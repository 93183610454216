import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface XpoStatusIndicatorCellRendererParams extends ICellRendererParams {
  statusColorCallback: (node: RowNode) => string;
  statusTextCallback?: (node: RowNode) => string;
  isLabelCallback?: (node: RowNode) => boolean;
  tooltip?: ((node: RowNode) => string) | string;
}

@Component({
  selector: 'xpo-status-indicator-cell-renderer',
  templateUrl: './xpo-status-indicator-cell-renderer.component.html',
  styleUrls: ['./xpo-status-indicator-cell-renderer.component.scss']
})
export class XpoStatusIndicatorCellRendererComponent implements ICellRendererAngularComp {
  private params: XpoStatusIndicatorCellRendererParams;

  protected statusColor: string;
  protected statusText: string;
  protected isLabel: boolean = false;
  protected toolTip: string = "";

  agInit(params: XpoStatusIndicatorCellRendererParams): void {
    this.params = params;
    this.updateAllStates();
  }

  refresh(params: XpoStatusIndicatorCellRendererParams): boolean {
    this.params = params;
    this.updateAllStates();
    return true;
  }

  updateAllStates() {
    this.updateStatusColorState();
    this.updateStatusTextState();
    this.updateIsLabelState();
    this.updateTooltip();
  }

  iconOnly(): boolean {
    return !(this.statusText && this.statusText.length > 0);
  }

  updateStatusColorState(): void {
    this.statusColor = this.params.statusColorCallback ? this.params.statusColorCallback(this.params.node) : "darkgrey"
  }

  updateStatusTextState(): void {
    this.statusText = this.params.statusTextCallback ? this.params.statusTextCallback(this.params.node) : undefined
  }

  updateIsLabelState(): void {
    this.isLabel = this.params.isLabelCallback ? this.params.isLabelCallback(this.params.node) : false;
  }

  private updateTooltip(): void {
    if (this.params.tooltip) {
      if (typeof this.params.tooltip === 'function') {
        this.toolTip = this.params.tooltip(this.params.node);
      } else {
        this.toolTip = this.params.tooltip;
      }
    } else {
      this.toolTip = '';
    }
  }
}
