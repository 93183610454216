import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/_services/api.service';

export interface DownloadButtonCellRendererParams extends ICellRendererParams {
  fileNameKey: string;
  fileName: string | Function;
  fileDownloadHandler: Function
  tooltip: string;
}


@Component({
  selector: 'download-cell-renderer',
  templateUrl: './download-cell-renderer.component.html',
  styleUrls: ['./download-cell-renderer.component.scss'],
})

export class DownloadCellRendererComponent {
  params: DownloadButtonCellRendererParams

  constructor(public rfpApi: RfpMgmtApiService, private snackbar: XpoSnackBar) {}

  agInit(params: DownloadButtonCellRendererParams): void {
    this.params = params;
  }

  getFileName(): string{
    if (typeof this.params.fileName === 'function') {
      return this.params.fileName(this.params.node.data);
    } else if (this.params.fileName) {
      return this.params.fileName;
    } else {
      return this.params.node.data[this.params.fileNameKey];
    }
  }

  downloadFile(): Observable<Blob | HttpResponse<Blob>>{
    return this.params.fileDownloadHandler(this.params.node.data)
  }
}
