import { GetRfpDetailsResp, RfpReviewSalesResponse } from "@xpo-ltl/sdk-rfpmgmt";


const RfpPremiumServiceCodes = [
    "MBS",
    "MBD"
]

export class RfpDetails extends GetRfpDetailsResp {

    constructor(response: GetRfpDetailsResp) {
        super();
        Object.assign(this, response);
    }

    hasPremiumService(): boolean {
        return this.accessorials.some(x => RfpPremiumServiceCodes.includes(x.accessorialCode));
    }

    isChangeAccessorial(): boolean {
        return this.rfpTypeCode === "ChangeAc";
    }

    isPricingCreated(): boolean {
        return this.salesProgram === "MonAndAdj" ||
            this.salesProgram === "PricingInitiated" ||
            this.salesProgram.startsWith("MonitorandAdjust");
    }

    isInCycleReno(): boolean {
        return this.inCycleInd && this.rfpTypeCode === "Reneg";
    }

    /** Sales is allowed to close RFPs that were not created by pricing, and are not in-cycle renos */
    canSalesClose(): boolean {
        return !this.isPricingCreated() && !this.isInCycleReno();
    }
}


export class RfpReview extends RfpReviewSalesResponse {
    constructor(response: RfpReviewSalesResponse) {
        super();
        Object.assign(this, response);
    }

    isRdsReview(): boolean {
        return this.reviewType === "RDSREVIEW"
    }

    isOpenRdsReview(): boolean {
        return this.isRdsReview() && this.reviewStatusCode !== "Approved"
    }

    isApprovedRdsReview(): boolean {
        return this.isRdsReview() && this.reviewStatusCode === "Approved";
    }

    isPsaeReview(): boolean {
        return this.reviewType === "PSAEREVIEW"
    }

    isOpenPsaeReview(): boolean {
        return this.isPsaeReview() && this.reviewStatusCode !== "Approved"
    }

    isApprovedPsaeReview(): boolean {
        return this.isPsaeReview() && this.reviewStatusCode === "Approved";
    }
}