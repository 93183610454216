import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: 'xpo-grid-toolbar-actions-group-more',
  host: {
    'data-cy': 'action-buttons-group-more',
  },
})
export class XpoBoardGridToolbarActionsMore { }
@Component({
  selector: 'xpo-grid-toolbar-dynamic-more',
  templateUrl: 'toolbar-dynamic-more.component.html',
  styleUrls: ['toolbar-dynamic-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class XpoGridToolbarDynamicMore implements OnChanges, AfterViewInit {
  @Input() dynButtons: HTMLFieldSetElement[];
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('menu') menu: MatMenu;
  // Necessary to group and get the projectedButtons from <ng-content></ng-content>
  @ViewChild('projectedWrapper') projectedWrapper: ElementRef;
  // Projected buttons coming from implementation
  private projectedButtons: ElementRef[] = [];
  // If showMenu=false hide the dropdown button
  showMenu = true;

  constructor(private cd: ChangeDetectorRef) { }

  /*
 * This ngOnChanges is being dispatched by the "@Input() dynButton" when the screen is
 * resized or when the dropdown is expanded
 */
  ngOnChanges(): void {
    this.showMenu = this.projectedButtons.length > 0 || this.dynButtons.length > 0;
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    // As the projected buttons are simple button without @Component or @Directive isn't possible
    // To get them using @ContendChildren. The option is to get the container with @ViewChild and
    // then use the QuerySelector to get finally the buttons.
    this.projectedButtons =
      (this.projectedWrapper && this.projectedWrapper.nativeElement.getElementsByTagName('button')) || [];
  }
}
