<div xpoDialog>
    <h1 mat-dialog-title>
      Insert New User
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content>

        <form [formGroup]="addUserForm">
            <mat-form-field floatLabel="always" class="width-double">
                <mat-label>New Employee</mat-label>
                <input type="text" matInput placeholder="Select A New Employee" [formControlName]="addUserFormFields.NewEmployee" [matAutocomplete]="managerAutocomplete" xpoAutocomplete />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete autoActiveFirstOption #managerAutocomplete [displayWith]="userFieldDisplay">
                    <mat-option *ngFor="let user of filteredUserOptions$ | async" [value]="user">
                        <xpo-select-two-columns-container style="grid-template-columns: 70% 30%;">
                            <span>{{ user.employeeFullName | titlecase }}</span>
                            <span>{{ user.employeeId }}</span>
                        </xpo-select-two-columns-container>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field floatLabel="always">
                <mat-label>Channel Code</mat-label>
                <mat-select placeholder="Select Channel" xpoSelect [formControlName]="addUserFormFields.ChannelCode">
                  <mat-option *ngFor="let channel of channelCodes" [value]="channel"> {{ channel }} </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

    </div>
  
    <div mat-dialog-actions>
        <xpo-button-group>
        </xpo-button-group>
        <button mat-flat-button xpoLargeButton xpoButton [disabled]="addUserForm.invalid" (click)="add()">Add</button>
    </div>
  </div>