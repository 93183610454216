<div class="baseContainer">

    <div class="horizontalContainer">
        <xpo-card>
            <xpo-card-header>
              <xpo-card-title>Account Search</xpo-card-title>
            </xpo-card-header>
          
            <xpo-card-content class="searchContainer">
                <mat-form-field floatLabel="always">
                    <mat-label>MAD Code</mat-label>
                    <input type="text" name="madcode" matInput [(ngModel)]="customerSearch" (keyup.enter)="searchCustomer()" placeholder="Enter Madcode" matTooltip="Search for a MAD Code" matTooltipPosition="above"/>
                </mat-form-field>
                <button mat-flat-button xpoButton xpoSmallButton (click)="searchCustomer()">Search</button>
            </xpo-card-content>
        </xpo-card>


        <xpo-card class="dev-tools-card" *ngIf="showDevTool">
            <xpo-card-header>
              <xpo-card-title>DEV TOOL</xpo-card-title>
            </xpo-card-header>
          
            <xpo-card-content>
                <button mat-flat-button xpoButton (click)="openCreateRfpDialog()">CREATE RFP</button>
            </xpo-card-content>
        </xpo-card>
    </div>

    <div class="xpo-Card field-container">     

        <mat-tab-group selectedIndex="0" xpoPillTabGroup>
            <mat-tab label="RFPs">
                <div class="grid-container flex-grow">
                    <xpo-ag-grid>
                        <ag-grid-angular [gridOptions]="rfpGridOptions" (gridReady)="onRfpGridReady($event)">
                        </ag-grid-angular>
                    </xpo-ag-grid>
                </div>
            </mat-tab>

            <mat-tab label="Agreements">
                <div class="grid-container flex-grow">

                    <xpo-ag-grid>
                        <xpo-grid-toolbar [gridOptions]="agreementGridOptions">           
                            <xpo-grid-toolbar-settings-group>
                                <button mat-button
                                    matTooltip="Open PL Docs"
                                    matTooltipPosition="above"
                                    (click)="openPlDocs()">
                                    <xpo-icon xpoIconSmall iconName="open-in-new"></xpo-icon> PL Docs
                                </button>
                            </xpo-grid-toolbar-settings-group>
                        </xpo-grid-toolbar>

                        <ag-grid-angular [gridOptions]="agreementGridOptions" (gridReady)="onAgreementGridReady($event)">
                        </ag-grid-angular>
                    </xpo-ag-grid>

                </div>
            </mat-tab>

            <mat-tab label="Hierarchy">
                <div class="grid-container flex-grow">
                    <account-hierarchy [madCode]="customerSearch"></account-hierarchy>
                </div>
            </mat-tab>
            
        </mat-tab-group>
    </div>

</div>
