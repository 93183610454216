<xpo-contextual-header>
    <xpo-contextual-header-items [itemList]="contextualHeaderItems"></xpo-contextual-header-items>

    <xpo-contextual-header-actions [config]="actionsConfig"></xpo-contextual-header-actions>

    <div class="header-actions" *ngIf="rfpService.isEditable()">
        <div class="action-button-container">
            <ng-container *ngIf="rfpService.isEditable()">
                <span [matTooltip]="getSubmitToPricingError()"><button mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="!canSubmitToPricing() || makingApiCall || savingRfpData" (click)="submitToPricing()"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Submit to Pricing</button></span>
                <button xpo-action-button [disabled]="rfpCancelPricingForm.invalid || makingApiCall || savingRfpData" [matTooltip]="'Save RFP'" (click)="callSaveRfpData()"><xpo-icon iconName="save"></xpo-icon></button>
                <button xpo-action-button [disabled]="makingApiCall || savingRfpData" [matTooltip]="'Close RFP'" (click)="closeRfp()"><xpo-icon iconName="cancel-outline"></xpo-icon></button>
            </ng-container>
        </div>
    </div>

</xpo-contextual-header>


<div class="content-container">
    <div class="xpo-Card field-container">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" xpoPillTabGroup [disableRipple]="true">

            <mat-tab *ngVar="getTabStatus() as tabStatus">
                <ng-template mat-tab-label>
                    {{ tabStatus.tabName }}
                    <ng-container *ngIf="tabStatus?.complete; else tabInvalid">
                        <xpo-icon iconName="checkmark-alt" class="tab-success"></xpo-icon>
                    </ng-container>

                    <ng-container *ngIf="tabStatus?.unsavedChanges">
                        <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                    </ng-container>
                </ng-template>

                <div class="tab-container">
                    <form class="column-container" [formGroup]="rfpCancelPricingForm">
                        <div class="column-container">
                            <h2>Proposed Expiration Date</h2> 
                            <mat-form-field appearance="standard">
                                <input matInput required [matDatepicker]="expirationDatePicker" [formControlName]="rfpCancelPricingFormFields.ProposedExpirationDate">
                                <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #expirationDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    
                        <h2>Contact Details</h2> 
                        <div class="column-container">
                            <div class="row-container">
                                <mat-form-field floatLabel="always" class="width-double">
                                    <mat-label>Contact Name</mat-label>
                                    <input matInput required [formControlName]="rfpContactFormFields.ContactName" name="name" autocomplete="name">
                                </mat-form-field>
                        
                                <mat-form-field floatLabel="always" class="width-double">
                                    <mat-label>Email</mat-label>
                                    <input matInput [formControlName]="rfpContactFormFields.ContactEmail" name="email" autocomplete="email">
                                </mat-form-field>
                    
                            </div>
                    
                            <div class="row-container">
                                <mat-form-field floatLabel="always" class="width-double">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput required [formControlName]="rfpContactFormFields.AddressLine1" name="address-line1" autocomplete="address-line1">
                                </mat-form-field>
                        
                                <mat-form-field floatLabel="always" class="width-double">
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput [formControlName]="rfpContactFormFields.AddressLine2" name="address-line2" autocomplete="address-line2">
                                </mat-form-field>
                            </div>
                    
                            <div class="row-container">
                                <mat-form-field floatLabel="always">
                                    <mat-label>City</mat-label>
                                    <input matInput required [formControlName]="rfpContactFormFields.CityName" name="address-level3" autocomplete="address-level3">
                                </mat-form-field>
                    
                                <mat-form-field floatLabel="always">
                                    <mat-label>State Code</mat-label>
                                    <input matInput required [formControlName]="rfpContactFormFields.StateCode" name="address-level1" autocomplete="address-level1">
                                </mat-form-field>
                    
                                <mat-form-field floatLabel="always">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput required [formControlName]="rfpContactFormFields.ZipCode" name="postal-code" autocomplete="postal-code">
                                </mat-form-field>
                    
                                <mat-form-field floatLabel="always">
                                    <mat-label>Country Code</mat-label>
                                    <input matInput required [formControlName]="rfpContactFormFields.CountryCode" name="country" autocomplete="country">
                                </mat-form-field>
                            </div>
                    
                        </div>
                    
                    </form>
                </div>
            </mat-tab>

            <mat-tab label="Documents">
                <div class="tab-container">
                    <rfp-documents [rfpDetails]="rfpDetails"></rfp-documents>
                </div>
            </mat-tab>


            <mat-tab label="Messages">
                <div class="tab-container no-side-bottom-padding">
                    <rfp-messages [containerHeight]="'403px'" [rfpDetails]="rfpDetails" (onDocumentSelected)="navigateToDocumentTab($event)"></rfp-messages>
                </div>
            </mat-tab>


            <mat-tab label="History">
                <div class="tab-container">
                    <rfp-history [rfpDetails]="rfpDetails"></rfp-history>
                </div>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>

<ng-template #tabInvalid>
    <xpo-icon iconName="priority-high" class="tab-error"></xpo-icon>
</ng-template>

<ng-template #tabUnsavedChanges>
    <xpo-icon iconName="save" class="tab-normal"></xpo-icon>
</ng-template>


