import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { XpoGridToolbarDynamicMore } from './toolbar-dynamic-more/toolbar-dynamic-more.component';

@Component({
  selector: 'xpo-grid-toolbar-actions-group',
  templateUrl: 'toolbar-actions.component.html',
  styleUrls: ['toolbar-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-grid-toolbar-container-actions-buttons-group',
    'data-cy': 'actions-buttons-group',
  },
})
export class XpoGridToolbarActions {
  buttons: HTMLFieldSetElement[] = [];
  @Input() dynButtons: HTMLFieldSetElement[];
  constructor(private cdr: ChangeDetectorRef) { }

  @ViewChild('dynamicMoreMenu') dynamicMoreMenu: XpoGridToolbarDynamicMore;

  updateButtonsVisibility(event: HTMLFieldSetElement[]): void {
    this.buttons = event;
    if (this.dynamicMoreMenu.menuTrigger) {
      this.dynamicMoreMenu.menuTrigger.closeMenu();
    };
    this.cdr.detectChanges();
  }
}
