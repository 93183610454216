import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CellEditingStoppedEvent, ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent, RowEditingStoppedEvent, RowNode } from "ag-grid-community";
import { DownloadCellRendererComponent } from 'src/app/_reusable/cell-renderers/download-cell-renderer/download-cell-renderer.component';
import { GridColumnPartialsHelper } from "src/app/_reusable/grid-column-partial-helper";
import { UploadDocumentsDialogInput } from './upload-documents-dialog/upload-documents-dialog-input';
import { UploadDocumentsDialogResponse } from './upload-documents-dialog/upload-documents-dialog-response';
import { UploadDocumentsDialogComponent } from './upload-documents-dialog/upload-documents-dialog.component';
import { DeleteDocumentPath, RfpMgmtApiService, UpdateDocumentPath, UpdateDocumentRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { RfpDocumentDetails } from 'src/app/_models/temp/rfpDocument';
import moment from 'moment';
import { LookupService } from 'src/app/_services/lookup.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { IconButtonCellRendererComponent } from 'src/app/_reusable/cell-renderers/icon-button-cell-renderer/icon-button-cell-renderer.component';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { ConfigSettings } from 'src/app/_models/configSettings';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { SelectEditorComponent } from '@xpo-ltl/ngx-ag-grid';

export interface DocIdFilter {
  documentId : {
    filterType: string,
    type: string,
    filter: number
  }
}

@Component({
  selector: 'rfp-documents',
  templateUrl: './rfp-documents.component.html',
  styleUrls: ['./rfp-documents.component.scss']
})
export class RfpDocumentsComponent implements OnInit {
  @Input() rfpDetails: RfpDetails;


  excludedDocumentTypes: string[] = ["Rulesets", "Doc for Signature"]
  documents: RfpDocumentDetails[] = [];
  documentTypeDropdownOptions: string[] = []

  DocumentsColDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.RowIndex,
    { 
      field: 'download',                
      headerName: '',   
      cellRendererFramework: DownloadCellRendererComponent,
      cellRendererParams: { 
        fileNameKey: "documentName", 
        fileDownloadHandler: this.downloadFile.bind(this), 
        tooltip:"Download File" 
      }, 
      ...GridColumnPartialsHelper.XpoStatusColDef,
    },
    { 
      field: 'delete',                  
      headerName: '',   
      cellRendererFramework: IconButtonCellRendererComponent, 
      cellRendererParams: { 
        onClickCallback: this.deleteFile.bind(this),  
        iconName:"delete", tooltip:"Delete File" 
      }, 
      ...GridColumnPartialsHelper.XpoStatusColDef,
    },
    { 
      field: 'documentId',              
      headerName: "Document ID",    
      filter: "agTextColumnFilter", 
      hide: true 
    },
    { 
      field: 'documentName',
      headerName: "Document Name",
      filter: "agTextColumnFilter",
    },
    { 
      field: 'documentTypeCode',
      headerName: "Document Type",
      filter: "agSetColumnFilter",
      suppressPaste: true, 
      ...GridColumnPartialsHelper.Editable, 
      ...GridColumnPartialsHelper.ValueFormatterLookupCodeNamePair,
      cellEditor: 'agRichSelectCellEditor', 
      cellEditorParams: () => ({ values: this.documentTypeDropdownOptions }), 
    },
    { 
      field: 'documentAuthorFullName',
      headerName: "Author",
      filter: "agSetColumnFilter",  
    },
    { 
      field: 'documentComment',
      headerName: "Comment",
      filter: "agTextColumnFilter", 
    },
    { 
      field: 'uploadDate', 
      headerName: "Upload Date",
      width:220, 
      suppressAutoSize: true, 
      flex: 0, 
      sort: "desc", 
      ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY hh:mm:ss A")
    },
  ]

  gridApi: GridApi;
  gridLoadingData: boolean = false;
  gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
      singleClickEdit: true 
    },
    maintainColumnOrder: true,
    pagination: true,
    columnDefs: this.DocumentsColDefs,
    getRowNodeId: (data) => data.documentId,
    onCellEditingStopped: this.onCellEditingStopped.bind(this) ,
  }

  
  constructor(private dialog: MatDialog, private rfpApi: RfpMgmtApiService, public lookup: LookupService, private configManagerService: ConfigManagerService, private snackbar: XpoSnackBar, private confirmDialog: XpoConfirmDialog) { }
 
  ngOnInit(): void {
    this.lookup.documentTypes$.subscribe(values => {
      this.documentTypeDropdownOptions = values.filter(x => !this.excludedDocumentTypes.includes(x.code)).map(x => { return x.name })
    })  
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange && this.gridApi) {
      this.getGridData()
    }
  }

  filterDocById(documentId: number){
    let filterModel: DocIdFilter = {
      documentId: {
        filterType: "text",
        type: "equals",
        filter: documentId
      }
    }

    this.gridApi.setFilterModel(filterModel)
  }

  downloadFile(document: RfpDocumentDetails){
    return this.rfpApi.downloadDocument({documentId: document.documentId}, null, { responseType: "blob" })
  }  

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if(event.column?.getId() == "documentTypeCode"){
      const documentId: number = parseInt(event.node.id)

      this.lookup.documentTypes$.subscribe(docTypes => {
        if(event.newValue != event.oldValue) {
          const path: UpdateDocumentPath = {
            documentId: documentId
          }
          
          const newDocType: LookupCodeNamePair = docTypes.find(x => x.name == event.newValue)

          const request: Partial<UpdateDocumentRqst>  = {
            documentTypeCode: newDocType.code
          }
          this.rfpApi.updateDocument(request as UpdateDocumentRqst, path).subscribe(x => {
            let editedDoc = this.documents.find(x => x.documentId === documentId);
            editedDoc.documentTypeCode = newDocType.code;
            this.snackbar.success("Document type updated successfully!")
          })
        }
      })
    }
  }

  deleteFile(rowNode: RowNode){
    const documentId = Number(rowNode.id)
    const documentToDelete: RfpDocumentDetails = this.gridApi?.getRowNode(`${documentId}`)?.data
    
    const config: XpoConfirmDialogConfig = {
      confirmButtonText: "Delete",
      icon: "delete"
    }
    
    this.confirmDialog.confirm(
      `<p>Are you sure you want to delete the following file:</p>
      <p><b>${documentToDelete.documentName}</b></p> 
      <p>This cannot be undone.</p>`,
      `Delete Document`, 
      config).subscribe(confirmation => {
        if(confirmation){
          const request: DeleteDocumentPath = {
            documentId: documentId
          }
      
          this.rfpApi.deleteDocument(request).subscribe(
            (response) => {
              this.snackbar.success(`${documentToDelete.documentName} Successfully Deleted`)
              this.getGridData();
            },
            (error)=> {
              this.snackbar.error(`There was an issue deleting ${documentToDelete.documentName}`)
            }
          )
        }
      }
    )
  }

  openFileUploadDialog() {
    const dialogData: UploadDocumentsDialogInput = {
      rfpDetails: this.rfpDetails,
      header: "Upload New RFP Document",
      isPlDocs: false
    }
    this.dialog.open(UploadDocumentsDialogComponent, { data: dialogData, minWidth: "30vw", disableClose: true }).afterClosed().subscribe((dialogResponse: UploadDocumentsDialogResponse)  => {
      this.getGridData();
    })
  }

  anyFiltersActive(): boolean{
    return this.gridApi?.isAnyFilterPresent()
  }

  clearAllGridFilters(): void{
    this.gridApi?.setFilterModel(null)
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.getGridData();
  } 

  hasShipmentFile(): boolean {
    return this.documents.findIndex(x => x.documentTypeCode === "Shipment File") !== -1
  }

  getGridData() {
    this.gridLoadingData = true;
    this.gridApi.showLoadingOverlay();

    this.rfpApi.getRfpDocuments({salesRfpId: this.rfpDetails.salesRfpId}).subscribe(response => {
      this.documents = response.items;
      this.lookup.documentTypes$.subscribe(docTypes => {
        this.gridApi.setRowData(response.items.map((document: RfpDocumentDetails) => {
          return { 
            ...document, 
            documentTypeCode: docTypes.find(x => x.code == document.documentTypeCode)?.name ?? document.documentTypeCode,
            uploadDate: moment.utc(document.uploadDate).local().valueOf()
          }
        }))
      })
    })
  }

  openPlDocs(){
    let urlBase = ""
    let plDocsurl = "appjs/pricing/#/PLDocs/tabs/documentSearch"
    if(this.configManagerService.getSetting(ConfigSettings.Production)){
      urlBase = "https://app.ltl.xpo.com/"
    }
    else {
      urlBase = "https://tctss-app.ltl.xpo.com/"
    }
    
    window.open(`${urlBase}${plDocsurl}`)
  }
}
