export enum ConfigSettings {
    Region = "region",
    BuildVersion = "buildVersion",
    Production = "production",
    FeatureFlags = "featureFlags",
    DptThreshold = "dptThreshold",
    PrcAgmtAppUrl = "prcAgmtAppUrl",
    RfpMaxFileSizeMB = "rfpMaxFileSizeMB",
    PremiumServicesMadCodes = "premiumServicesMadCodes",
}

export interface FeatureFlags {
    showDevTools: boolean;
    enableRdsReview: boolean;
    enableRdsReviewUser: boolean;
    enableReturnReasons: boolean;
    enablePremiumServices: boolean;
    enablePremiumServicesReview: boolean;
    enableTdcSunset: boolean;
    enableDptLocations: boolean;
}