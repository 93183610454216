import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { LookupService } from 'src/app/_services/lookup.service';
import { RfpTariff } from 'src/app/_models/rfp/rfpTariff';
import { TabStatus, OnSaveEvent, SaveEvent } from '../../../rfp-edit.component';
import { RfpService } from 'src/app/_services/rfp.service';

export enum RatingTariffFields {
  DomesticRateTariff = "rateTariffDomestic",
  InternationalRateTariff = "rateTariffInternational",
  CanadaRateTariff = "rateTariffCanadian",
  DiscountType = "currentDiscountCode",
  DiscountAmount  = "discountAmt"
}

interface RatingTariffFilterConfig {
  includes: string[];
  excludes: string[]
}

@Component({
  selector: 'rfp-tariff-discount',
  templateUrl: './rfp-tariff-discount.component.html',
  styleUrls: ['./rfp-tariff-discount.component.scss']
})
export class RfpTariffDiscountComponent implements OnInit, OnSaveEvent {
  @Input() rfpDetails: RfpDetails;
  @Output() rfpSave = new EventEmitter<SaveEvent>();

  domesticFilter: RatingTariffFilterConfig = {
    includes: ["US/US"],
    excludes: ["US/CN", "CN/CN"]
  }
  internationalFilter: RatingTariffFilterConfig = {
    includes: ["US/CN"],
    excludes: ["US/US", "CN/CN"]
  }
  canadaFilter: RatingTariffFilterConfig = {
    includes: ["CN/CN"],
    excludes: ["US/US", "US/CN"]
  }
  ratingTariffFields = RatingTariffFields;

  ratingTariffFormGroup: UntypedFormGroup;

  rfpTariffOptions: RfpTariff[];

  filteredDomesticTariffOptions$: Observable<RfpTariff[]>;
  filteredInternationalTariffOptions$: Observable<RfpTariff[]>;
  filteredCanadaTariffOptions$: Observable<RfpTariff[]>;

  constructor(
    private fb: UntypedFormBuilder, 
    public lookup: LookupService,
    private rfpService: RfpService,
  ) { }


  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createForm();
    }
  }

  createForm(){
    this.lookup.rfpTariffs$.subscribe(tariffs => {
      this.rfpTariffOptions = tariffs
    
      this.ratingTariffFormGroup = this.fb.group({
        [RatingTariffFields.DomesticRateTariff]:       [this.getRateTariff(this.rfpDetails?.rateTariffDomestic?.tariffCode,       this.rfpDetails?.rateTariffDomestic?.effectiveDate,       this.domesticFilter), Validators.required],
        [RatingTariffFields.InternationalRateTariff]:  [this.getRateTariff(this.rfpDetails?.rateTariffInternational?.tariffCode,  this.rfpDetails?.rateTariffInternational?.effectiveDate,  this.internationalFilter)],
        [RatingTariffFields.CanadaRateTariff]:         [this.getRateTariff(this.rfpDetails?.rateTariffCanadian?.tariffCode,       this.rfpDetails?.rateTariffCanadian?.effectiveDate,       this.canadaFilter)],
        [RatingTariffFields.DiscountType]:             [this.rfpDetails.currentDiscountCode, Validators.required],
        [RatingTariffFields.DiscountAmount]:           [(this.rfpDetails.discountAmt && this.rfpDetails.currentDiscountCode == "PERCENT") ? this.rfpDetails.discountAmt * 100 : this.rfpDetails.discountAmt, [Validators.min(-100), Validators.max(999.99)]],
      })
  
      this.filteredDomesticTariffOptions$ = this.ratingTariffFormGroup.get(RatingTariffFields.DomesticRateTariff)?.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this.filterRecentTariffOptions(value, this.domesticFilter))
      );
        
      this.filteredInternationalTariffOptions$ = this.ratingTariffFormGroup.get(RatingTariffFields.InternationalRateTariff)?.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this.filterRecentTariffOptions(value, this.internationalFilter))
      );
  
      this.filteredCanadaTariffOptions$ = this.ratingTariffFormGroup.get(RatingTariffFields.CanadaRateTariff)?.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.name),
        map(value => this.filterRecentTariffOptions(value, this.canadaFilter))
      );
      
      setTimeout(() => {
        this.handleEditability();
      }, 0);
    })
    
  }

  getTabStatus(): TabStatus {
    return { 
      complete: this.ratingTariffFormGroup?.enabled ? this.ratingTariffFormGroup?.valid : true, 
      canSave: true,
      unsavedChanges: this.ratingTariffFormGroup?.dirty,
      tabName: "Tariff & Discount"
    };
  }

  isDiscountTypeFlat(): boolean {
    return this.ratingTariffFormGroup.get(RatingTariffFields.DiscountType)?.value?.toUpperCase() == "FLAT"
  }
  isDiscountTypePercent(): boolean {
    return this.ratingTariffFormGroup.get(RatingTariffFields.DiscountType)?.value?.toUpperCase() == "PERCENT"
  }

  onRfpSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent  = {
      changes: []
    }
    saveEvent.changes.push({ key: RatingTariffFields.DomesticRateTariff,        value: this.ratingTariffFormGroup.get(RatingTariffFields.DomesticRateTariff).value })
    saveEvent.changes.push({ key: RatingTariffFields.InternationalRateTariff,   value: this.ratingTariffFormGroup.get(RatingTariffFields.InternationalRateTariff).value })
    saveEvent.changes.push({ key: RatingTariffFields.CanadaRateTariff,          value: this.ratingTariffFormGroup.get(RatingTariffFields.CanadaRateTariff).value })
    saveEvent.changes.push({ key: RatingTariffFields.DiscountType,              value: this.ratingTariffFormGroup.get(RatingTariffFields.DiscountType).value })

    let discountAmount = this.ratingTariffFormGroup.get(RatingTariffFields.DiscountAmount).value;
    if(discountAmount){
      if(this.isDiscountTypePercent()) discountAmount = discountAmount / 100;
      else if (this.isDiscountTypeFlat()) discountAmount = discountAmount;
      else discountAmount = null
    }
    saveEvent.changes.push({ key: RatingTariffFields.DiscountAmount, value: discountAmount });
    
    return of(saveEvent);
  }
  
  onRfpSaveSuccess(): void { 
    this.ratingTariffFormGroup.markAsPristine();
    this.handleEditability();
  }

  handleEditability(): void {
    this.rfpService.isEditable() ? this.ratingTariffFormGroup.enable() : this.ratingTariffFormGroup.disable();
  }

  ngOnDestroy(): void {
    this.ratingTariffFormGroup.get(RatingTariffFields.DomesticRateTariff).clearValidators();
  }

  displayWith(rfpTariff: RfpTariff): string {
    return rfpTariff ? `${rfpTariff.tariffName} - ${moment(rfpTariff.effectiveDate).format('L')}` : '';
  }

  filterRecentTariffOptions(value: string = "", filterConfig: RatingTariffFilterConfig): RfpTariff[] {  
    const regionFiltered = this.filterTariffsByRegion(this.rfpTariffOptions, filterConfig)

    return regionFiltered.filter((rfpTariff) => this.checkRfpTariffFilter(rfpTariff, value))
  }

  checkRfpTariffFilter(rtpTariff: RfpTariff, searchValue: string): boolean {
    const searchValueLowercase: string = searchValue.toLowerCase()

    return  rtpTariff.tariffName.toLowerCase().includes(searchValueLowercase) ||           // Tariff Name Contains Search
            rtpTariff.tariffCode.toLowerCase().includes(searchValueLowercase) ||           // Tariff Code Contains Search
            moment(rtpTariff.effectiveDate).format('L').includes(searchValueLowercase) ||  // Tariff Effective Date Search
            this.displayWith(rtpTariff).toLowerCase() === searchValueLowercase;            // Copy-Paste Filter

  }

  filterTariffsByRegion(tariffs: RfpTariff[], filterConfig: RatingTariffFilterConfig): RfpTariff[] {
    return tariffs.filter(rfpTariff => {
      const tariffNameUpper = rfpTariff.tariffName.toUpperCase();
  
      // Check for includes
      if (filterConfig.includes.some(include => tariffNameUpper.includes(include.toUpperCase()))) {
        return true;
      }
      
      // Check for excludes
      if (filterConfig.excludes.some(exclude => tariffNameUpper.includes(exclude.toUpperCase()))) {
        return false;
      }
  
      return true;
    });
  }
    
  getRateTariff(tariffCode: string, date: string, filterConfig: RatingTariffFilterConfig): RfpTariff {
    const selectedRateTariff = this.filterTariffsByRegion(this.rfpTariffOptions, filterConfig).find(x => x.tariffCode == tariffCode && new Date(x.effectiveDate).getTime() === new Date(date).getTime()) ?? null;
    return selectedRateTariff;
  }
}

