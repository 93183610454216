import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import moment from 'moment';
import { MessageEditDialogInput } from './message-edit-dialog-input';
import { MessageEditDialogResponse } from './message-edit-dialog-response';

@Component({
  selector: 'message-edit-dialog',
  templateUrl: './message-edit-dialog.component.html',
  styleUrls: ['./message-edit-dialog.component.scss']
})
export class MessageEditDialogComponent implements OnInit {

  messageBoxText: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageEditDialogInput, public dialogRef: MatDialogRef<MessageEditDialogComponent, MessageEditDialogResponse>) { }

  ngOnInit(): void {
    this.messageBoxText = this.data.messageToEdit.content
  }

  cancel(){
    this.dialogRef.close()
  }

  saveMessage(){
    let dialogResponse: MessageEditDialogResponse = {
      updatedMessage: { ...this.data.messageToEdit, content: this.messageBoxText, lastUpdatedOn: moment().format("MM/DD/YYYY hh:mm A") }
    }

    this.dialogRef.close(dialogResponse)
  }

}
