import { Injectable } from '@angular/core';
import { XpoTileModel } from '@xpo-ltl/ngx-ltl-core/tile-ribbon';
import { MetricFormat } from '../_models/enums';
import { DatePipe } from "@angular/common";
import { LookupCodeNamePair } from '../_models/temp/lookupCodeValuePair';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  static formatValue(value: number, valueFormat: MetricFormat) : string{
    if (value !== undefined && value !== null) {
      let formatingValue: number =  +value ?? 0;

      switch(valueFormat){
        case MetricFormat.Dollar:   return `$   ${formatingValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        case MetricFormat.Percent:  return (formatingValue * 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " %";
        case MetricFormat.Fixed0:   return formatingValue.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        case MetricFormat.Fixed1:   return formatingValue.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        case MetricFormat.Fixed2:   return formatingValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        case MetricFormat.Fixed3:   return formatingValue.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        case MetricFormat.Fixed4:   return formatingValue.toFixed(4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        default:                    return formatingValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    return ""
  }

  static formatDate(value: string) : string{
    if (value !== undefined && value !== null) {
      return new DatePipe("en-US").transform(value, 'MM/dd/yy');
    }
    return "-";
  }
  createXpoTileModel(title: any, label: any, status?:any, description?: any): XpoTileModel{
    return {
      title: {
        text: title
      },

      link: undefined,

      label: {
        text: label,
        status: status,
      },

      description: {
        text: description,
      },
    }
  }

  splitStringByCapitals(sentence: string): string{
    return sentence.split(/(?=[A-Z])/).join(" ");
  }

  capitalizeFirstLetters(sentence: string): string{
    var words = sentence.split(" ");

    return words.map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    }).join(" ");
  }

  removeWhiteSpaces(sentence: string): string{
    return sentence.replace(/\s/g, "");
  }

  formatWeightClassValue(weightClass: number){
    if(weightClass == 925 || weightClass == 775){
      return weightClass / 10
    }
    return Math.round(weightClass)
  }

  static matchRegularExpression(regExpString: string, value: string): boolean {
    const toRegExp = new RegExp(regExpString);
    return toRegExp.test(value);
  }

  static stringToPercent(value: string): string {
    let percentValue;
    const toNumber = Number(value);
    if (!isNaN(toNumber)) {
      percentValue = toNumber * 100;
      percentValue = percentValue.toFixed(2).toString();
    }
    return percentValue;
  }

  static numberToMultiplier(value: number): number {
    const result = (value / 100);
    return result < 0 ? 0 : result;
  }

  static decimalToPercent(value: number): number {
    return value * 100
  }

  static percentToDecimal(value: number): number {
    return value / 100
  }

  static multiplierToString(value: number): string {
    return ((value) * 100).toFixed(2);
  }

  calculatePercentChange(projectedValue, totalValue): number {
    if (totalValue && totalValue != 0)
      return (projectedValue - totalValue) / totalValue
    return null
  }

  // Maps a value from a given range to a value in a new range
  // inMin and inMax: are the incoming grid min and max value range based on which the heatmap needs to be displayed
  // outMin and outMax: are the new range to replace the inMin and inMax
  // This functions maps the value based on which the cell will recieve its color and maps it into an equivaluent range needed by the heatmap cell renderer (i.e. between 0-100)
  mapToRange(value: number, inMin: number, inMax: number, outMin: number, outMax: number) {
    return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  /* 
  Returns the given `obj` with all keys prefaced with the given `prefix`
  */
  static prefixKeys(obj: {[key: string]: any}, prefix: string) {
    const newObj: {[key: string]: any} = {};
  
    for (let key in obj) {
      newObj[`${prefix}${key}`] = obj[key];
    }
  
    return newObj;
  }

  static getLookupValue(options: LookupCodeNamePair[], code: string) {
    if(!options) return null
    return options.find(x => x.code === code)?.name
  }
}
 