<xpo-shell style="height: calc(100vh - 46px)">
	<xpo-header-app-name>Edge</xpo-header-app-name>
	<xpo-header-sub-app-name>Sales</xpo-header-sub-app-name>

	<xpo-header-utilities-actions>
		<xpo-auth-user-profile></xpo-auth-user-profile>
	</xpo-header-utilities-actions>

	<xpo-error-pages [type]="errorPagesType.NotFound" *ngIf="userMissingValidated">
        <xpo-error-pages-title>
        <h1>Invalid Permissions</h1>
        </xpo-error-pages-title>
    
        <xpo-error-pages-description>
        <p>Sorry, you don't have access to this content. Please contact your manager if you believe this is incorrect.</p>
        </xpo-error-pages-description>
    </xpo-error-pages>
</xpo-shell>

<xpo-footer xpoFooterSticky [version]="appVersion">
	<a xpo-regular-link color="accent" href='mailto:athena@xpo.com?subject=Athena Feedback'>Contact Us</a>
</xpo-footer>
