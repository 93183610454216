import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  LicenseManager.setLicenseKey(`CompanyName=XPO Logistics (Boston, MA),LicensedGroup=Enterprise Architecture,` +
      `LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,` +
      `LicensedProductionInstancesCount=0,AssetReference=AG-014247,` +
      `ExpiryDate=16_April_2022_[v2]_MTY1MDA2MzYwMDAwMA==f8235caadbde24c608a1217bd9809d38`
  );