<div class="overlay-container" *ngIf="loadingHierarchy">
    <div class="overlay">
        <div class="spinner-container">
            <xpo-busy-loader></xpo-busy-loader>
            <h1>Loading Hierarchy</h1>
        </div>
    </div>
</div>

<form [formGroup]="filterFormGroup" class="filter-container " autocomplete="off">
    <div class="filter">
        <mat-label class="mat-label">MadCode</mat-label>
        <mat-form-field appearance="standard">
            <input matInput [formControlName]="accountHierarchyFilterFields.MadCode" placeholder="Search by MadCode" id="madCode"/>
        </mat-form-field>
    </div>

    <form [formGroup]="statusFormGroup" class="filter">
        <mat-label class="mat-label">Account Status</mat-label>
        <div class="checkbox-container">
            <mat-checkbox class="status-active" [formControlName]="accountHierarchyFilterFields.StatusActive">Active</mat-checkbox>
            <mat-checkbox class="status-inactive" [formControlName]="accountHierarchyFilterFields.StatusInactive">Inactive</mat-checkbox>
            <mat-checkbox class="status-expired" [formControlName]="accountHierarchyFilterFields.StatusExpired">Expired</mat-checkbox>
        </div>
    </form>

    <form [formGroup]="functionFormGroup" class="filter">
        <mat-label class="mat-label">Account Function</mat-label>
        <div class="checkbox-container">
            <mat-checkbox class="function-corp" [formControlName]="accountHierarchyFilterFields.FunctionCorp">Corp</mat-checkbox>
            <mat-checkbox class="function-pnd" [formControlName]="accountHierarchyFilterFields.FunctionPnD">PnD</mat-checkbox>
            <mat-checkbox class="function-billto" [formControlName]="accountHierarchyFilterFields.FunctionBillTo">Bill-To</mat-checkbox>
        </div>
    </form>

    <button xpo-action-button class="more-actions-button" matTooltip="More Actions" matTooltipPosition="above" [matMenuTriggerFor]="actionMenu">
        <xpo-icon iconName="more-horiz"></xpo-icon>
    </button>

    <mat-menu #actionMenu="matMenu">
        <button mat-menu-item (click)="toggleAll(true)"><xpo-icon iconName="arrow-expand"></xpo-icon>Expand All</button>
        <button mat-menu-item (click)="toggleAll(false)"><xpo-icon iconName="arrow-collapse"></xpo-icon>Collapse All</button>
        <button mat-menu-item (click)="toggleAll(false, [1])"><xpo-icon iconName="arrow-collapse"></xpo-icon>Collapse All - Except Primary</button>
    </mat-menu>
</form>

<ul class="base-ul">
    <ng-template #recursiveList let-locations>
        <li *ngFor="let location of locations; trackBy: trackByFunction">
            <div (click)="toggle(location)" class="location-container" [style.paddingLeft.px]="(30 * (location.levelNumber - 1)) + (location.children?.length ? 0 : 40)" [ngClass]="location.children?.length == 0 ? 'no-children' : 'show-pointer'">
                <xpo-icon xpoIconLarge *ngIf="location.children?.length" [iconName]="getChevron(expandedStateMap.get(location.accountNumber))" class="chevron-icon"></xpo-icon>
                <xpo-status-indicator [statusColor]="getAccountStatusColor(location.statusCode)"></xpo-status-indicator>
                <div class="info">
                    <span class="madcode">{{ location.madCode }}</span>
                    <mat-chip xpoBadgeChip class="function-code" [ngClass]="location.functionCode">{{ location.functionCode | accountFunctionDisplay }}</mat-chip>
                    <span class="name">{{ location.name }}<ng-container *ngIf="location.name2"> ({{location.name2}})</ng-container></span>
                    <span class="address">{{ location.addressText }}, {{ location.cityName }}, {{ location.stateCode }}, {{ location.zipCode }}</span>
                </div>
                <button mat-button class="action-button" matTooltip="Copy Address" matTooltipPosition="after" (click)="copyAddress(location, $event)"><xpo-icon iconName="copy"></xpo-icon></button>
            </div>
            <ul *ngIf="location.children && expandedStateMap.get(location.accountNumber)">
                <ng-container *ngTemplateOutlet="recursiveList; context: {$implicit: location.children}"></ng-container>
            </ul>
        </li>
    </ng-template>

    <ng-container *ngTemplateOutlet="recursiveList; context: {$implicit: filteredHierarchy$ | async}"></ng-container>
</ul>