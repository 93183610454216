import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RfpManagerGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.rfpUser$.pipe(map(user => {
      if(user && (user.roleOptions?.isManager || user.roleOptions?.isAdmin)) {
        return true;
      } 
      else {
        this.router.navigateByUrl("")
        return false
      }
    })
  )}
  
}
