/* eslint-disable max-len */
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { XpoGridToolbar, XpoGridToolbarProjectContentComponent } from './toolbar.component';
import { XpoGridToolbarActions } from './toolbar-actions/toolbar-actions.component';
import { XpoGridToolbarDynamicMore, XpoBoardGridToolbarActionsMore } from './toolbar-actions/toolbar-dynamic-more/toolbar-dynamic-more.component';
import { XpoGridToolbarSettings } from './toolbar-settings/toolbar-settings.component';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoUtilsModule } from '@xpo-ltl/ngx-ltl-core/utils';
import { XpoDownloadButtonModule } from '@xpo-ltl/ngx-ltl-core/download-button';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';

@NgModule({
  declarations: [
    XpoGridToolbar,
    XpoGridToolbarActions,
    XpoBoardGridToolbarActionsMore,
    XpoGridToolbarDynamicMore,
    XpoGridToolbarSettings,
    XpoGridToolbarProjectContentComponent,
  ],
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    XpoSelectModule,
    CommonModule,
    MatSlideToggleModule,
    FormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    XpoUtilsModule,
    XpoDownloadButtonModule,
    XpoButtonModule,
    MatTreeModule,
    MatFormFieldModule,
    XpoIconModule,
  ],
  exports: [
    XpoGridToolbar,
    XpoGridToolbarActions,
    XpoBoardGridToolbarActionsMore,
    XpoGridToolbarDynamicMore,
    XpoGridToolbarSettings,
    XpoGridToolbarProjectContentComponent,
  ],
})
export class XpoGridToolbarModule {}
