import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { RfpMgmtApiService, RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RfpMgmtUser } from '../_models/lookups/rfpMgmtUser';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly authUser$: Observable<RfpMgmtUser>;
  readonly rfpUser$: Observable<RfpUser>;

  private profilePictureCache: Map<string, SafeUrl> = new Map();

  constructor(
    private rfpApi: RfpMgmtApiService, 
    private sanitizer: DomSanitizer
  ) {
    this.authUser$ = this.rfpApi.getLoggedInUser().pipe(shareReplay(1));
    this.rfpUser$ = this.authUser$.pipe(
      switchMap(user => this.rfpApi.getUser({ employeeId: user.employeeId }).pipe(
        catchError(error => {
          if (error.status === 404) {
            return of(null); // Return null if user is not found
          }
          throw error; // Re-throw other errors
        })
      )),
      shareReplay(1)
    );

    this.authUser$ = this.rfpApi.getLoggedInUser().pipe(shareReplay(1))
    this.rfpUser$ = this.authUser$.pipe(
      switchMap(user => this.rfpApi.getUser({ employeeId: user.employeeId })),
      catchError(error => {
        throw error;
      }),
      shareReplay(1)
    );
  }

  isUserManager(): Observable<boolean> {
    return this.rfpUser$.pipe(
      map(user => user.roleOptions?.isManager || user.roleOptions?.isAdmin),
      catchError(() => of(false))
    )
  }

  isUserAdmin(): Observable<boolean> {
    return this.rfpUser$.pipe(
      map(user => user.roleOptions?.isAdmin ),
      catchError(() => of(false))
    )
  }

  getProfilePicture(username: string): Observable<SafeUrl>{
    const cachedProfilePicture = this.profilePictureCache.get(username);
    if (cachedProfilePicture) {
      return of(cachedProfilePicture);
    }
    return this.fetchProfilePicture(username).pipe(shareReplay(1));
  }
  
  fetchProfilePicture(username: string): Observable<SafeUrl> {
    return this.rfpApi.getUserPicture({ username: username }, { size: 'large' }, null, { responseType: 'blob' }).pipe(
      switchMap((response: Blob) => {
        const contentType = response.type;
        if (contentType === 'image/svg+xml') {
          const url = URL.createObjectURL(response);
          const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
          this.profilePictureCache.set(username, safeUrl);
          return of(safeUrl);
        } else {
          return new Observable<SafeUrl>((observer) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
              this.profilePictureCache.set(username, safeUrl);
              observer.next(safeUrl);
              observer.complete();
            };
            reader.readAsDataURL(response);
          });
        }
      }),
      shareReplay(1)
    );
  }
}