<div class="horizontal-container flex-gap-64" [formGroup]="mexicoForm">
    <div class="vertical-container">
        <h2>General Info</h2>
        
        <mat-form-field floatLabel="always">
            <mat-label>Tariff</mat-label>
            <mat-select required placeholder="Select Mexico Tariff" xpoSelect [formControlName]="mexicoFormFields.Tariff">
                <mat-option *ngFor="let tariff of mexicoTariffs" [value]="tariff"> {{ tariff.tariffName }} - {{ tariff.effectiveDate | date: "MM/dd/yyyy" }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>Discount Amount</mat-label>
            <input matInput type="number" step="0.01" required name="mexicoFormFields.DiscountAmount" class="inputRight" [formControlName]="mexicoFormFields.DiscountAmount"/>
            <span class="inputSuffix">%</span>
        </mat-form-field>
    </div>

    <div class="vertical-container">
        <h2>Accessorial Overrides</h2>

        <div class="vertical-container">
            <div *ngFor="let control of accessorialFormArrayControls(); let i = index" [formGroup]="control" class="vertical-container">

                <ng-container *ngVar="control.get(mexicoAccessorialFormFields.Accessorial).value as acc">
                    <span>
                        <mat-slide-toggle  style="margin: 8px 0;" [formControlName]="mexicoAccessorialFormFields.Selected"><h3>{{ acc.code }} - {{ acc.name }}</h3></mat-slide-toggle>
                    </span>

                    <mat-form-field floatLabel="always">
                        <mat-label>Negotiated Rate (USD)</mat-label>
                        <span class="inputPrefix">$</span>
                        <input matInput type="number" step="0.01" required name="mabdFormFields.MustArriveByAmount" class="inputRight" [formControlName]="mexicoAccessorialFormFields.Amount"/>
                        <mat-hint align="end">Standard Rate: {{ standardRates[acc.code] | currency : 'USD' : 'symbol'  }} </mat-hint>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="vertical-container" *ngIf="questionnaire">
        <h2>Questionnaire</h2>
        <div *ngFor="let question of questionnaire; index as i" class="questionnaire">
            <div class="question">{{ i+1 }}. {{ question.question }}</div>
            <p class="answer">{{ question.answer }}</p>
        </div>
    </div>


</div>