import { Component } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { GridApi, GridOptions, RowNode, ColDef, ColGroupDef } from "ag-grid-enterprise";
import { DeletePremiumServiceAssignmentPath, DeletePremiumServiceAssignmentQuery, PremiumSvcAsgmntResponse, RfpMgmtApiService, RfpMgmtRfpPremiumServiceAssignment } from '@xpo-ltl/sdk-rfpmgmt';
import { GridColumnPartialsHelper } from "src/app/_reusable/grid-column-partial-helper";
import { GridReadyEvent, RowDataTransaction, RowSelectedEvent } from 'ag-grid-community';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { TooltilListPipeData } from 'src/app/_reusable/pipes/tooltipListPipe';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PsaeAddDialogResponse, PsaeAssignmentAddDialogComponent } from './psae-assignment-add-dialog/psae-assignment-add-dialog.component';

interface RegionCodeData extends LookupCodeNamePair {
  assigned: boolean;
  mbd: string;
  mbs: string;
}

@Component({
  selector: 'admin-psae-assignment',
  templateUrl: './admin-psae-assignment.component.html',
  styleUrls: ['./admin-psae-assignment.component.scss']
})
export class AdminPsaeAssignmentComponent {

  salesRegions: RegionCodeData[] = [];
  salesRegionsCodes: string[] = []

  columnDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.RowIndex,
    {
      field: "channelCode",
      headerName: "Channel Code",
      filter: 'agTextColumnFilter',
      rowGroup: true,
      rowGroupIndex: 1,
      hide: true,
    },
    {
      field: "employeeFullName",
      headerName: "Employee Name",
      filter: 'agTextColumnFilter',
      rowGroup: true,
      rowGroupIndex: 2,
      hide: true,
    },
    {
      field: "employeeId",
      headerName: "Employee ID",
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      field: "accessorialCode",
      headerName: "Accessorial Code",
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: ['MBS', 'MBD'],
      }
    },
    {
      field: "salesRegionCode",
      headerName: "Sales Region Code",
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: this.salesRegionsCodes,
      }
    },
  ]

  selectedNode: RowNode;
  gridApi: GridApi;
  gridLoadingData: boolean = false;
  gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      editable: false,
    },
    groupDisplayType: "groupRows",
    columnDefs: this.columnDefs,
    groupDefaultExpanded: 1,
    rowSelection: 'single',
    isRowSelectable: (node) => {
      return !node.group
    },
    onRowSelected: (event: RowSelectedEvent) => {
      if(event.node.isSelected()) {
        this.selectedNode = event.node;
      }
      else if(this.selectedNode?.id == event.node.id) {
        this.selectedNode = null;
      }
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
          }
        }
      ]
    },
  }


  constructor(
    private rfpApi: RfpMgmtApiService, 
    private snackbar: XpoSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void { }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api
    this.getGridData();
  }

  getGridData() {
    this.gridApi.showLoadingOverlay();
    this.rfpApi.getAllPremiumServiceAssignments().subscribe(resp => {
      const data = resp.items.sort((a, b) =>  
      a.salesRegionCode.localeCompare(b.salesRegionCode));
      this.gridApi.setRowData(data);
      this.getAllRegions(data);
    })
  }

  getAllRegions(assigned: PremiumSvcAsgmntResponse[]){
    this.rfpApi.getSalesRegions().subscribe(resp => {
      resp.items.push({ code: "NAT", name: "NATIONAL" })
      
      this.salesRegions = resp.items.map(region => {
        const mbdUsr = assigned.find(x => x.salesRegionCode === region.code && x.accessorialCode === "MBD")
        const mbsUsr = assigned.find(x => x.salesRegionCode === region.code && x.accessorialCode === "MBS")
        
        this.salesRegionsCodes.push(region.code)

        return {
          ...region,
          assigned: (mbdUsr !== undefined || mbsUsr !== undefined),
          mbd: mbdUsr?.employeeFullName,
          mbs: mbsUsr?.employeeFullName,
        }
      })
    });
  }

  save(){
    const assignments: RfpMgmtRfpPremiumServiceAssignment[] = [];

    this.gridApi.forEachNode(x => {
      if(!x.group){
        const nodeData: PremiumSvcAsgmntResponse = x.data;
        assignments.push(nodeData)
      }
    })


    this.rfpApi.upsertPremiumServiceAssignment({assignments}).subscribe({
      next: resp => {
        this.snackbar.success("Save successful")
        this.getGridData();
      },
      error: err => {
        this.snackbar.error("There was an issue saving")
      }
    })

  }

  insertRow(initData?: PremiumSvcAsgmntResponse) {
    if(!initData){
      initData = {
        ...this.selectedNode.data,
        accessorialCode: null,
        salesRegionCode: null
      }
    }

    let transaction: RowDataTransaction = {
      add: [initData],
    }
    this.gridApi.applyTransaction(transaction);
  }

  deleteRow(){
    const rowData: RfpMgmtRfpPremiumServiceAssignment = this.selectedNode.data

    const path: DeletePremiumServiceAssignmentPath = {
      channelCode: rowData.channelCode,
      accessorialCode: rowData.accessorialCode,
      salesRegionCode: rowData.salesRegionCode
    }

    const query: DeletePremiumServiceAssignmentQuery = {
      reviewerEmployeeId: rowData.employeeId
    }

    this.rfpApi.deletePremiumServiceAssignment(path, query).subscribe(x => {
      let transaction: RowDataTransaction = {
        remove: [this.selectedNode.data],
      }
      this.gridApi.applyTransaction(transaction);
      this.gridApi.deselectAll();
    })
  }

  formatRegionDisplay(region: RegionCodeData){
    return `${region.code} - ${region.name}`
  }
  
  getRegionStatus(region: RegionCodeData){
    return region.assigned ? 'green' : 'red'
  }

  getRegionTooltip(region: RegionCodeData): TooltilListPipeData {
    return {
      header: "Assigned",
      lines: [
        `MBS: ${region.mbs ?? "None"}`,
        `MBD: ${region.mbd ?? "None"}`
      ]
    }
  }

  openAddUserDialog(){
    this.dialog.open(PsaeAssignmentAddDialogComponent).afterClosed().subscribe((response: PsaeAddDialogResponse) => {
      if(response && response.newEmployee && response.channelCode){
        const newRow: PremiumSvcAsgmntResponse = {
          employeeFullName: response.newEmployee.employeeFullName,
          accessorialCode: null,
          channelCode: response.channelCode,
          salesRegionCode: null,
          employeeId: response.newEmployee.employeeId,
        }

        this.insertRow(newRow);
      }
    })
  }
}
