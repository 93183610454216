<div class="horizontal-container flex-gap-64">
    <div class="vertical-container">
        <div class="vertical-container flex-gap-24">

            <div *ngFor="let control of mustArriveByControls(); let i = index" [formGroup]="control" class="vertical-container">

                <mat-slide-toggle style="margin: 8px 0;" [formControlName]="mabdFormFields.MustArriveBySelected"><h3>{{ control.get(mabdFormFields.MustArriveByTier).value.name }}</h3></mat-slide-toggle>

                <mat-form-field floatLabel="always">
                    <mat-label>Standard Rate (USD)</mat-label>
                    <span class="inputPrefix">$</span>
                    <input matInput type="number" step="0.01" required name="mabdFormFields.MustArriveByAmount" class="inputRight" [formControlName]="mabdFormFields.MustArriveByAmount"/>
                </mat-form-field>

                <mat-form-field floatLabel="always">
                    <mat-label>Unit of Measure</mat-label>
                    <mat-select required placeholder="Select Unit of Measure" xpoSelect [formControlName]="mabdFormFields.UnitOfMeasure">
                        <mat-option *ngFor="let uom of getUomOptions(i)" [value]="uom"> {{ uom }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="vertical-container" *ngIf="questionnaire">
        <h2>Questionnaire</h2>
        <div *ngFor="let question of questionnaire; index as i" class="questionnaire">
            <div class="question">{{ i+1 }}. {{ question.question }}</div>
            <p class="answer">{{ question.answer }}</p>
        </div>
    </div>


</div>