<div class="region-container">
  <xpo-status-indicator *ngFor="let region of salesRegions" [statusText]="formatRegionDisplay(region)" [statusColor]="getRegionStatus(region)" [matTooltip]="getRegionTooltip(region) | tooltipList" matTooltipPosition="right"></xpo-status-indicator>
</div>

<xpo-ag-grid>
  <xpo-grid-toolbar [gridOptions]="gridOptions">    
      <xpo-grid-toolbar-actions-group>
        <button mat-button 
            (click)="save()"
            [disabled]="gridLoadingData">
            <xpo-icon xpoIconSmall svgIconName="save"></xpo-icon> Save Grid
        </button>
        <button mat-button 
            (click)="openAddUserDialog()"
            [disabled]="gridLoadingData">
            <xpo-icon xpoIconSmall svgIconName="add"></xpo-icon> Add User
        </button>
        <button mat-button 
            (click)="insertRow()"
            [disabled]="gridLoadingData || selectedNode == null">
            <xpo-icon xpoIconSmall svgIconName="arrow-from-top"></xpo-icon> Quick Insert Row
        </button>
        <button mat-button 
            (click)="deleteRow()"
            [disabled]="gridLoadingData || selectedNode == null">
            <xpo-icon xpoIconSmall svgIconName="delete"></xpo-icon> Delete Row
        </button>
      </xpo-grid-toolbar-actions-group>
  </xpo-grid-toolbar>

  <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</xpo-ag-grid>