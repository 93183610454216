import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EmailSubscriptionOptions, GetRfpUserSubscriptionsPath, RfpMgmtApiService, SaveRfpSubscriptionsRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { LookupService } from 'src/app/_services/lookup.service';
import { SubscriptionDialogInput } from './subscription-dialog-input';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Component({
  selector: 'subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent implements OnInit {
  currentUserSubscriptions: EmailSubscriptionOptions;
  dialogInput: SubscriptionDialogInput;
  subscriptionFormGroup: UntypedFormGroup
  objectKeys = Object.keys;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SubscriptionDialogInput, public dialogRef: MatDialogRef<SubscriptionDialogComponent>, private rfpApi: RfpMgmtApiService, private formBuilder: UntypedFormBuilder, public lookup: LookupService, private snackbar: XpoSnackBar) {
    this.dialogInput = data
  }

  ngOnInit(): void {
    this.getSubscriptionStatus();
  }

  createForm(defaultSubs: EmailSubscriptionOptions){
    this.lookup.subscriptionOptions$.subscribe(options => {
      this.subscriptionFormGroup = this.formBuilder.group(
        options.reduce((acc, option) => {
          const controlName = this.mapLookupCodeToObject(option.code)
          const defaultValue = (this.dialogInput.unsubscribeCode === option.code) ? false : defaultSubs[controlName] ?? true
          acc[controlName] = [defaultValue];
          return acc;
        }, {})
      );
    })
  }

  getSubscriptionStatusColor(code: string){
    return this.currentUserSubscriptions[code] ? "green" : "red"
  }

  getSubscriptionStatus() {
    const request: GetRfpUserSubscriptionsPath = {
      rfpId: this.dialogInput.salesRfpId,
      userEmailAddress: this.dialogInput.userEmailAddress
    }

    this.rfpApi.getRfpUserSubscriptions(request).subscribe(response => {
      this.currentUserSubscriptions = response.subscriptions
      this.createForm(response.subscriptions);
    })
  }


  savePreferences(){
    const request: Partial<SaveRfpSubscriptionsRqst> = {
      salesRfpId: this.dialogInput.salesRfpId,
      emailId: this.dialogInput.userEmailAddress,
    }

    for(const key of Object.keys(this.subscriptionFormGroup.value)){
      request[key] = this.subscriptionFormGroup.value[key]
    }
    
    this.rfpApi.saveRfpSubscriptions(request as SaveRfpSubscriptionsRqst).subscribe(response => {
      this.snackbar.success("Successfully Updated Email Notification Preferences")
      this.dialogRef.close();
    })
  }

  mapLookupCodeToObject(code: string): string{
    return code.charAt(0).toLowerCase() + code.slice(1);
  }
}
