
<div class="message-container" [style.height]="computedHeight">
    <rfp-message *ngFor="let message of rfpMessages" [message]="message" (onDeleteMessage)="deleteMessage($event)" (onEditMessage)="editMessage($event)" (onDocumentSelected)="documentSelected($event)"></rfp-message>
</div>
<div class="message-entry-container">
    <mat-form-field appearance="standard">
        <textarea rows="2" matInput placeholder="Aa" [(ngModel)]="messageBoxText"></textarea>
    </mat-form-field>

    <button xpo-action-button class="lock-button" [ngClass]="messageIsPrivate ? 'lock-button-locked' : ''" (click)="toggleIsPrivate()" matTooltip="Mark this message as an 'Internal' Sales message, hiding it from Pricing">
        <xpo-icon xpoIconLarge [iconName]="messageIsPrivateIcon"></xpo-icon>
    </button>

    <button mat-flat-button xpoButton (click)="addNewMessage()" [disabled]="messageBoxText.length == 0">Send</button>
</div>