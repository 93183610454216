<div xpoDialog>
    <h1 mat-dialog-title>
      Email Notification Preferences
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content>
        <form *ngIf="subscriptionFormGroup; else loading" [formGroup]="subscriptionFormGroup">
        
            <div *ngFor="let subscription of lookup.subscriptionOptions$ | async">
                <mat-slide-toggle [formControlName]="mapLookupCodeToObject(subscription.code)">
                    <xpo-status-indicator [statusText]="subscription.name" [statusColor]="getSubscriptionStatusColor(mapLookupCodeToObject(subscription.code))"></xpo-status-indicator>
                </mat-slide-toggle>
            </div>
        
        </form>
    </div>

    <div mat-dialog-actions>
        <xpo-button-group>
            <button mat-stroked-button xpoButton (click)="dialogRef.close()">Close</button>
        </xpo-button-group>
        
        <xpo-button-group>    
            <button mat-flat-button xpoButton (click)="savePreferences()">Save</button>
        </xpo-button-group>
      </div>
</div>


<ng-template #loading>
    <div class="spinner-container">
        <h4>Loading Preferences</h4>
        <div class="spinner">
            <xpo-busy-loader></xpo-busy-loader>
        </div>
    </div>
</ng-template>