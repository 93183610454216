<div xpoDialog>
    <h1 mat-dialog-title>
        Bulk Edit {{ selectedUsers.length }} Users
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>

    <mat-dialog-content class="dialog-content">
        <form [formGroup]="userEditForm" class="user-edit-form flex-gap-64">
            <div class="vertical-container flex-gap-8">
                <h3>User Options</h3>
                <ng-container *ngFor="let flag of userRoleFlags">
                    <div *ngIf="isVisibleUserOption(flag)" class="flag-toggle-group vertical-container flex-gap-16">
                        <div formGroupName="{{flag}}" class="vertical-container flex-gap-8">
                            <mat-slide-toggle formControlName="override" (change)="onOverrideChange(flag)" class="override-toggle">
                                Override '{{ flagLabels[flag] }}'
                            </mat-slide-toggle>
                            <mat-slide-toggle formControlName="value" [disabled]="!getFormGroup(flag).get('override').value" class="value-toggle">
                                Enable
                            </mat-slide-toggle>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="vertical-container flex-gap-8">
                <h3>Application Options</h3>
                <ng-container *ngFor="let flag of applicationFlags">
                    <div *ngIf="isVisibleApplicationOption(flag)" class="flag-toggle-group vertical-container flex-gap-16">
                        <div formGroupName="{{flag}}" class="vertical-container flex-gap-8">
                            <mat-slide-toggle formControlName="override" (change)="onOverrideChange(flag)" class="override-toggle">
                                Override '{{ flagLabels[flag] }}'
                            </mat-slide-toggle>
                            <mat-slide-toggle formControlName="value" [disabled]="!getFormGroup(flag).get('override').value" class="value-toggle">
                                Enable
                            </mat-slide-toggle>
                        </div>
                    </div>
                </ng-container>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button xpoLargeButton xpoButton [disabled]="userEditForm.pristine || userEditForm.invalid"
            (click)="submit()">Submit</button>
    </mat-dialog-actions>
</div>
