<div xpoDialog>
    <h1 mat-dialog-title>
        User Select 
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content style="padding-bottom: 8px; height: 680px">
        <xpo-ag-grid>
            <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </xpo-ag-grid>
    </div>
  
    <div mat-dialog-actions>
        <span class="selectionDisplay">
            <button mat-flat-button xpoLargeButton xpoButton [disabled]="!selectedUser" (click)="selectUser()">Select</button>
            <h1 *ngIf="selectedUser">Selected: {{ selectedUser.employeeFullName }}</h1>
        </span>
        <xpo-button-group>
            <button mat-stroked-button xpoLargeButton xpoButton (click)="reset()">Reset</button>
        </xpo-button-group>
    </div>
</div>