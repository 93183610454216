import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountOverview } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { LookupService } from 'src/app/_services/lookup.service';
import { TabStatus, OnSaveEvent, SaveEvent } from '../../../rfp-edit.component';
import { RfpBid } from 'src/app/_models/rfp/rfpBid';
import { Observable, of } from 'rxjs';
import { RfpService } from 'src/app/_services/rfp.service';

export enum RfpGeneralFormFields {
  SalesProgram = "salesProgram",
  IsBid = "isBid",
  BidForm = "bidForm",
  BidDate = "bidDate",
  BidReceiveDate = "bidReceiveDate",
  BidDueReason = "bidDueReason",
  Narrative = "narrative"
}

const DisabledSalesProgramCodes: { [code: string]: boolean; } = {
  MonAndAdj: true,
  MonitorandAdjustClaimsELS: true,
  MonitorandAdjustFirearms: true,
  MonitorandAdjustFSC: true,
  MonitorandAdjustGrowth: true,
  MonitorandAdjustWinback: true,
  MonitorandAdjustYellow: true,
  OptimizedPricingProgram: true,
  PricingInitiated: true,
  RetailRollout: true,
};

@Component({
  selector: 'rfp-general',
  templateUrl: './rfp-general.component.html',
  styleUrls: ['./rfp-general.component.scss']
})
export class RfpGeneralComponent implements OnInit, OnSaveEvent   {
  @Input() account: AccountOverview;
  @Input() rfpDetails: RfpDetails;
  @Output() rfpSave = new EventEmitter<SaveEvent>();

  rfpGeneralForm: UntypedFormGroup;
  rfpGeneralFormFields = RfpGeneralFormFields;
  preChangeNarrative: string;

  constructor(
    public formBuilder: UntypedFormBuilder, 
    public lookup: LookupService,
    protected rfpService: RfpService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createForm();
    }
  }

  createForm(){
    this.rfpGeneralForm = this.formBuilder.group(
      {
        [RfpGeneralFormFields.SalesProgram]:                [this.rfpDetails.salesProgram, Validators.required],
        [RfpGeneralFormFields.Narrative]:                   [this.rfpDetails.narrative],
        [RfpGeneralFormFields.IsBid]:                       [this.rfpDetails.bid !== null, Validators.required],
        [RfpGeneralFormFields.BidForm]:                     this.formBuilder.group(
          {
            [RfpGeneralFormFields.BidDate]:           [this.rfpDetails.bid?.bidDate, Validators.required],
            [RfpGeneralFormFields.BidReceiveDate]:    [this.rfpDetails.bid?.bidReceiveDate],
            [RfpGeneralFormFields.BidDueReason]:      [this.rfpDetails.bid?.bidDueReason],
          }
        )
      }
    )

    this.rfpGeneralForm.get(RfpGeneralFormFields.IsBid).valueChanges.subscribe(newValue => {
      this.setFormEnabled(RfpGeneralFormFields.BidForm, newValue)
    })

    setTimeout(() => {
      this.handleEditability();
    }, 0);
  }

  setFormEnabled(formControlName: string, enabled: boolean){
    if(enabled){
      this.rfpGeneralForm.get(formControlName).enable()
    }
    else {
      this.rfpGeneralForm.get(formControlName).disable()
    }
  }

  isBid(): boolean {
    return this.rfpGeneralForm.get(RfpGeneralFormFields.IsBid).value
  }

  editingNarrative(): boolean {
    return this.rfpGeneralForm.get(RfpGeneralFormFields.Narrative).enabled 
  }

  rfpNarrativeDifferentThanForm(){
    return this.rfpDetails.narrative != this.preChangeNarrative
  }

  toggleNarrativeEditing() {
    let narrativeFormControl = this.rfpGeneralForm.get(RfpGeneralFormFields.Narrative)
    if(narrativeFormControl.disabled){
      this.preChangeNarrative = narrativeFormControl.value;
      narrativeFormControl.enable() 
    }
    else {
      narrativeFormControl.disable()
    }
  }

  applyNarrativeChanges(){
    this.toggleNarrativeEditing();
    this.rfpGeneralForm.markAsDirty();
  }

  resetNarrativeChanges(){
    let narrativeFormControl = this.rfpGeneralForm.get(RfpGeneralFormFields.Narrative)
    narrativeFormControl.setValue(this.rfpDetails.narrative);
    this.toggleNarrativeEditing();
  }

  cancelNarrativeChanges(){
    let narrativeFormControl = this.rfpGeneralForm.get(RfpGeneralFormFields.Narrative)
    narrativeFormControl.setValue(this.preChangeNarrative);
    this.toggleNarrativeEditing();

    if(this.rfpDetails.narrative != this.preChangeNarrative) this.rfpGeneralForm.markAsDirty();
  }

  getTabStatus(): TabStatus {
    return { 
      complete: this.rfpGeneralForm?.enabled ? this.rfpGeneralForm?.valid : true, 
      canSave: true,
      unsavedChanges: this.rfpGeneralForm?.dirty,
      tabName: "General"
    };
  }

  onRfpSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent  = {
      changes: []
    }
    saveEvent.changes.push({ key: RfpGeneralFormFields.SalesProgram,  value: this.rfpGeneralForm.get(RfpGeneralFormFields.SalesProgram).value })
    saveEvent.changes.push({ key: RfpGeneralFormFields.Narrative,     value: this.rfpGeneralForm.get(RfpGeneralFormFields.Narrative).value })

    if(this.rfpGeneralForm.get(RfpGeneralFormFields.IsBid).value) {
      const bidForm = this.rfpGeneralForm.get(RfpGeneralFormFields.BidForm)

      let bidDetails: RfpBid = {
        [RfpGeneralFormFields.BidDate]:         bidForm.get(RfpGeneralFormFields.BidDate).value,
        [RfpGeneralFormFields.BidReceiveDate]:  bidForm.get(RfpGeneralFormFields.BidReceiveDate).value,
        [RfpGeneralFormFields.BidDueReason]:    bidForm.get(RfpGeneralFormFields.BidDueReason).value,
      }
      saveEvent.changes.push({ key: "bid",   value: bidDetails })
    }
    else {
      saveEvent.changes.push({ key: "bid",   value: null })
    }
    
    return of(saveEvent);
  }
  
  onRfpSaveSuccess(): void {
    this.rfpGeneralForm.markAsPristine();
    this.handleEditability();
  }

  isCodeDisabled(code: string){
    return DisabledSalesProgramCodes[code] ?? false
  }

  handleEditability(): void {
    const isEditable = this.rfpService.isEditable();

    if(isEditable) {
      this.rfpGeneralForm.enable();

      if (DisabledSalesProgramCodes[this.rfpDetails.salesProgram]){
        this.rfpGeneralForm.get(RfpGeneralFormFields.SalesProgram).disable()
      }
    } 
    else {
      this.rfpGeneralForm.disable();
    }
    this.rfpGeneralForm.get(RfpGeneralFormFields.Narrative).disable();
    this.setFormEnabled(RfpGeneralFormFields.BidForm, isEditable && this.rfpGeneralForm.get(RfpGeneralFormFields.IsBid).value)
  }
}
