<div class="baseContainer">

    <mat-tab-group [(selectedIndex)]="selectedIndex" xpoVerticalTabs (selectedTabChange)="onTabChanged($event)">
        <mat-tab *ngFor="let lookup of referenceCodes" [label]="lookup.referenceCode" [disabled]="gridLoadingData"></mat-tab>
        <mat-tab label="+ Add New Category"></mat-tab>
    </mat-tab-group>
       
    <xpo-ag-grid>
        <xpo-grid-toolbar [gridOptions]="gridOptions">    
            <xpo-grid-toolbar-actions-group>

                <ng-container *ngIf="!this.isEditing; else gridEditing">
                    <button mat-button 
                        (click)="onStartEditing()"
                        [disabled]="gridLoadingData">
                        <xpo-icon xpoIconSmall svgIconName="edit"></xpo-icon> Enable Editing
                    </button>
                </ng-container>
        
                <ng-template #gridEditing>
                    <button mat-button 
                        (click)="onCancelEditing()"
                        [disabled]="gridLoadingData">
                        <xpo-icon xpoIconSmall svgIconName="cancel"></xpo-icon> Cancel Editing
                    </button>
                    <button mat-button 
                        (click)="addNewEntry()"
                        [disabled]="gridLoadingData">
                        <xpo-icon xpoIconSmall svgIconName="add"></xpo-icon> Add Entry
                    </button>
        
                    <button mat-button 
                        (click)="deleteSelected()"
                        [disabled]="selectedRows?.length == 0 || gridLoadingData">
                        <xpo-icon xpoIconSmall svgIconName="delete"></xpo-icon> Delete Entries <ng-container *ngIf="selectedRows?.length > 0">({{selectedRows?.length}})</ng-container>
                    </button>
        
                    <button mat-button 
                        (click)="saveEdits()"
                        [disabled]="!editsExist() || gridLoadingData">
                        <xpo-icon xpoIconSmall svgIconName="save"></xpo-icon> Save Edits
                    </button>
        
                    <button mat-button 
                        (click)="clearChanges()"
                        [disabled]="!editsExist() || gridLoadingData">
                        <xpo-icon xpoIconSmall svgIconName="undo"></xpo-icon> Clear Edits
                    </button>
                </ng-template>
    
            </xpo-grid-toolbar-actions-group>
        </xpo-grid-toolbar>

        <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </xpo-ag-grid>

</div>