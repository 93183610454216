import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContextualHeaderActions, ContextualHeaderBackArrow, ContextualHeaderItems } from '@xpo-ltl/ngx-ltl-core/contextual-header';
import { AutoApproveDynamicPricingResp, AutoApproveDynamicPricingRqst, DptFakOverrideOption, DynamicPricingApiService } from '@xpo-ltl/sdk-dynamicpricing';
import { Observable, forkJoin, of } from 'rxjs';
import { MetricFormat } from 'src/app/_models/enums';
import { FakClassOverride } from 'src/app/_models/proposal/fakClassOverride';
import { AccountOverview, RfpContact } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { LookupService } from 'src/app/_services/lookup.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { OnSaveEvent, SaveEvent } from '../rfp-edit.component';
import { RfpMgmtApiService, UpdateRfpsStatusRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { RfpDocumentsComponent } from '../components/forms/rfp-documents/rfp-documents.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomerInfoDialogInput } from '../components/dialogs/customer-info-dialog/customer-info-dialog-input';
import { CustomerInfoDialogComponent } from '../components/dialogs/customer-info-dialog/customer-info-dialog.component';
import { XpoTileModel } from '@xpo-ltl/ngx-ltl-core/tile-ribbon';
import { SnackBarErrorListComponent } from 'src/app/_reusable/snackbars/snack-bar-error-list/snack-bar-error-list.component';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigSettings, FeatureFlags } from 'src/app/_models/configSettings';
import { SubscriptionDialogComponent } from '../components/dialogs/subscription-dialog/subscription-dialog.component';
import { SubscriptionDialogInput } from '../components/dialogs/subscription-dialog/subscription-dialog-input';
import { UserService } from 'src/app/_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { RfpService } from 'src/app/_services/rfp.service';

@Component({
	selector: 'rfp-dynamic',
	templateUrl: './rfp-dynamic.component.html',
	styleUrls: ['./rfp-dynamic.component.scss']
})
export class RfpDynamicComponent implements OnInit {
	@Input() rfpDetails: RfpDetails;
	@Input() accountOverview: AccountOverview;
	@Input() rfpContact: RfpContact;
	@ViewChild(RfpDocumentsComponent) documentComponent!: RfpDocumentsComponent;
	@ViewChildren("savable") saveableChildren!: QueryList<OnSaveEvent>;
	@Output() refreshRfpDetails = new EventEmitter<number>();

	savingRfpData: boolean = false;
	makingApiCall: boolean = false;
	submittingToDynamicPricing: boolean = false;
	unsavedRfpDetails: any = {};
	selectedTabIndex: number = 0;

	contextualHeaderItems: ContextualHeaderItems[] = [];
  	actionsConfig: ContextualHeaderActions = { };
	tiles: XpoTileModel[] = [];

	featureFlags: FeatureFlags;

	constructor(
		public rfpApi: RfpMgmtApiService, 
		public dynamicApi: DynamicPricingApiService, 
		private configManagerService: ConfigManagerService, 
		private snackbar: XpoSnackBar, 
		public formBuilder: UntypedFormBuilder, 
		public lookup: LookupService, 
		private dialog: MatDialog, 
		private confirmDialog: XpoConfirmDialog, 
		private userService: UserService, 
		private route: ActivatedRoute,
		protected rfpService: RfpService,
	) {
	}

	ngOnInit(): void {
		this.featureFlags = this.configManagerService.getSetting<FeatureFlags>(ConfigSettings.FeatureFlags);

		if(this.route.snapshot.queryParams.unsubscribe){
			this.openSubscriptionsDialog(this.route.snapshot.queryParams.unsubscribe);
		  }
		this.createContextualHeader();
	} 
	
	createContextualHeader() {
		forkJoin([this.lookup.rfpTypes$, this.lookup.rfpStatuses$]).subscribe(response => {
			const rfpTypes = response[0];
			const rfpStatuses = response[1];
			
			this.contextualHeaderItems = [
				{
					labelTitle: 'RFP ID',
					title: `${this.rfpDetails.salesRfpId}`,
				},
				{
					labelTitle: 'RFP Type',
					title: rfpTypes.find(x => x.code.toLocaleLowerCase() === this.rfpDetails.rfpTypeCode.toLocaleLowerCase())?.name,
				},
				{
					labelTitle: 'Status',
					title: rfpStatuses.find(x => x.code.toLocaleLowerCase() === this.rfpDetails.rfpStatusCode.toLocaleLowerCase())?.name
				},
				{
					labelTitle: 'Account',
					title: `${this.rfpDetails.madCode}`,
					action: {
					action: {
						icon: 'visibility',
						itemActionFn: this.openCustomerInfoDialog.bind(this),
					},
					},
				},
				{
					labelTitle: 'Agreement',
					title: `${this.rfpDetails.pricingAgreementId}`,
				},
				{
					labelTitle: 'Requested Date',
					title: UtilityService.formatDate(this.rfpDetails.requestedDate),
				},
				{
					labelTitle: 'Renegotiation Date',
					title: UtilityService.formatDate(this.rfpDetails.renegotiationDate),
				},
			]

			this.actionsConfig = {
				actionsList: [
					{
						name: 'RFP Notifications',
						icon: 'notifications',
						actionFn: this.openSubscriptionsDialog.bind(this),
					},
					{
						name: 'Refresh Rfp Details',
						icon: 'refresh',
						actionFn: this.callRefreshRfpDetails.bind(this),
					},
				]
			}

			this.tiles = [
				{
					title: {
					text: this.rfpDetails.pricingType
					},
					label: {
					text: "Pricing Type"
					}
				},
				{
					title: {
					text: this.accountOverview.creditStatus
					},
					label: {
					text: "Credit Status"
					}
				},
				{
					title: {
					text: this.rfpDetails.thirdPartyTypeCode
					},
					label: {
					text: "3PL Type"
					}
				},
				{
					title: {
					text: this.rfpDetails.monthlyRevenuePotential ? UtilityService.formatValue(this.rfpDetails.monthlyRevenuePromise, MetricFormat.Dollar) : "-"
					},
					label: {
					text: "Monthly Promise Revenue"
					}
				},
				{
					title: {
					text: this.rfpDetails.monthlyRevenuePromise ? UtilityService.formatValue(this.rfpDetails.monthlyRevenuePotential, MetricFormat.Dollar) : "-"
					},
					label: {
					text: "Monthly Potential Revenue"
					}
				},
			]
		})
	}
	
	ngOnChanges(changes: SimpleChanges){
		if(changes.rfpDetails && !changes.rfpDetails.firstChange) {
		  this.createContextualHeader()
		}
	}

	isLocationsLocked(): boolean {
		return this.rfpDetails.isChangeAccessorial() || !this.featureFlags.enableDptLocations
	}

	callRefreshRfpDetails(){
		this.refreshRfpDetails.emit(this.rfpDetails.salesRfpId)
	}

	allFormsComplete(){
		const canSaveResp = this.saveableChildren?.toArray().map(child => child.getTabStatus());
		return canSaveResp?.every(x => x.complete ?? true) ?? false;
	}

	canAllSave(){
		if(!this.rfpService.isEditable()) return false;
		const canSaveResp = this.saveableChildren?.toArray().map(child => child.getTabStatus());
		return !this.makingApiCall && (canSaveResp?.every(x => x.canSave ?? true) ?? false);
	}

	onChildSave(saveEvent: SaveEvent) {
		saveEvent.changes.forEach(change => {
			this.unsavedRfpDetails[change.key] = change.value;
		});
	}

	callSaveRfpData(){
		this.saveRfpData().subscribe(saveSuccessful => {
			if(saveSuccessful) {
				this.snackbar.success("Save Successful")
			}
			else {
				this.snackbar.error("Something happened while saving, please try again")
			}
		})
	}

	saveRfpData(): Observable<boolean> {
		const canSaveResp = this.saveableChildren.toArray().map(child => child.getTabStatus());

		if (this.canAllSave()) {
			const saveObservables = this.saveableChildren.map((child: OnSaveEvent) => child.onRfpSave());

			return forkJoin(saveObservables).pipe(
			  tap((saveEvents: SaveEvent[]) => {
				saveEvents.forEach(saveEvent => {
				  this.unsavedRfpDetails = { ...this.unsavedRfpDetails, ...saveEvent.changes.reduce((obj, change) => ({ ...obj, [change.key]: change.value }), {}) };
				});
			  }),
			  tap(() => this.savingRfpData = true),
			  switchMap(() => {
				const updatedRfp = new RfpDetails({ ...this.rfpDetails, ...this.unsavedRfpDetails });
				
				return this.rfpApi.upsertRfp(updatedRfp).pipe(
				  map(() => {
					this.rfpDetails = updatedRfp;
		
					this.saveableChildren.forEach((child: OnSaveEvent) => {
					  child.onRfpSaveSuccess();
					});
		
					return true;
				  }),
				  catchError((error) => {
					return of(false);
				  }),
				  finalize(() => this.savingRfpData = false)
				)
			  }),
			);
		  }
		else {
			this.snackbar.open({
				message: 'Cannot Save, Please See Following Tabs:',
				detailedMessage: canSaveResp.filter(x => !(x.canSave ?? true)).map(x => x.tabName).join(", "),
				status: 'error',
				matConfig: {
					duration: 0,
				},
			});
			return of(false);
		}
	}

	canSubmitToDynamicPricing(): boolean {
		return this.allFormsComplete() && this.rfpService.isEditable() && !this.makingApiCall
	}

	getSubmitToDynamicPricingTooltip(): string {
		if(!this.canSubmitToDynamicPricing()){
			return "There is an error in one of the tabs below. Please check and fix before attempting to submit."
		}
		return null;
	}
	
	submitToDynamicPricing(){
		this.saveRfpData().subscribe((saveSuccessful: boolean) => {
			if(!saveSuccessful) {
				this.makingApiCall = false;
				return;
			}

			this.makingApiCall = true;
			this.submittingToDynamicPricing = true;
			const request = {
				requestType: "AutoApprove",
				rfpHeader: {
					salesRfpId: this.rfpDetails.salesRfpId
				}
			}

			this.dynamicApi.autoApproveDynamicPricing(request as AutoApproveDynamicPricingRqst).subscribe(
				(response: AutoApproveDynamicPricingResp) => { 
					if(response.responseCd.toUpperCase() === "APPROVED".toUpperCase()){
						this.snackbar.success("RFP Successfully Auto Approved") 
					}
					else {
						this.snackbar.open({
							message: 'This RFP did not meet the auto-approval criteria and has been forwarded to the Dynamic Pricing team for review due to the following reasons:',
							detailedMessageComponent: SnackBarErrorListComponent,
							detailedMessageComponentData: response.validationMessages,
							status: 'warn',
							matConfig: {
								duration: 0
							}
						});
					}
				}, 
				(response)=> {
					this.snackbar.open({
						message: `Error Code ${response.code}: ${response.error?.message} `,
						status: 'error',
						matConfig: {
							duration: 0
						}
					});
				}
			).add(() => {
				this.makingApiCall = false;
				this.submittingToDynamicPricing = false;
				this.refreshRfpDetails.emit(this.rfpDetails.salesRfpId)
			})
		})
	}


	showReOpenButton(){
		return this.rfpDetails.rfpStatusCode.toLowerCase() === "close" || this.rfpDetails.rfpStatusCode.toLowerCase() === "cancel"
	  }
	
	  reOpenRfp(){
		const confirmConfig: XpoConfirmDialogConfig = {
		  confirmButtonText: 'Re-Open RFP',
		  rejectButtonText: 'Cancel',
		  icon: 'warning',
		};
	
		this.confirmDialog.confirm(
		  '',
		  'Are you sure you want to Re-Open this RFP?',
		  confirmConfig
		).subscribe((result) => {
		  if(result){
			this.makingApiCall = true;
			this.lookup.rfpStatuses$.subscribe((response) => {
			  var closeStatusCd = response.find(status => status.code.toLocaleLowerCase() === "InSales".toLocaleLowerCase())?.code;
				
			  let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
				statusCode: closeStatusCd,
				rfpIds: [this.rfpDetails.salesRfpId]
			  };
		
			  this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
				() => {
				  this.snackbar.success("Successfuly Re-Opened RFP")
				},
				(error) => {
				  this.snackbar.error("Something happened while trying to re-open this RFP, please try again")
				},
				() => {
				  this.callRefreshRfpDetails();
				}
			  ).add(() => {
				this.makingApiCall = false;
			  })
			});
		  }
		});
	  }
	
	  closeRfp(){
		const confirmConfig: XpoConfirmDialogConfig = {
		  confirmButtonText: 'Close RFP',
		  rejectButtonText: 'Cancel',
		  icon: 'warning',
		};
	
		this.confirmDialog.confirm(
		  '',
		  'Are you sure you want to close this RFP?',
		  confirmConfig
		).subscribe((result) => {
		  if(result){
			this.makingApiCall = true;
			this.lookup.rfpStatuses$.subscribe((response) => {
			  var closeStatusCd = response.find(status => status.code.toLocaleLowerCase() === "close".toLocaleLowerCase())?.code;
				
			  let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
				statusCode: closeStatusCd,
				rfpIds: [this.rfpDetails.salesRfpId]
			  };
		
			  this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
				() => {
				  this.snackbar.success("Successfuly Closed RFP")
				},
				(error) => {
				  this.snackbar.error("Something happened while trying to close this RFP, please try again")
				},
				() => {
				  this.callRefreshRfpDetails();
				}
			  ).add(() => {
				this.makingApiCall = false;
			  })
			});
	
		  }
		});
	}

	navigateToDocumentTab(docId: number){
		this.selectedTabIndex = 2
		this.documentComponent.filterDocById(docId)
	}

	openCustomerInfoDialog(){
		const dialogData: CustomerInfoDialogInput = {
			accountOverview: this.accountOverview,
			rfpDetails: this.rfpDetails,
			rfpContact: this.rfpContact,
			reviews: []
		}

		this.dialog.open(CustomerInfoDialogComponent, {data: dialogData, position: { top: "10vh" }});
	}

	openSubscriptionsDialog(unsubscribeCode?: string){
		this.userService.authUser$.subscribe(user => {
		  const dialogData: SubscriptionDialogInput = {
			salesRfpId: this.rfpDetails.salesRfpId,
			userEmailAddress: user.emailAddress,
			unsubscribeCode: unsubscribeCode ?? undefined
		  }
	  
		  this.dialog.open(SubscriptionDialogComponent, {data: dialogData, position: { top: "10vh" }});
		})
	}
}