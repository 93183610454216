import { forwardRef, Input, ContentChild, ViewChild, EventEmitter, Directive } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { ColumnMovedEvent, ColumnVisibleEvent, GridOptions } from 'ag-grid-community';
import { XpoProjectedFeatureBase } from '../projected-feature-base.class';
import { XpoGridToolbarSettings } from './toolbar-settings/toolbar-settings.component';

@Directive({
  selector: 'xpo-grid-toolbar-projected-content',
  host: {
    'data-cy': 'xpo-grid-toolbar-projected-content',
  },
})
export class XpoGridToolbarProjectContentComponent {}

@Component({
  selector: 'xpo-grid-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: XpoProjectedFeatureBase,
      useExisting: forwardRef(() => XpoGridToolbar),
    },
  ],
  host: { class: 'xpo-grid-toolbar' },
})
export class XpoGridToolbar extends XpoProjectedFeatureBase {
  @Input() gridOptions: GridOptions = null;

  @ContentChild(XpoGridToolbarSettings) toolbarSettings: XpoGridToolbarSettings;

  $columnMovedSubscriber: EventEmitter<ColumnMovedEvent> = new EventEmitter<ColumnMovedEvent>(false);
  $columnHiddenSubscriber: EventEmitter<ColumnMovedEvent> = new EventEmitter<ColumnMovedEvent>(false);
  defaultGridOptions: GridOptions = {};

  defaultGridEvents: GridOptions = {
    onGridReady: () => {
      this.initialize();
    },
    onColumnMoved: (event) => {
      this.$columnMovedSubscriber.emit(event);
    },
    onColumnVisible: (event: ColumnVisibleEvent) => {
      this.$columnHiddenSubscriber.emit(event);
    },
  };

  gridReady: boolean = false;

  constructor() {
    super();
  }

  private initialize(): void {
  }
}
