import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountOverview, RfpContact } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails, RfpReview } from 'src/app/_models/temp/rfpDetails';
import { RfpMgmtApiService, RfpMgmtContactDetails } from '@xpo-ltl/sdk-rfpmgmt';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { Observable, forkJoin } from 'rxjs';
import { PremiumServiceCodes } from './components/forms/rfp-premium-services/rfp-premium-services.service';
import { RfpService } from 'src/app/_services/rfp.service';

export interface SaveEvent {
  changes: Array<{ key: string; value: any }>;
}

export interface TabStatus { 
  complete: boolean, 
  canSave: boolean, 
  unsavedChanges: boolean, 
  tabName: string, 
  message?: string,
  matBadge?: string | number;
};

export interface OnSaveEvent {
  rfpSave: EventEmitter<SaveEvent>;
  onRfpSave(): Observable<SaveEvent>;
  getTabStatus(): TabStatus;
  onRfpSaveSuccess(): void;
  handleEditability(): void;
}

@Component({
  selector: 'rfp-edit',
  templateUrl: './rfp-edit.component.html',
  styleUrls: ['./rfp-edit.component.scss']
})
export class RfpEditComponent implements OnInit {
  salesRfpFromPath: number;
  finishedFetchingRfpData = false;
  finishedFetchingAccountData = false;
  get finishedFetchingData() { return this.finishedFetchingRfpData && this.finishedFetchingAccountData; }
  rfpDetails: RfpDetails;
  rfpReviews: RfpReview[];
  accountOverview: AccountOverview;
  rfpContact: RfpContact;

  constructor(
    private route: ActivatedRoute, 
    private rfpApi: RfpMgmtApiService, 
    private snackBar: XpoSnackBar,
    private rfpService: RfpService
  ) {
    this.salesRfpFromPath = this.route.snapshot.params.salesRfpId
  }

  ngOnInit(): void {
    this.getRfpDetails(this.salesRfpFromPath)
  }
  
  rfpAndAccountPopulated(): boolean {
    return this.rfpDetails != null && this.accountOverview != null && this.rfpContact != null;
  }

  isCancelPricing(){
    return this.rfpDetails?.rfpTypeCode == "CanPricing"
  }

  getRfpDetails(rfpId: number){
    this.rfpApi.getRfpDetails({salesRfpId: rfpId}).subscribe((rfpDetails: RfpDetails) => {
      this.rfpDetails = new RfpDetails(rfpDetails);
      this.rfpService.setRfpDetails(this.rfpDetails);
      
      const requests = {
        accountOverview: this.rfpApi.getAccountOverview({madCode: rfpDetails.madCode}),
        rfpContact: this.rfpApi.getRfpContact({rfpId: rfpDetails.salesRfpId}),
        rfpReviews: this.rfpApi.getReviewSalesDetails({rfpId: rfpDetails.salesRfpId})
      }

      forkJoin(requests).subscribe((resp) => {
          this.accountOverview = resp.accountOverview
          this.rfpContact = resp.rfpContact?.primaryContact;
          this.rfpReviews = resp.rfpReviews.items.map(x => new RfpReview(x));
        }
      ).add(() => { this.finishedFetchingAccountData = true;})
    },
    (error) => {
      this.finishedFetchingAccountData = true; //Since we don't do an account search if the RFP Details errors out, we should set account fetch to true on error.
    }).add(() => { this.finishedFetchingRfpData = true; })
  }
}