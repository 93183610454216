<div class="grid-container">
    <div class="grid-section">
        <div class="section-header">Negotiating Account Code</div>
        <div class="section-content">{{ account.madCode }}</div>
    </div>
    <div class="grid-section">
        <div class="section-header">Account Owner</div>
        <div class="section-content">{{ rfpDetails.salesAgentFullName }}</div>
    </div>
    <div class="grid-section">
        <div class="section-header">Customer Number</div>
        <div class="section-content">{{ account.customerNumber }}</div>
    </div>
    <div class="grid-section">
        <div class="section-header">Customer Name</div>
        <div class="section-content">{{ account.name }}</div>
    </div>
    <div class="grid-section">
        <div class="section-header">Customer Address</div>
        <div class="section-content">{{ account.address }}</div>
        <div class="section-content">{{ account.city }}, {{ account.stateCode }} {{ account.zipCode }} {{ account.countryCode }}</div>
    </div>
    <div class="grid-section">
        <div class="section-header">Credit Status</div>
        <div class="section-content">{{ account.creditStatus }}</div>
    </div>
</div>