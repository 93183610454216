<form class="form-container" [formGroup]="rfpGeneralForm">

    <div class="vert-container">
        <h2>Account Info</h2> 
        <mat-form-field floatLabel="always">
            <mat-label>Sales Program</mat-label>
            <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpGeneralFormFields.SalesProgram">
                <mat-option *ngFor="let program of lookup.salesPrograms$ | async" [value]="program.code" [disabled]="isCodeDisabled(program.code)"> {{ program.name }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="vert-container">
        <h2>Bid</h2>  
        <mat-radio-group class="radioContainer" [formControlName]="rfpGeneralFormFields.IsBid">
            <mat-radio-button [value]="false">No</mat-radio-button>
            <mat-radio-button [value]="true">Yes</mat-radio-button>
        </mat-radio-group>
    </div>

    <div [formGroupName]="rfpGeneralFormFields.BidForm" *ngIf="isBid()" class="vert-container">
        <h2>Bid Information</h2>  
        <mat-form-field floatLabel="always">
            <mat-label>Bid Date</mat-label>
            <input matInput required [matDatepicker]="bidDatePicker" [formControlName]="rfpGeneralFormFields.BidDate">
            <mat-datepicker-toggle matSuffix [for]="bidDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #bidDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>Bid Receive Date</mat-label>
            <input matInput [matDatepicker]="bidReceiveDatePicker" [formControlName]="rfpGeneralFormFields.BidReceiveDate">
            <mat-datepicker-toggle matSuffix [for]="bidReceiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #bidReceiveDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>Bid Due Reason</mat-label>
            <mat-select placeholder="Select item" xpoSelect [formControlName]="rfpGeneralFormFields.BidDueReason">
                <mat-option *ngFor="let reason of lookup.bidReasons$ | async" [value]="reason.code"> {{ reason.name }} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>


</form>

<form [formGroup]="rfpGeneralForm" class="narrative-entry-container">
    <span class="header">
        <h2>Narrative</h2>  
        <xpo-button-group>
            <button mat-stroked-button xpoSmallButton xpoButton *ngIf="editingNarrative() && rfpNarrativeDifferentThanForm()" (click)="resetNarrativeChanges()">Reset</button>
            <button mat-stroked-button xpoSmallButton xpoButton *ngIf="editingNarrative()" (click)="cancelNarrativeChanges()">Cancel</button>
            <button mat-flat-button xpoSmallButton xpoButton *ngIf="editingNarrative()" (click)="applyNarrativeChanges()">Apply Changes</button>
            <button mat-stroked-button xpoSmallButton xpoButton *ngIf="!editingNarrative()" [disabled]="!rfpService.isEditable()" (click)="toggleNarrativeEditing()">Edit Narrative</button>
        </xpo-button-group>
    </span>
    <textarea matInput placeholder="" [formControlName]="rfpGeneralFormFields.Narrative"></textarea>
</form>
