import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,  MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomerInfoDialogInput } from './customer-info-dialog-input';
import { RfpDetails, RfpReview } from 'src/app/_models/temp/rfpDetails';
import { AccountOverview, RfpContact } from 'src/app/_models/temp/rfpAccount';
import { LookupService } from 'src/app/_services/lookup.service';
import { UserSelectDialogComponent, UserSelectDialogResponseStatus } from 'src/app/_reusable/dialogs/user-select-dialog/user-select-dialog.component';
import { UserSelectDialogResponse } from 'src/app/_reusable/dialogs/user-select-dialog/user-select-dialog-response';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { RfpMgmtApiService, RfpFieldData, ListUsersRqst, AssignRfpAnalystReviewerRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpConfigService } from 'src/app/_services/rfp.config.service';

@Component({
  selector: 'customer-info-dialog',
  templateUrl: './customer-info-dialog.component.html',
  styleUrls: ['./customer-info-dialog.component.scss']
})
export class CustomerInfoDialogComponent implements OnInit {

  accountOverview: AccountOverview;
  rfpDetails: RfpDetails;
  rfpContact: RfpContact;
  rdsReview: RfpReview
  psaeReview: RfpReview
  salesRegions: { code: string, label: string}[] = [];
  rfpConfigFields: RfpFieldData[] = [];
  enablePsaeChange: boolean = false;
  enableRdsChange: boolean = false;
  enableSellerChange: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA)public data: CustomerInfoDialogInput,
    public dialogRef: MatDialogRef<CustomerInfoDialogComponent>,
    private dialog: MatDialog, 
    public lookup: LookupService,
    private snackbar: XpoSnackBar,
    private confirmDialog: XpoConfirmDialog,
    private rfpApi: RfpMgmtApiService,
    private rfpConfigService: RfpConfigService
    ){
      this.accountOverview = data.accountOverview;
      this.rfpDetails = data.rfpDetails;
      this.rfpContact = data.rfpContact;
      this.handleReviews(data.reviews);
    }

  ngOnInit(): void {
    this.lookup.salesRegions$.subscribe(values => {
      this.salesRegions = values.map(x => { return { code: x.code, label: `${x.code}-${x.name}`} })
    });

    this.rfpConfigService.configFields$.subscribe(values => {
      this.rfpConfigFields = values;
      const enableSellerChange = this.rfpConfigFields.find(r => r.fieldName.toLocaleLowerCase() === 'enablesellerchange')?.value;
      const enablePsaeChange = this.rfpConfigFields.find(r => r.fieldName.toLocaleLowerCase() === 'enablepsaechange')?.value;
      const enableRdsChange = this.rfpConfigFields.find(r => r.fieldName.toLocaleLowerCase() === 'enablerdschange')?.value;

      this.enablePsaeChange = (enablePsaeChange === "true");
      this.enableSellerChange = (enableSellerChange === "true")
        && (this.rfpDetails.sourceCrmOpportunityId === '-1'
            || this.rfpDetails.sourceCrmOpportunityId === 'unknown'
            || this.rfpDetails.sourceCrmOpportunityId === '-changeAccId');
      this.enableRdsChange = (enableRdsChange === "true");
    });
  }

  handleReviews(reviews: RfpReview[]){
    this.rdsReview  = reviews.find(x => x.isRdsReview());
    this.psaeReview = reviews.find(x => x.isPsaeReview());

  }

  getRegion(){
    if(this.rfpDetails.isDpt == false){
      return this.salesRegions.find(x => x.code === this.rfpDetails.salesRegionCode)?.label ?? this.rfpDetails.salesRegionCode;
    }
    else{
      return this.accountOverview.region;
    }
  }

  getCompanyAddress(){
    return `${ this.accountOverview.address.toTitleCase() }, ${ this.accountOverview.city.toTitleCase() }, ${ this.accountOverview.stateCode } ${ this.accountOverview.zipCode } ${ this.accountOverview.countryCode }`
  }

  openUserSelectDialog(changeType: string){
    const listUserRqst = new ListUsersRqst();
    
    if(changeType === 'rds'){
      const stringRoleType = this.rfpConfigFields.find(x => x.fieldName.toLocaleLowerCase() === 'rdsuserroletype')?.value;
      listUserRqst.includeRoleTypes = stringRoleType.split(';') ?? [];
    }
    else if(changeType === 'psae'){
      const stringRoleType = this.rfpConfigFields.find(x => x.fieldName.toLocaleLowerCase() === 'psaeuserroletype')?.value;
      listUserRqst.includeRoleTypes = stringRoleType.split(';') ?? [];
    }
    else if(changeType === 'seller'){
      const fieldName = this.rfpDetails.pricingType === 'National' ? 'nationalselleruserroletype' : 'localselleruserroletype';
      const stringRoleType = this.rfpConfigFields.find(x => x.fieldName.toLocaleLowerCase() ===  fieldName)?.value;
      listUserRqst.includeRoleTypes = stringRoleType.split(';') ?? [];
    }
    
    this.rfpApi.listUsers(listUserRqst).subscribe(resp => {
      this.dialog.open(UserSelectDialogComponent, { width: "1100px", height: "874px", position: { top: "10vh" }, data: resp.items }).afterClosed().subscribe((response: UserSelectDialogResponse)  => {
        if(response && response?.status != UserSelectDialogResponseStatus.Cancel){
  
          const confirmConfig: XpoConfirmDialogConfig = {
            confirmButtonText: 'Reassign',
            showCancelButton: true,
            icon: 'warning',
          };
  
          this.confirmDialog
            .confirm(
              `Are you sure you want to re-assign this RFP to ${response.user.employeeFullName}?`,
              `Reassign?`,
              confirmConfig
            )
            .subscribe((result) => {
              if(result){
                const assignRfpAnalystReviewerRqst = new AssignRfpAnalystReviewerRqst();
                
                if(changeType === 'rds'){
                  assignRfpAnalystReviewerRqst.rdsEmployeeId = response.user?.employeeId ?? undefined
                }
                else if(changeType === 'psae'){
                  assignRfpAnalystReviewerRqst.psaeEmployeeId = response.user?.employeeId ?? undefined
                }
                else if(changeType === 'seller'){
                  assignRfpAnalystReviewerRqst.salesAgentId = response.user?.employeeId ?? undefined
                }

                this.rfpApi.assignRfpAnalystReviewer(assignRfpAnalystReviewerRqst, {rfpId: this.rfpDetails.salesRfpId}).subscribe(result => {
                  this.snackbar.success(`RFP Reassigned to ${response.user.employeeFullName}`)
                  this.dialogRef.close(true)
                })
              }
            });
        }      
      })
		  
		})
  }
}
