<nav class="xpo-grid-toolbar-container" data-cy="grid-toolbar-navbar">
  <div class="xpo-grid-toolbar-left-section">
    <ng-content select="xpo-grid-toolbar-actions-group"></ng-content>
  </div>
  <div class="xpo-grid-toolbar-center-section">
    <ng-content select="xpo-grid-toolbar-projected-content"></ng-content>
  </div>
  <div class="xpo-grid-toolbar-right-section">
    <ng-content #content select="xpo-grid-toolbar-toggle-buttons-group"></ng-content>
    <ng-content select="xpo-grid-toolbar-settings-group"></ng-content>
  </div>
</nav>
