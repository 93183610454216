import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { RfpMgmtApiService, RfpFieldData, UpsertRfpFieldDataRqst, UpsertRfpFieldDataPath, RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowDragEndEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { GridColumnPartialsHelper } from 'src/app/_reusable/grid-column-partial-helper';
import { CellEditionErrorCellRendererComponent, DataChanges, XpoInlineEditingBase } from '@xpo-ltl/ngx-ag-grid';
import { TeardownLogic } from 'rxjs';
import { RfpConfig } from 'src/app/_models/rfpConfig';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'admin-rfp-config',
  templateUrl: './admin-rfp-config.component.html',
  styleUrls: ['./admin-rfp-config.component.scss']
})
export class AdminRfpConfigComponent extends XpoInlineEditingBase implements OnInit {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  
  userEmployeeId: string = '';
  
  lookupColDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.SelectionWithSelectAll,
    GridColumnPartialsHelper.RowIndex,
    { 
      field: "fieldName",      
      headerName: "Field Name",
      rowDrag: () => this.isEditing,
      cellRenderer: 'cellEditionError',
      filter: 'agTextColumnFilter', 
      editable: () => this.isEditing, 
      cellClassRules: { 
        'xpo-AgGrid-editableCell': () => this.isEditing, 
        'xpo-AgGrid-editableCell--edited': (params) => params.data && this.isCellEdited(params.data[this.keyField], params.colDef.field), 
        'xpo-AgGrid-editableCell--error': (params) => params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
      }, 
    },
    { 
      field: "value",
      headerName: "Value", 
      cellRenderer: 'cellEditionError',
      filter: 'agTextColumnFilter', 
      editable: () => this.isEditing, 
      cellClassRules: { 
        'xpo-AgGrid-editableCell': () => this.isEditing, 
        'xpo-AgGrid-editableCell--edited': (params) => params.data && this.isCellEdited(params.data[this.keyField], params.colDef.field), 
        'xpo-AgGrid-editableCell--error': (params) => params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
      }, 
    },
  ]

  gridData: any;
  keyField: string = "fieldName";
  gridApi: GridApi;
  gridColumnApi : ColumnApi;
  gridLoadingData: boolean = true;
  isEditing: boolean = false;
  reordered: boolean = false;
  selectedRows: RowNode[] = [];

  gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
      valueSetter: (params) => {
        this.handleValueSetter(params, this.keyField);
        return true;
      },
      tooltipValueGetter: (params) => {
        const colDef = params.colDef as ColDef;
        return params.data && this.getErrorDescription(params, colDef.field);
      },
      cellRendererParams: (params) => {
        return {
          error: params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
        };
      }
    },
    onRowDragEnd: (event: RowDragEndEvent) => {
      this.reordered = this.isGridReOrdered();
    },
    onSelectionChanged: (event: SelectionChangedEvent) => {
      this.selectedRows = event.api.getSelectedNodes()
    },
    rowDragManaged: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    singleClickEdit: true,
    rowSelection: "multiple",
    columnDefs: this.lookupColDefs,
    frameworkComponents: {
      cellEditionError: CellEditionErrorCellRendererComponent,
    },
  } 

  constructor(private rfpApi: RfpMgmtApiService, public userService: UserService, private snackbar: XpoSnackBar, private dialog: MatDialog) {
    super()
    this.userService.rfpUser$.subscribe({
      next: (user: RfpUser) => {
        this.userEmployeeId = user.employeeId;
      }
    });
  }

  ngOnInit(): void { }

  onStartEditing(): void {
    super.onStartEditing();
    this.gridApi.redrawRows();
  }

  onCancelEditing(): void {
    this.clearChanges();
    super.onCancelEditing();
    this.gridApi.redrawRows();
  }

  save(): DataChanges[] {
    const changes = super.save();
    this.gridApi.redrawRows();
    return changes;
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getGridData();
  }

  getGridData(){
    this.gridApi.showLoadingOverlay();
    this.gridLoadingData = true;
    this.rfpApi.getRfpFieldData().subscribe({
      next: resp => {
        this.gridData = resp.items.sort((a, b) =>  
        a.fieldName.localeCompare(b.fieldName));
        this.gridApi.setRowData(this.gridData);
        let selecteNodes: RowNode[] = [];
        this.gridApi.forEachNode((rowNode: RowNode, index: number) => {
          if (rowNode.data.inUse) {
            selecteNodes.push(rowNode)
            rowNode.setSelectedInitialValue(true);
          }
        })
        this.gridApi.redrawRows({ rowNodes: selecteNodes })
      },
    }).add(() => this.gridLoadingData = false)
  }

  isGridReOrdered(): boolean {
    // Retrieve the current order of grid data
    const currentGridData: any[] = [];
    this.gridApi.forEachNode(node => currentGridData.push(node.data));

    // Check if the lengths are different
    if (currentGridData.length !== this.gridData.length) {
      return true;
    }

    // Compare each element in the arrays
    for (let i = 0; i < currentGridData.length; i++) {
      if (currentGridData[i].fieldName !== this.gridData[i].fieldName) {
        return true; // Found a mismatch
      }
    }

    return false; // No mismatches found, data is in the original order
  }

  saveEdits(){
    this.gridLoadingData = true;

    const rowData: RfpFieldData[] = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));

    const request: UpsertRfpFieldDataRqst = {
      fields: rowData
    }
    
    const params: UpsertRfpFieldDataPath = {
      userEmployeeId: this.userEmployeeId
    }

    this.rfpApi.upsertRfpFieldData(request, params).subscribe(
      response => {
        this.snackbar.success(`Successfully updated the changes`)
        this.gridData = rowData
        this.save();
      },
      error => {
        this.snackbar.error(`Failed to update the changes`)
      }
    ).add((teardown: TeardownLogic) => this.gridLoadingData = false)
  }

  editsExist(): boolean {
    return this.reordered || this.dataChanges.length > 0 || (this.gridData?.length != this.gridApi?.getModel()?.getRowCount())
  }

  clearChanges(){
    this.gridApi.setRowData(this.gridData)
    this.reordered = false;
  }
  
  addNewEntry() {
    const rowData: any[] = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
  
    const newFieldName = 'FieldName';
    const newFieldValue = 'FieldValue';
  
    const newEntry = { fieldName: newFieldName, value: newFieldValue }
    // Insert the new entry at the top of the grid
    this.gridApi.applyTransaction({ add: [newEntry], addIndex: 0 });
    this.gridData = [newEntry, ...this.gridData]
  }

  deleteSelected() {
    // Get the selected nodes
    const selectedNodes = this.gridApi.getSelectedNodes();
  
    // Extract the data from the selected nodes
    const rowsToDelete = selectedNodes.map(node => node.data);
  
    // Perform the deletion using applyTransaction
    this.gridApi.applyTransaction({ remove: rowsToDelete });
  }
}