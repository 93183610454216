<div  class="color-key-container" *ngIf="!locked">
  <div><b>Color Key:</b></div>
  <div class="colors">
    <div class="key-value">Negotiating Customer <div class="indicator negotiatingCustomer"></div></div>
    <div class="key-value">Primary Location <div class="indicator primaryLocation"></div></div>
    <div class="key-value">Selected Location <div class="indicator selectedLocation"></div></div>
    <div class="divider-vertical" style="height:24px; margin: 0px"></div>
    <div class="key-value" matTooltip="The number of locations currently selected in the grid"><b>Selected</b>: {{ getSelectedCount() | number:'1.0':'en-US' }}</div>
    <div class="key-value" matTooltip="The number of locations currently saved to the RFP"><b>Saved</b>: {{ getSavedLocationCount() | number:'1.0':'en-US' }}</div>
    <div class="key-value" matTooltip="The total number of locations for this customer"><b>Total</b>: {{ getTotalLocationCount() | number:'1.0':'en-US' }}</div>
  </div>
  <div *ngIf="rfpDetails.isDpt && !hasPnDLocationSelected" class="pnd-container">
    <xpo-icon iconName="error"></xpo-icon> You must select at least one 'Pickup or Delivery' location.
  </div>
</div>

<div *ngIf="rfpDetails.isDpt && !hasPnDLocation">
  <xpo-snack-bar  status="error" [hideClose]="true" message="This customer does not have an active 'Pickup or Delivery' location under their hierarchy. You cannot 'Submit to Dynamic Pricing` until a `Pickup or Delivery` location is added."></xpo-snack-bar>
</div>
  
<xpo-ag-grid>
  <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</xpo-ag-grid>