import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RfpFakOverride } from './fakClassOverride';
import { LookupCodeNameValuePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { LookupService } from 'src/app/_services/lookup.service';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { TabStatus, OnSaveEvent, SaveEvent } from '../../../rfp-edit.component';
import { Observable, of } from 'rxjs';
import { RfpService } from 'src/app/_services/rfp.service';

enum FakFormGroupFields {
  FakClass = "fakClass",
  FromClass = "fromClass",
  ThroughClass = "throughClass"
}

@Component({
  selector: 'rfp-fak-overrides',
  templateUrl: './rfp-fak-overrides.component.html',
  styleUrls: ['./rfp-fak-overrides.component.scss']
})
export class RfpFakOverridesComponent implements OnInit, OnSaveEvent {
  @Input() rfpDetails: RfpDetails;
  @Output() rfpSave = new EventEmitter<SaveEvent>();

  formControls = FakFormGroupFields;
  fakFormArray: UntypedFormArray;

  displayedColumns: string[] = ['originalFak', 'newFak'];
  fakOverrideTableView: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder, 
    public lookup: LookupService,
    private rfpService: RfpService,
  ) { }
  
  ngOnInit(): void {
    this.createFakForm();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createFakForm();
    }
  }

  createFakForm(){
    this.fakFormArray = this.formBuilder.array([], ValidateFakOVerrides.bind(this));
    this.fakFormArray.valueChanges.subscribe(x => this.createFakOverrideTableView())
    this.lookup.fakClasses$.subscribe(faks => {
      if(this.rfpDetails.fakOverrides && this.rfpDetails.fakOverrides.length > 0){
        this.rfpDetails.fakOverrides.forEach((x: RfpFakOverride) => {
          this.addFakOverride(
            faks.find(y => y.code == x.classCode), 
            faks.find(y => y.code == x.fromClassCode), 
            faks.find(y => y.code == x.throughClassCode)
          )
        })
      }
      else {
        this.addFakOverride();
      }
      
      setTimeout(() => {
        this.handleEditability();
      }, 0);
    })
  }

  getArrayControls(): UntypedFormGroup[]{
    return this.fakFormArray.controls as UntypedFormGroup[]
  }

  //FAK ARRAY CONTROLS --------------
  initFakOverride(fakClass?: LookupCodeNameValuePair, fromClass?: LookupCodeNameValuePair, throughClass?: LookupCodeNameValuePair): UntypedFormGroup{
    var newGroup: UntypedFormGroup = this.formBuilder.group({
      [FakFormGroupFields.FakClass]:      [fakClass],
      [FakFormGroupFields.FromClass]:     [fromClass],
      [FakFormGroupFields.ThroughClass]:  [throughClass],
    });
    return newGroup;
  }

  addFakOverride(fakClass?: LookupCodeNameValuePair, fromClass?: LookupCodeNameValuePair, throughClass?: LookupCodeNameValuePair){
    this.fakFormArray.push(this.initFakOverride(fakClass, fromClass, throughClass));
  }

  removeFakOverride(i: number){
    this.fakFormArray.removeAt(i);

  }

  resetFakOverride(i: number){
    this.fakFormArray.at(i).reset()

  }

  getFakArrayFormGroup(i){
    return this.fakFormArray.at(i) as UntypedFormGroup
  }

  createFakOverrideTableView() {
    this.lookup.fakClasses$.subscribe((faks: LookupCodeNameValuePair[]) => {
      this.fakOverrideTableView = faks.map((fak: LookupCodeNameValuePair) => {
        let newFak: LookupCodeNameValuePair[] = this.fakFormArray.controls.filter((form: UntypedFormGroup) =>  fak.value >= form.value[FakFormGroupFields.FromClass]?.value && fak.value <= form.value[FakFormGroupFields.ThroughClass]?.value).map(x => x.value[FakFormGroupFields.FakClass])
        return {
          originalFak: fak.value,
          newFak: newFak.length > 0 ? newFak.map(x => x.value).join(", ") : "-"
        }
  
      })
    })
  }

  getDataSource(minIndex: number, maxIndex: number){
    return this.fakOverrideTableView.slice(minIndex, maxIndex)
  }  

  getTabStatus(): TabStatus {
    return { 
      complete: this.fakFormArray?.enabled ? this.fakFormArray?.valid : true, 
      canSave: this.fakFormArray?.enabled ? this.fakFormArray?.valid : true, 
      unsavedChanges: this.fakFormArray?.value && (this.rfpDetails.fakOverrides.length != this.getValidCount()),
      tabName: "FAK Overrides",
      matBadge: this.rfpDetails.fakOverrides.length
    };
  }

  getValidCount() {
    return this.fakFormArray?.value.filter(x => x[FakFormGroupFields.FakClass] && x[FakFormGroupFields.FromClass] && x[FakFormGroupFields.ThroughClass]).length
  }

  onRfpSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent  = {
      changes: []
    }

    const fakOverrides: RfpFakOverride[] = this.fakFormArray.value.filter(x => x[FakFormGroupFields.FakClass] && x[FakFormGroupFields.FromClass] && x[FakFormGroupFields.ThroughClass]).map((values) => {
      const override: RfpFakOverride = {
        classCode:        values[FakFormGroupFields.FakClass].code,
        fromClassCode:    values[FakFormGroupFields.FromClass].code,
        throughClassCode: values[FakFormGroupFields.ThroughClass].code
      }
      return override
    })

    saveEvent.changes.push({ key: "fakOverrides", value: fakOverrides })

    return of(saveEvent);
  }

  onRfpSaveSuccess(): void { }

  handleEditability(): void {
    this.rfpService.isEditable() ? this.fakFormArray.enable() : this.fakFormArray.disable();
  }
}

function ValidateFakOVerrides(control: UntypedFormArray): {[key: string]: any} | null {
  let overrides: number[] = [];
  let errors: {[key: string]: any}= {}

  this.lookup.fakClasses$.subscribe((fakClassOptions: LookupCodeNameValuePair[]) => {
    control.value.forEach((element) => {
      let override: LookupCodeNameValuePair = element[FakFormGroupFields.FakClass];
      let from: LookupCodeNameValuePair = element[FakFormGroupFields.FromClass];
      let through: LookupCodeNameValuePair = element[FakFormGroupFields.ThroughClass];

      if (override ? !(from || through) : from ? !through : !!through) {
        errors.requireRange = true; // Message: N/A atm
      }
      
      if(from && through){
        if(from.value > through.value){
          errors.rangeIssue = true; // Message: "From Class" must be less than "To Class"
        }
        else {
          overrides.push(...fakClassOptions.map(fakLookup => fakLookup.value).filter(x => (x >= from.value && x <= through.value)))
        }
      }
    });
  })

  if(overrides.length !== new Set(overrides).size){
    errors.multipleClassOverload = true; // Message: Class cannot have multiple overrides
  }
  if(Object.keys(errors).length === 0){
    return null
  } 
  else{
    return errors;
  } 
}