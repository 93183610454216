import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[ngVar]',
})
export class VarDirective implements OnDestroy {
  private context: {
    $implicit: unknown;
    ngVar: unknown;
  } = {
    $implicit: null,
    ngVar: null,
  };

  private hasView: boolean = false;
  private subscription: Subscription | null = null;

  @Input()
  set ngVar(context: unknown | Observable<unknown>) {
    if (context instanceof Observable) {
      this.handleObservable(context);
    } else {
      this.handleValue(context);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private handleValue(context: unknown): void {
    this.context.$implicit = this.context.ngVar = context;

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  private handleObservable(observable: Observable<unknown>): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = observable.subscribe((value) => {
      this.handleValue(value);
    });
  }
}
