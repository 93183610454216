import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { RfpMgmtApiService, RfpMgmtRfpReferenceCode, UpsertRfpReferenceCodeRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { CellEditingStoppedEvent, ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowDragEndEvent, RowDragEnterEvent, RowDragEvent, RowDragLeaveEvent, RowDragMoveEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import { IconButtonCellRendererComponent } from 'src/app/_reusable/cell-renderers/icon-button-cell-renderer/icon-button-cell-renderer.component';
import { GridColumnPartialsHelper } from 'src/app/_reusable/grid-column-partial-helper';
import { NewLookupDialogComponent, NewLookupDialogResponse } from './new-lookup-dialog/new-lookup-dialog.component';
import { GridEditedValue, GridEditedValueEvent } from 'src/app/_models/grid/edited-values';
import { CellEditionErrorCellRendererComponent, DataChanges, XpoInlineEditingBase } from '@xpo-ltl/ngx-ag-grid';
import { TeardownLogic } from 'rxjs';

@Component({
  selector: 'admin-lookups',
  templateUrl: './admin-lookups.component.html',
  styleUrls: ['./admin-lookups.component.scss']
})
export class AdminLookupsComponent extends XpoInlineEditingBase implements OnInit {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  
  selectedIndex: number = -1;
  referenceCodes: RfpMgmtRfpReferenceCode[] = [];
  
  lookupColDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.SelectionWithSelectAll,
    GridColumnPartialsHelper.RowIndex,
    { 
      field: "code",      
      headerName: "Code",
      rowDrag: () => this.isEditing,
      cellRenderer: 'cellEditionError',
      filter: 'agTextColumnFilter', 
      editable: () => this.isEditing, 
      cellClassRules: { 
        'xpo-AgGrid-editableCell': () => this.isEditing, 
        'xpo-AgGrid-editableCell--edited': (params) => params.data && this.isCellEdited(params.data[this.keyField], params.colDef.field), 
        'xpo-AgGrid-editableCell--error': (params) => params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
      }, 
    },
    { 
      field: "name",
      headerName: "Name", 
      cellRenderer: 'cellEditionError',
      filter: 'agTextColumnFilter', 
      editable: () => this.isEditing, 
      cellClassRules: { 
        'xpo-AgGrid-editableCell': () => this.isEditing, 
        'xpo-AgGrid-editableCell--edited': (params) => params.data && this.isCellEdited(params.data[this.keyField], params.colDef.field), 
        'xpo-AgGrid-editableCell--error': (params) => params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
      }, 
    },
  ]

  gridData: any;
  keyField: string = "code";
  gridApi: GridApi;
  gridColumnApi : ColumnApi;
  gridLoadingData: boolean = true;
  isEditing: boolean = false;
  reordered: boolean = false;
  selectedRows: RowNode[] = [];

  gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      filter: true,
      valueSetter: (params) => {
        this.handleValueSetter(params, this.keyField);
        return true;
      },
      tooltipValueGetter: (params) => {
        const colDef = params.colDef as ColDef;
        return params.data && this.getErrorDescription(params, colDef.field);
      },
      cellRendererParams: (params) => {
        return {
          error: params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
        };
      }
    },
    onRowDragEnd: (event: RowDragEndEvent) => {
      this.reordered = this.isGridReOrdered();
    },
    onSelectionChanged: (event: SelectionChangedEvent) => {
      this.selectedRows = event.api.getSelectedNodes()
    },
    rowDragManaged: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    singleClickEdit: true,
    rowSelection: "multiple",
    columnDefs: this.lookupColDefs,
    frameworkComponents: {
      cellEditionError: CellEditionErrorCellRendererComponent,
    },
  } 

  constructor(private rfpApi: RfpMgmtApiService, private snackbar: XpoSnackBar, private dialog: MatDialog) {
    super()
  }

  ngOnInit(): void { }

  onStartEditing(): void {
    super.onStartEditing();
    this.gridApi.redrawRows();
  }

  onCancelEditing(): void {
    this.clearChanges();
    super.onCancelEditing();
    this.gridApi.redrawRows();
  }

  save(): DataChanges[] {
    const changes = super.save();
    this.gridApi.redrawRows();
    return changes;
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getGridData();
  }

  getGridData(){
    this.gridApi.showLoadingOverlay();
    this.gridLoadingData = true;
    this.rfpApi.getAllRfpReferenceCodes().subscribe(references => {
      this.referenceCodes = references.items.sort((a, b) =>  
      a.referenceCode.localeCompare(b.referenceCode));
      setTimeout(() => {
        this.selectedIndex = 0
      }, 0);
    }).add((teardown: TeardownLogic) => this.gridLoadingData = false)
  }

  onTabChanged(event: MatTabChangeEvent) {
    if(event.index >= this.referenceCodes.length) {
      this.openLookupCreateDialog();
    }
    else if(event.index >= 0) {
      this.gridData = this.referenceCodes[this.selectedIndex]?.data
      this.gridApi.setRowData(this.gridData)
    }
    if(this.isEditing) this.onCancelEditing();
  }

  isGridReOrdered(): boolean {
    // Retrieve the current order of grid data
    const currentGridData: any[] = [];
    this.gridApi.forEachNode(node => currentGridData.push(node.data));

    // Check if the lengths are different
    if (currentGridData.length !== this.gridData.length) {
      return true;
    }

    // Compare each element in the arrays
    for (let i = 0; i < currentGridData.length; i++) {
      if (currentGridData[i].code !== this.gridData[i].code) {
        return true; // Found a mismatch
      }
    }

    return false; // No mismatches found, data is in the original order
  }

  saveEdits(){
    this.gridLoadingData = true;
    const referenceCode: string = this.referenceCodes[this.selectedIndex].referenceCode

    const rowData: LookupCodeNamePair[] = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));

    const request: UpsertRfpReferenceCodeRqst = {
      referenceCodes: [
        {
          referenceCode: referenceCode,
          data: rowData
        }
      ]
    }
    
    this.rfpApi.upsertRfpReferenceCode(request).subscribe(
      response => {
        this.snackbar.success(`Successfully updated ${referenceCode}`)
        this.referenceCodes.find(x => x.referenceCode == referenceCode).data = rowData
        this.gridData = rowData
        this.save();
      },
      error => {
        this.snackbar.error(`Failed to update ${referenceCode}`)
      }
    ).add((teardown: TeardownLogic) => this.gridLoadingData = false)
  }

  editsExist(): boolean {
    return this.reordered || this.dataChanges.length > 0 || (this.referenceCodes[this.selectedIndex]?.data?.length != this.gridApi?.getModel()?.getRowCount())
  }

  clearChanges(){
    this.gridData = this.referenceCodes[this.selectedIndex]?.data
    this.gridApi.setRowData(this.gridData)
    this.reordered = false;
  }
  
  addNewEntry() {
    const rowData: any[] = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
  
    const baseCode = 'NewCode';
    const baseName = 'NewName';
  
    // Collect all existing indices
    let existingIndices: number[] = [];
    rowData.forEach(data => {
      if (data.code === baseCode) {
        existingIndices.push(0); // Add index 0 for the base case
      } else if (data.code.startsWith(baseCode)) {
        const match = data.code.match(/\((\d+)\)$/);
        if (match && match[1]) {
          existingIndices.push(parseInt(match[1]));
        }
      }
    });
  
    // Find the smallest missing index
    let newIndex = 0;
    while (existingIndices.includes(newIndex)) {
      newIndex++;
    }
  
    // Generate the new code and name using the smallest missing index
    const newCode = newIndex > 0 ? `${baseCode}(${newIndex})` : baseCode;
    const newName = newIndex > 0 ? `${baseName}(${newIndex})` : baseName;
    const newEntry = { code: newCode, name: newName }
    // Insert the new entry at the top of the grid
    this.gridApi.applyTransaction({ add: [newEntry], addIndex: 0 });
    this.gridData = [newEntry, ...this.gridData]
  }

  deleteSelected() {
    // Get the selected nodes
    const selectedNodes = this.gridApi.getSelectedNodes();
  
    // Extract the data from the selected nodes
    const rowsToDelete = selectedNodes.map(node => node.data);
  
    // Perform the deletion using applyTransaction
    this.gridApi.applyTransaction({ remove: rowsToDelete });
  }

  openLookupCreateDialog(){
    this.dialog.open(NewLookupDialogComponent).afterClosed().subscribe((response: NewLookupDialogResponse) => {
      if(response && response.lookupName){
        this.referenceCodes.push({ referenceCode: response.lookupName, data: []});
        this.selectedIndex = null;
        setTimeout(() => {
          this.selectedIndex = this.referenceCodes.length - 1;
        }, 0);
      }
    })
  }
}