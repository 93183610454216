import { ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'xpo-grid-toolbar-settings-group',
  templateUrl: 'toolbar-settings.component.html',
  styleUrls: ['toolbar-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-grid-toolbar-container-settings-buttons-group',
    'data-cy': 'settings-buttons-group',
  },
})
export class XpoGridToolbarSettings {

}
