import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ApplicationOptions, RfpMgmtApiService, RfpUser, UpdateUserApplicationOptionsRqst, UpsertUserRqst, UserRoleOptions } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';

export interface UserBulkEditDialogInput {
  selectedUsers: RfpUser[],
}

@Component({
  selector: 'user-bulk-edit-dialog',
  templateUrl: './user-bulk-edit-dialog.component.html',
  styleUrls: ['./user-bulk-edit-dialog.component.scss']
})
export class UserBulkEditDialogComponent implements OnInit {
  selectedUsers: RfpUser[];
  userEditForm: UntypedFormGroup;
  currentUserIsAdmin: boolean = false;
  currentUserIsRdsManager: boolean = false;

  flagLabels = {
    isRds: 'RDS',
    isManager: 'Manager',
    manageUserRdsReview: 'Manage User RDS Review',
    isAdmin: 'Administrator',
    enableMabd: 'Enable MABD',
    requireRdsReview: 'Require RDS Review',
    cannotModifyRfp: 'Cannot Modify RFPs'
  };
  userRoleFlags = ['isRds', 'isManager', 'manageUserRdsReview', 'isAdmin'];
  applicationFlags = ['enableMabd', 'requireRdsReview', 'cannotModifyRfp'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserBulkEditDialogInput,
    public dialogRef: MatDialogRef<UserBulkEditDialogComponent, boolean>,
    private fb: UntypedFormBuilder,
    private rfpApi: RfpMgmtApiService,
    private snackbar: XpoSnackBar,
    public userService: UserService,
  ) {
    this.selectedUsers = data.selectedUsers;
    this.userService.rfpUser$.subscribe({
      next: (user: RfpUser) => {
        this.currentUserIsAdmin = user.roleOptions.isAdmin;
        this.currentUserIsRdsManager = user.roleOptions.manageUserRdsReview;
      }
    });
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm(): void {
    this.userEditForm = this.fb.group({
      isRds: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
      isManager: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
      manageUserRdsReview: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
      isAdmin: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
      enableMabd: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
      requireRdsReview: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
      cannotModifyRfp: this.fb.group({
        override: [false],
        value: [{ value: false, disabled: true }]
      }),
    });
  }

  getFormGroup(flagName: string): UntypedFormGroup {
    return this.userEditForm.get(flagName) as UntypedFormGroup
  }

  isVisibleUserOption(flag: string): boolean {
    if (flag === 'manageUserRdsReview' || flag === 'isAdmin') {
      return this.currentUserIsAdmin;
    }
    return true; // Other user options are always visible
  }
  
  isVisibleApplicationOption(flag: string): boolean {
    if (flag === 'requireRdsReview') return this.currentUserIsRdsManager || this.currentUserIsAdmin;
    if(flag === 'cannotModifyRfp') return this.currentUserIsAdmin;
    return true; // Other application options are always visible
  }

  onOverrideChange(flagName: string): void {
    const control = this.userEditForm.get(flagName) as UntypedFormGroup;
    const override = control.get('override').value;
    const valueControl = control.get('value');

    if (override) {
      valueControl.enable();
    } else {
      valueControl.disable();
      valueControl.setValue(false);
    }
  }

  submit() {
    const applicationOptions: Partial<ApplicationOptions> = {}
    const userRoleOptions: Partial<UserRoleOptions> = {}

    // ApplicationOptions Flags
    this.applicationFlags.forEach(flag => {
      const group = this.userEditForm.get(flag) as UntypedFormGroup;
      if (group.get('override').value) {
        applicationOptions[flag] = group.get('value').value;
      }
    });

    // UserRoleOptions Flags
    this.userRoleFlags.forEach(flag => {
      const group = this.userEditForm.get(flag) as UntypedFormGroup;
      if (group.get('override').value) {
        userRoleOptions[flag] = group.get('value').value;
      }
    });

    const request: UpdateUserApplicationOptionsRqst = {
      employeeIds: this.selectedUsers.map(x => x.employeeId),
      applicationOptions: applicationOptions as ApplicationOptions,
      userRoleOptions: userRoleOptions as UserRoleOptions,
      source: "RFP-SALES"
    }

    this.rfpApi.updateUserApplicationOptions(request).subscribe({
      next: (resp) => {
        this.snackbar.success(`Successfully Updated ${this.selectedUsers.length} User Records!`);
        this.dialogRef.close(true);
      }
    })

  }
}