<div class="base-container">
    <xpo-tile-ribbon xpoTileRibbonCompact>
        <xpo-tile *ngFor="let tile of tiles" [tile]="tile" (linkClick)="linkClick($event)"></xpo-tile>
    </xpo-tile-ribbon>
    <div class="flex-grow">
        <xpo-ag-grid>
            <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </xpo-ag-grid>
    </div>
</div>