import { Injectable } from '@angular/core';
import { Accessorial, GetRfpReferenceCodePath, RfpMgmtApiService, RfpMgmtPremiumServiceV2LookupPair } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LookupCodeNameValuePair, LookupCodeNamePair, PremiumServiceLookupPair } from '../_models/temp/lookupCodeValuePair';
import { ApiService } from './api.service';
import { RfpTariff } from '../_models/rfp/rfpTariff';
import { DptAmcOption, DptFakOverrideOption, DptOption, DynamicPricingApiService, ListDptOptionsResp } from '@xpo-ltl/sdk-dynamicpricing';
import { DptOptionCodeDisplay } from '../_models/temp/dptConstants';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  readonly allAccessorials$: Observable<Accessorial[]>;

  readonly threePLTypes$: Observable<LookupCodeNamePair[]>;
  readonly bidReasons$: Observable<LookupCodeNamePair[]>;
  readonly commentTypes$: Observable<LookupCodeNamePair[]>;
  readonly commentSubTypes$: Observable<LookupCodeNamePair[]>;
  readonly discountTypes$: Observable<LookupCodeNamePair[]>;
  readonly documentTypes$: Observable<LookupCodeNamePair[]>;
  readonly fakClasses$: Observable<LookupCodeNameValuePair[]>;
  readonly premiumServices$: Observable<RfpMgmtPremiumServiceV2LookupPair[]>;
  readonly pricingReturnReasons$: Observable<LookupCodeNamePair[]>;
  readonly pricingTypes$: Observable<LookupCodeNamePair[]>;
  readonly rfpStatuses$: Observable<LookupCodeNamePair[]>;
  readonly rfpTypes$: Observable<LookupCodeNamePair[]>;
  readonly rfpTariffs$: Observable<RfpTariff[]>
  readonly salesPrograms$: Observable<LookupCodeNamePair[]>;
  readonly salesReturnReasons$: Observable<LookupCodeNamePair[]>;
  readonly subscriptionOptions$: Observable<LookupCodeNamePair[]>;

  readonly dptAccessorialTypes$: Observable<DptOption[]>;
  readonly dptAmcTypes$: Observable<DptAmcOption[]>;
  readonly dptDiscountTypes$: Observable<DptOption[]>;
  readonly dptFakOverrideTypes$: Observable<DptFakOverrideOption[]>;
  readonly dptRateTariffTypes$: Observable<DptOptionCodeDisplay[]>;
  readonly salesRegions$: Observable<LookupCodeNamePair[]>;
  readonly premiumServiceTypes$: Observable<LookupCodeNamePair[]>;

  constructor(private rfpApi: RfpMgmtApiService, private athenaApi: ApiService, private dynamicPricingApi: DynamicPricingApiService) {
    const premiumServiceTypePath: GetRfpReferenceCodePath = {
      referenceCode: "PREMIUMSERVICEV2"
    }

    this.allAccessorials$ = this.rfpApi.getAccessorials().pipe(map(response => response.items), shareReplay())
  
    this.threePLTypes$ =          this.rfpApi.get3PLTypes().pipe(map(response => response.items), shareReplay())
    this.bidReasons$ =            this.rfpApi.getBidReasons().pipe(map(response => response.items), shareReplay())
    this.commentTypes$ =          this.rfpApi.getCommentTypes().pipe(map(response => response.items), shareReplay())
    this.commentSubTypes$ =       this.rfpApi.getCommentSubTypes().pipe(map(response => response.items), shareReplay())
    this.discountTypes$ =         this.rfpApi.getDiscountTypes().pipe(map(response => response.items), shareReplay())
    this.documentTypes$ =         this.rfpApi.getDocumentTypes().pipe(map(response => response.items), shareReplay())
    this.fakClasses$ =            this.rfpApi.getFakClasses().pipe(map(response => response.items), shareReplay())
    this.premiumServices$ =       this.rfpApi.getPremiumServicesV2(null).pipe(map(response => response.items), shareReplay())
    this.pricingReturnReasons$ =  this.rfpApi.getPricingReturnReasons().pipe(map(response => response.items), shareReplay())
    this.pricingTypes$ =          this.rfpApi.getPricingTypes().pipe(map(response => response.items), shareReplay())
    this.rfpStatuses$ =           this.rfpApi.getRfpStatuses().pipe(map(response => response.items), shareReplay())
    this.rfpTariffs$ =            this.rfpApi.listSMC3Tariffs().pipe(map(response => response.items), shareReplay())
    this.rfpTypes$ =              this.rfpApi.getRfpTypes().pipe(map(response => response.items), shareReplay())
    this.salesPrograms$ =         this.rfpApi.getSalesPrograms(null).pipe(map(response => response.items), shareReplay())
    this.salesReturnReasons$ =    this.rfpApi.getSalesReturnReasons().pipe(map(response => response.items), shareReplay())
    this.subscriptionOptions$ =   this.rfpApi.getEmailSubscriptions().pipe(map(response => response.items), shareReplay())
    this.salesRegions$ =          this.rfpApi.getSalesRegions().pipe(map(response => response.items), shareReplay())
    this.premiumServiceTypes$ =   this.rfpApi.getRfpReferenceCode(premiumServiceTypePath).pipe(map(response => response.data), shareReplay())
    
    const dptOptions$: Observable<ListDptOptionsResp> = this.dynamicPricingApi.listDptOptions().pipe(shareReplay())
    this.dptAccessorialTypes$ = dptOptions$.pipe(map(allOptions => allOptions.accessorials.sort((a, b) => (a.code < b.code ? -1 : 1))), shareReplay())
    this.dptAmcTypes$ = dptOptions$.pipe(map(allOptions => allOptions.amcs), shareReplay())
    this.dptDiscountTypes$ = dptOptions$.pipe(map(allOptions => allOptions.discounts), shareReplay())
    this.dptFakOverrideTypes$ = dptOptions$.pipe(map(allOptions => allOptions.fakOverrideOptions), shareReplay())
    this.dptRateTariffTypes$ = dptOptions$.pipe(map(allOptions => allOptions.tariffs), shareReplay())
  }

  /**
  * Inserts the given value at the start of the given source array.
  */
  insertIntoObservableArray<T>(source$: Observable<T[]>, itemToInsert: T): Observable<T[]> {
    return source$.pipe(map(array => [itemToInsert, ...array]));
  }
}