<ng-container *ngIf="finishedFetchingData; else loadingRfpData">
    <ng-container *ngIf="rfpDetails; else rfpNotFound">
        <ng-container *ngIf="accountOverview; else accountNotFound">
            <ng-container *ngIf="!isCancelPricing(); else cancelPricing">
                <ng-container *ngIf="!rfpDetails.isDpt; else dynamicRfpEdit">
                    <rfp-contracted [rfpDetails]="rfpDetails" [accountOverview]="accountOverview" [rfpContact]="rfpContact" [rfpReviews]="rfpReviews" (refreshRfpDetails)="getRfpDetails($event)"></rfp-contracted>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loadingRfpData>
    <div class="center-align-icon">
        <div style="width:100px;">
            <xpo-busy-loader [slow]="true" matSuffix></xpo-busy-loader>
        </div>
        <h1>Loading RFP Data...</h1>
    </div>
</ng-template>

<ng-template #rfpNotFound>
    <div class="center-align-icon">
        <xpo-empty-state>
            <xpo-empty-state-icon>
                <mat-icon>search</mat-icon>
            </xpo-empty-state-icon>
            <xpo-empty-state-header>Cannot Find RFP ID {{ salesRfpFromPath }}</xpo-empty-state-header>
            <xpo-empty-state-description>Please make sure you have the right RFP ID</xpo-empty-state-description>
        </xpo-empty-state>
    </div>
</ng-template>

<ng-template #accountNotFound>
    <div class="center-align-icon">
        <xpo-empty-state>
            <xpo-empty-state-icon>
                <mat-icon>search</mat-icon>
            </xpo-empty-state-icon>
            <xpo-empty-state-header>Cannot Find Customer MAD Code {{ rfpDetails.madCode }}</xpo-empty-state-header>
            <xpo-empty-state-description>Please make sure you have the right customer MAD Code</xpo-empty-state-description>
        </xpo-empty-state>
    </div>
</ng-template>


<ng-template #tabInvalid>
    <xpo-icon iconName="priority-high" class="tab-error"></xpo-icon>
</ng-template>

<ng-template #tabUnsavedChanges>
    <xpo-icon iconName="save" class="tab-normal"></xpo-icon>
</ng-template>

<ng-template #dynamicRfpEdit>
    <rfp-dynamic [rfpDetails]="rfpDetails"  [accountOverview]="accountOverview" [rfpContact]="rfpContact" (refreshRfpDetails)="getRfpDetails($event)"></rfp-dynamic>
</ng-template>

<ng-template #cancelPricing>
    <rfp-cancel-pricing [rfpDetails]="rfpDetails"  [accountOverview]="accountOverview" [rfpContact]="rfpContact" (refreshRfpDetails)="getRfpDetails($event)"></rfp-cancel-pricing>
</ng-template>