import { Component } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigSettings } from 'src/app/_models/configSettings';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'rfp-root',
  templateUrl: './rfp-root.component.html',
  styleUrls: ['./rfp-root.component.scss']
})
export class RfpRootComponent {
  appVersion = "local development"

  constructor(
    private configManagerService: ConfigManagerService,
    public userService: UserService
  ) {
    this.appVersion = this.configManagerService.getSetting<string>(ConfigSettings.BuildVersion)
  }

  canSeeManagerDashboard(): Observable<boolean> {
    return this.userService.isUserManager();
  }

  canSeeAdminDashboard(): Observable<boolean> {
    return this.userService.isUserAdmin()
  }
}
