import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

export interface ShipmentSummaryCellRendererParams extends ICellRendererParams {
  onClickCallback: Function,
  iconName: string;
  tooltip: string;
}


@Component({
  selector: 'shipment-summary-cell-renderer',
  templateUrl: './shipment-summary-cell-renderer.component.html',
  styleUrls: ['./shipment-summary-cell-renderer.component.scss']
})
export class ShipmentSummaryCellRendererComponent {
  params!: ShipmentSummaryCellRendererParams;
  showShipmentSummary: boolean = false;

  agInit(params: ShipmentSummaryCellRendererParams): void {
    this.params = params;
    this.showShipmentSummary = this.params?.data?.isActive;
  }

  onClick($event: MouseEvent) {
    if (this.params.onClickCallback instanceof Function) {
      this.params.onClickCallback(this.params.node);
    }
  }
}