import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, of } from 'rxjs';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LookupService } from 'src/app/_services/lookup.service';
import { PremiumServiceLookupPair } from 'src/app/_models/temp/lookupCodeValuePair';
import { takeUntil } from 'rxjs/operators';
import { PremiumServiceCodes, PremiumServicesService, PremiumServicesStatus } from '../rfp-premium-services.service';
import { OnSaveEvent, SaveEvent, TabStatus } from 'src/app/screens/rfp-edit/rfp-edit.component';
import { RfpService } from 'src/app/_services/rfp.service';

const AccessorialWaived = {
  MBS: ["DNC", "APT"],
  MBD: ["DNC", "APT", "GUR"],
}

const AccessorialsDisabled = {
  MBS: [],
  MBD: [],
  GLOBAL: ["TDC"]
}

export enum MabdFormFields {
  MustArriveBySelected = "mustArriveBySelected",
  MustArriveByFormArray = "mustArriveByForm",
  MustArriveByTier = "mustArriveByTier",
  MustArriveByAmount = "mustArriveByAmount",
  UnitOfMeasure = "unitOfMeasure",
}

@Component({
  selector: 'rfp-mabd',
  templateUrl: './rfp-mabd.component.html',
  styleUrls: ['./rfp-mabd.component.scss']
})
export class RfpMabdComponent implements OnInit, OnDestroy, OnSaveEvent {
  @Input() rfpDetails: RfpDetails;
  @Input() rfpPremiumServiceForm: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  mabdFormFields = MabdFormFields;
  mabdFormArray: UntypedFormArray;

  questionnaire: any;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public lookup: LookupService,
    private premiumService: PremiumServicesService,
    private rfpService: RfpService,
  ) { }
  rfpSave: EventEmitter<SaveEvent>;


  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createForm();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasMABD(): boolean {
    return this.rfpDetails.premiumServiceTypeCodes.includes(PremiumServiceCodes.MABD)
  }

  getUomOptions(index: number) {
    const selectedTier = this.mustArriveByArray().at(index).get(MabdFormFields.MustArriveByTier)?.value;

    if (selectedTier) {
      return selectedTier.unitOfMeasures;
    }

    return [];
  }

  createForm() {
    this.mabdFormArray = this.formBuilder.array([], )
    this.mabdFormArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(newValue => {
      this.handleMustArriveByChanged()
    })

    this.lookup.premiumServices$.pipe(takeUntil(this.destroy$)).subscribe((premiumServices) => {
      premiumServices.find(x => x.premiumServiceTypeCode == PremiumServiceCodes.MABD).accessorials.forEach(service => {
        const currentValue = this.rfpDetails.accessorials.find(x => x.accessorialCode === service.code)
        this.addTier(service, currentValue?.amount, currentValue?.unitOfMeasure)
      })

      this.rfpPremiumServiceForm.setControl(MabdFormFields.MustArriveByFormArray, this.mabdFormArray)
      this.handleEditability();
    })

    this.questionnaire = this.rfpDetails.premiumServiceTypeCodeJson?.find(x => x.premiumServiceType == PremiumServiceCodes.MABD)?.questions
  }

  requireOneSelected(): ValidatorFn {
    return (formArray: UntypedFormArray): ValidationErrors | null => {
      // Check if at least one group has the MustArriveBySelected field set to true
      const isAnyTrue = formArray.controls.some(
        control => control.value[MabdFormFields.MustArriveBySelected] === true
      );

      // If no group meets the condition, return an error object
      if (!isAnyTrue) {
        return { requireAtLeastOneTrue: true };
      }

      // If there's at least one, return null (no error)
      return null;
    };
  }

  mustArriveByArray(): UntypedFormArray {
    return this.mabdFormArray;
  }

  mustArriveByControls(): UntypedFormGroup[] {
    return this.mabdFormArray.controls as UntypedFormGroup[] ?? [];
  }

  addTier(tier: PremiumServiceLookupPair, amount?: number, uom?: string) {
    const newGroup = this.formBuilder.group({
      [MabdFormFields.MustArriveByTier]: [tier, Validators.required],
      [MabdFormFields.MustArriveBySelected]: [false, Validators.required],
      [MabdFormFields.MustArriveByAmount]: [null, Validators.required],
      [MabdFormFields.UnitOfMeasure]: [tier.defaultUnitOfMeasure, Validators.required],
    })

    newGroup.get(MabdFormFields.MustArriveBySelected).valueChanges.subscribe({
      next: (checked: boolean) => {
        this.updateInputEnabled(newGroup) 
      }
    });

    // if (tier.code === "MBD") {
    //   newGroup.get(MabdFormFields.MustArriveBySelected).valueChanges.subscribe(selected => {
    //     this.toggleMbsSelectedBasedOnMbd(selected);
    //   });

    //   // Initial call to ensure proper state from the beginning
    //   this.toggleMbsSelectedBasedOnMbd(newGroup.get(MabdFormFields.MustArriveBySelected).value);
    // }

    newGroup.patchValue({
      [MabdFormFields.MustArriveBySelected]: amount != null,
      [MabdFormFields.MustArriveByAmount]: amount ?? null,
      [MabdFormFields.UnitOfMeasure]: uom ?? tier.defaultUnitOfMeasure,
    })

    this.mustArriveByArray().push(newGroup);
  }

  lockMbs(){
    const mbsGroup = this.mustArriveByArray().controls.find(
      group => group.get(MabdFormFields.MustArriveByTier).value.code === "MBS"
    );

    if (mbsGroup) {
      mbsGroup.get(MabdFormFields.MustArriveBySelected).setValue(true);
      mbsGroup.get(MabdFormFields.MustArriveBySelected).disable();
    }
  }

  toggleMbsSelectedBasedOnMbd(isMbdSelected: boolean) {
    const mbsGroup = this.mustArriveByArray().controls.find(
      group => group.get(MabdFormFields.MustArriveByTier).value.code === "MBS"
    );

    if (!mbsGroup) return; // "MBS" not found or not yet added

    if (isMbdSelected && this.rfpService.isEditable() && this.hasMABD()) {
      mbsGroup.get(MabdFormFields.MustArriveBySelected).setValue(true);
      mbsGroup.disable();
    } else if (this.rfpService.isEditable() && this.hasMABD()) {
      // if (this.rfpService.isEditable()) mbsGroup.get(RfpPremiumServiceFormFields.MustArriveBySelected).setValue(false);
      mbsGroup.enable({ emitEvent: false });
    }
  }

  updateInputEnabled(group: UntypedFormGroup) {
    const checked = group.get(MabdFormFields.MustArriveBySelected).value
    const amountControl = group.get(MabdFormFields.MustArriveByAmount)
    const amountUom = group.get(MabdFormFields.UnitOfMeasure)

    if (group.enabled && checked && this.rfpService.isEditable() && this.hasMABD()) {
      amountControl.enable({ emitEvent: false });
      amountUom.enable({ emitEvent: false });
    }
    else {
      amountControl.disable({ emitEvent: false });
      amountUom.disable({ emitEvent: false });
    }
  }

  removeTier(index: number) {
    this.mustArriveByArray().removeAt(index);
  }


  handleMustArriveByChanged() {
    let status: PremiumServicesStatus = {
      hasMustArriveByDate: false,
      mustArriveByCodes: [],
      waivedAccessorials: [],
      disabledAccessorials: []
    }

    this.mustArriveByControls().forEach((group: UntypedFormGroup) => {
      const values = group.getRawValue();
      const tier: PremiumServiceLookupPair = values[MabdFormFields.MustArriveByTier]

      if (values[MabdFormFields.MustArriveBySelected]) {
        status.hasMustArriveByDate = true;
        status.mustArriveByCodes.push(tier.code)
        status.waivedAccessorials = Array.from(new Set([...status.waivedAccessorials, ...AccessorialWaived[tier.code]])); // Get Distinct Values
        status.disabledAccessorials = Array.from(new Set([...status.disabledAccessorials, ...AccessorialsDisabled[tier.code], ...AccessorialsDisabled.GLOBAL])); // Get Distinct Values
      }

      this.updateInputEnabled(group)
    })

    this.premiumService.updatePremiumServicesStatus(status as PremiumServicesStatus)
  }

  onRfpSave() {
    let saveEvent: SaveEvent = {
      changes: []
    }

    if (this.rfpDetails.premiumServiceTypeCodes.includes(PremiumServiceCodes.MABD) && this.mabdFormArray.enabled) {
      this.mustArriveByControls().forEach((group: UntypedFormGroup) => {
        if (group.enabled) {
          const values = group.getRawValue();
          const tier: PremiumServiceLookupPair = values[MabdFormFields.MustArriveByTier]

          if (values[MabdFormFields.MustArriveBySelected]) {
            const amount: number = Number.parseFloat(values[MabdFormFields.MustArriveByAmount]);
            const uom: string = values[MabdFormFields.UnitOfMeasure]

            const mustArriveByDate = {
              accessorialCode: tier.code,
              accessorialName: tier.name,
              amount: amount,
              isWaived: amount == 0,
              unitOfMeasure: uom ?? "Shipment"
            }

            saveEvent.changes.push({ key: "accessorials", value: [mustArriveByDate] })
          }
        }
      })
    }

    return of(saveEvent);
  }

  getTabStatus(): TabStatus { return null; }
  onRfpSaveSuccess(): void { }

  handleEditability(): void {
    if(this.rfpService.isEditable() && this.hasMABD()) {
      this.mabdFormArray.enable();
      this.lockMbs();
    }
    else {
      this.mabdFormArray.disable();
    }
    
    this.handleMustArriveByChanged();
  }
}
