<div class="actionBtnContainer">
  <button
    mat-button
    xpoDownloadButton
    [handleDownload]="downloadFile.bind(this)"
    [fileName]='getFileName()'
    [matTooltip]="params.tooltip"
    >
  </button>
</div>






