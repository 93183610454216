import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, of } from 'rxjs';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LookupService } from 'src/app/_services/lookup.service';
import { PremiumServiceLookupPair } from 'src/app/_models/temp/lookupCodeValuePair';
import { takeUntil } from 'rxjs/operators';
import { PremiumServiceCodes, PremiumServicesService } from '../rfp-premium-services.service';
import { OnSaveEvent, SaveEvent, TabStatus } from 'src/app/screens/rfp-edit/rfp-edit.component';
import { RfpMgmtApiService, RfpMgmtSmc3Tariff } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpTariff } from 'src/app/_models/rfp/rfpTariff';
import { RfpService } from 'src/app/_services/rfp.service';

export enum MexicoFormFields {
  MexicoForm = "mexicoForm",
  Tariff = "rateTariffMexico",
  DiscountAmount = "mexicoDiscountAmt",
  Accessorials = "accessorials"
}

export enum MexicoAccessorialFormFields {
  Accessorial = "accessorial",
  Selected = "selected",
  Amount = "amount",
  UnitOfMeasure = "uom"
}

@Component({
  selector: 'rfp-mexico',
  templateUrl: './rfp-mexico.component.html',
  styleUrls: ['./rfp-mexico.component.scss']
})
export class RfpMexicoComponent implements OnInit, OnDestroy, OnSaveEvent {
  @Input() rfpDetails: RfpDetails;
  @Input() rfpPremiumServiceForm: UntypedFormGroup;
  rfpSave: EventEmitter<SaveEvent>;
  private destroy$ = new Subject<void>();
  questionnaire: any;

  mexicoTariffs: RfpTariff[];

  standardRates: { [accessorial: string]: number }

  mexicoForm: UntypedFormGroup;
  mexicoFormFields = MexicoFormFields;
  mexicoAccessorialFormFields = MexicoAccessorialFormFields;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public lookup: LookupService,
    private premiumService: PremiumServicesService,
    private rfpApi: RfpMgmtApiService,
    private rfpService: RfpService,
  ) { }

  ngOnInit(): void {
    this.rfpApi.listMexicoTariffs().subscribe(x => {
      this.mexicoTariffs = x.items;
      this.mexicoForm.get(MexicoFormFields.Tariff).patchValue(this.getRateTariff(this.rfpDetails.rateTariffMexico?.tariffCode, this.rfpDetails.rateTariffMexico?.effectiveDate) ?? this.mexicoTariffs[0])
    })

    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createForm();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasMexico(): boolean {
    return this.rfpDetails.premiumServiceTypeCodes.includes(PremiumServiceCodes.Mexico)
  }

  createForm() {
    this.mexicoForm = this.formBuilder.group({
      [MexicoFormFields.Tariff]: [this.getRateTariff(this.rfpDetails.rateTariffMexico?.tariffCode, this.rfpDetails.rateTariffMexico?.effectiveDate), Validators.required],
      [MexicoFormFields.DiscountAmount]: [this.rfpDetails.mexicoDiscountAmt * 100, [Validators.required, Validators.min(0), Validators.max(100)]],
      [MexicoFormFields.Accessorials]: this.formBuilder.array([])
    })

    this.standardRates = {};
    this.lookup.premiumServices$.pipe(takeUntil(this.destroy$)).subscribe((premiumServices) => {
      premiumServices.find(x => x.premiumServiceTypeCode == PremiumServiceCodes.Mexico).accessorials.forEach(service => {
        const currentValue = this.rfpDetails.accessorials.find(x => x.accessorialCode === service.code)
        this.addAccessorial(service, currentValue != null, currentValue?.amount, currentValue?.unitOfMeasure)

        this.standardRates[service.code] = service.value;
      })
      this.rfpPremiumServiceForm.setControl(MexicoFormFields.MexicoForm, this.mexicoForm)
      this.handleEditability();
    })

    this.questionnaire = this.rfpDetails.premiumServiceTypeCodeJson?.find(x => x.premiumServiceType == PremiumServiceCodes.Mexico)?.questions
  }


  addAccessorial(accessorial: PremiumServiceLookupPair, selected?: boolean, amount?: number, uom?: string): void {
    const newGroup = this.formBuilder.group({
      [MexicoAccessorialFormFields.Accessorial]: [accessorial],
      [MexicoAccessorialFormFields.Selected]: [false],
      [MexicoAccessorialFormFields.Amount]: [null, [Validators.required, Validators.min(0)]],
      [MexicoAccessorialFormFields.UnitOfMeasure]: [accessorial.defaultUnitOfMeasure, Validators.required],

    })

    newGroup.get(MexicoAccessorialFormFields.Selected).valueChanges.subscribe({
      next: (checked: boolean) => {
        this.updateInputEnabled(newGroup)
      }
    });

    newGroup.patchValue({
      [MexicoAccessorialFormFields.Selected]: amount ?? null,
      [MexicoAccessorialFormFields.Amount]: amount ?? null,
      [MexicoAccessorialFormFields.UnitOfMeasure]: uom ?? accessorial.defaultUnitOfMeasure,
    })

    this.accessorialFormArray().push(newGroup);
  }

  accessorialFormArray(): UntypedFormArray {
    return this.mexicoForm.get(MexicoFormFields.Accessorials) as UntypedFormArray;
  }
  accessorialFormArrayControls(): UntypedFormGroup[] {
    return this.accessorialFormArray().controls as UntypedFormGroup[];
  }

  updateInputEnabled(group: UntypedFormGroup) {
    const checked = group.get(MexicoAccessorialFormFields.Selected).value
    const amountControl = group.get(MexicoAccessorialFormFields.Amount)

    if (group.enabled && checked && this.rfpService.isEditable() && this.hasMexico()) {
      amountControl.enable({ emitEvent: false });
    }
    else {
      amountControl.disable({ emitEvent: false });
    }
  }

  getRateTariff(tariffCode: string, date: string): RfpTariff {
    const selectedRateTariff = this.mexicoTariffs?.find(x => x.tariffCode == tariffCode && new Date(x.effectiveDate).getTime() === new Date(date).getTime()) ?? null;
    return selectedRateTariff;
  }

  onRfpSave() {
    let saveEvent: SaveEvent = {
      changes: []
    }

    if (this.rfpDetails.premiumServiceTypeCodes.includes(PremiumServiceCodes.Mexico) && this.mexicoForm.enabled) {

      saveEvent.changes.push({
        key: MexicoFormFields.Tariff,
        value: this.mexicoForm.get(MexicoFormFields.Tariff).value
      });

      saveEvent.changes.push({
        key: MexicoFormFields.DiscountAmount,
        value: this.mexicoForm.get(MexicoFormFields.DiscountAmount).value / 100
      })

      this.accessorialFormArrayControls().map(x => x.getRawValue()).forEach(value => {
        const tier: PremiumServiceLookupPair = value[MexicoAccessorialFormFields.Accessorial];

        if (value[MexicoAccessorialFormFields.Selected]) {
          const amount: number = Number.parseFloat(value[MexicoAccessorialFormFields.Amount]);
          const uom: string = value[MexicoAccessorialFormFields.UnitOfMeasure]

          const accessorial = {
            accessorialCode: tier.code,
            accessorialName: tier.name,
            amount: amount,
            isWaived: amount == 0,
            unitOfMeasure: uom ?? "Shipment"
          }

          saveEvent.changes.push({ key: "accessorials", value: [accessorial] })
        }
      })

    }
    return of(saveEvent);
  }

  getTabStatus(): TabStatus { return null; }
  onRfpSaveSuccess(): void { }

  handleEditability(): void {
    if (this.rfpService.isEditable() && this.hasMexico()) {
      this.mexicoForm.enable()
      this.accessorialFormArrayControls().forEach(x => this.updateInputEnabled(x))
    }
    else {
      this.mexicoForm.disable();
    }

  }
}