import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { DatePipe, Location } from '@angular/common';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { createHttpClient } from './http-client.factory';
import { LtlResponseEnvelope } from '../_models/LtlResponseEnvelope';
import { DefaultAccessorial } from '../_models/defaultAccessorial.ts';
import { RfpAnalystDetailRequest } from '../_models/rfp/rfpAnalystDetailRequest';
import { RfpAnalystDetail } from '../_models/rfp/rfpAnalystDetail';
import { RfpAccount } from '../_models/rfp/rfpAccountResponse';
import { RfpAnalystSummary } from '../_models/rfp/rfpAnalystSummary';
import { RfpAnalystSummaryRequest } from '../_models/rfp/rfpAnalystSummaryRequest';
import { RfpUpdateAssignmentRequest } from '../_models/rfp/rfpUpdateAssignmentRequest';
import { RfpAnalystWorkQueueRequest } from '../_models/rfp/rfpAnalystWorkQueueRequest';
import { RfpAnalystWorkQueueResponse } from '../_models/rfp/rfpAnalystWorkQueueResponse';
import { Customer } from '../_models/customers/customer';
import { PwbChannel } from '../_models/lookups/pwbChannel';
import { AccountType } from '../_models/lookups/accountType';
import { RfpStatus } from '../_models/rfp/rfpStatus';
import { PricingAgreementsResponse } from '../_models/pricing/pricingAgreementsResponse';
import { AccessorialDetails } from '../_models/accessorialDetail';
import { Proposal } from '../_models/proposal/proposal';



@Injectable({
  providedIn: 'root'
})
export class ApiService{
//   private baseApiUrl;
//   currentFile: File;
//   datePipe: DatePipe;
//   private http: HttpClient;

//   constructor(
//     private configManagerService: ConfigManagerService,
//     private injector: Injector,
//     private location: Location
//   ) {
//     // This overwrites the intercepror chain for the http client
//     // "AuthInterceptor" is the LTL interceptor that we don't want to use
//     this.http = createHttpClient(this.injector, ["AuthInterceptor"]);
//     var xeaApiEnvironment = this.configManagerService.getSetting<boolean>("xeaApiUseLocal") ? "xeaApiUrlLocal" : "xeaApiUrl"
//     this.baseApiUrl =  location.normalize(this.configManagerService.getSetting<string>(xeaApiEnvironment));
//   }


//   unwrapLtlEnvelope(response: Observable<LtlResponseEnvelope<any>>){
//     return response.pipe(map(response => { return response?.data }) );
//   }

// //------------------------------------------- ACCESSORIALS  -------------------------------------------
//   getDefault199Charges(): Observable<DefaultAccessorial[]>{
//     let url = new URL(`${this.baseApiUrl}/accessorials/default-199-charges`);

//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<DefaultAccessorial[]>>(url.toString()));
//   }
  


// //------------------------------------------- CUSTOMERS  -------------------------------------------

//   searchCustomer(search: string): Observable<Customer[]>{ //CustomerSearchRecord
//     if(search){
//       // const searchRequest = {
//       //   filter:  {
//       //     acctMadCd: {
//       //       startsWith: search
//       //     }
//       //   }
//       // }
//       //return this.searchSdk.xrtCustomerSearch(<any>searchRequest).pipe(map(response => { return response.result }) );

//       let url = new URL(`${this.baseApiUrl}/customers/search?q=${search}`);
//       return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<Customer[]>>(url.toString()));
//     }
//     return new Observable<Customer[]>();
//     // return new Observable<CustomerSearchRecord[]>();
//   }

//   getCustomer(madCode: string){
//     let url = new URL(`${this.baseApiUrl}/customers?madCode=${madCode}`);
//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<Customer>>(url.toString()));
//   }

// //------------------------------------------- EPA  -------------------------------------------

//   epaGetAccessorialDetails(proposalId: number): Observable<AccessorialDetails> {
//     let url = new URL(`${this.baseApiUrl}/economic-pricing-proposal/accessorial-details`);
  
//     let queryData = {
//       proposalId: proposalId
//     }
//     return this.unwrapLtlEnvelope(this.http.post<LtlResponseEnvelope<AccessorialDetails>>(url.toString(), queryData));
//   }



//   //------------------------------------------- Lookups  -------------------------------------------
//   getLookupsChannel(): Observable<PwbChannel[]>{
//     let url = new URL(`${this.baseApiUrl}/lookups/channel`);

//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<PwbChannel[]>>(url.toString()));
//   }

//   getLookupsAccountType(): Observable<AccountType[]>{
//     let url = new URL(`${this.baseApiUrl}/lookups/account-types`);

//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<AccountType[]>>(url.toString()));
//   }

//   getLookupsRfpStatuses(): Observable<RfpStatus[]>{
//     let url = new URL(`${this.baseApiUrl}/lookups/rfp-statuses`);

//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<RfpStatus[]>>(url.toString()));
//   }

//   //------------------------------------------- Pricing  -------------------------------------------

//   getPricingAgreements(customerMadCode: string): Observable<PricingAgreementsResponse>{
//     let url = new URL(`${this.baseApiUrl}/pricing/agreements/${customerMadCode}`);

//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<PricingAgreementsResponse>>(url.toString()))
//   }

//   //------------------------------------------- Proposal  -------------------------------------------

//   listProposals(customerMadCode: string):Observable<Proposal[]>{
//     let url = new URL(`${this.baseApiUrl}/proposals/list/${customerMadCode}`);
  
//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<Proposal[]>>(url.toString()))
//   }

//   //-------------------------------------------  RFP  -------------------------------------------

//   getRfpsForAnalyst(query: RfpAnalystDetailRequest): Observable<RfpAnalystDetail[]> {
//     let url = new URL(`${this.baseApiUrl}/rfps/analysts/list`);
//     return this.unwrapLtlEnvelope(this.http.post<LtlResponseEnvelope<RfpAnalystDetail[]>>(url.toString(), query));
//   }

//   getRfpsForAccount(madCode: string): Observable<RfpAccount[]> {
//     let url = new URL(`${this.baseApiUrl}/rfps/account/${madCode}`);
//     return this.unwrapLtlEnvelope(this.http.get<LtlResponseEnvelope<RfpAccount[]>>(url.toString()));
//   }

//   getManagerAnalystSummary(query: RfpAnalystSummaryRequest): Observable<RfpAnalystSummary[]> {
//     let url = new URL(`${this.baseApiUrl}/rfps/managers/analysts-summary`);
//     return this.unwrapLtlEnvelope(this.http.post<LtlResponseEnvelope<RfpAnalystSummary[]>>(url.toString(), query));
//   }

//   updateRfpAssignment(query: RfpUpdateAssignmentRequest): Observable<void> {
//     let url = new URL(`${this.baseApiUrl}/rfps/assignments/update`);
//     return this.unwrapLtlEnvelope(this.http.post<LtlResponseEnvelope<void>>(url.toString(), query));
//   }

//   getAnalystWorkQueue(query: RfpAnalystWorkQueueRequest): Observable<RfpAnalystWorkQueueResponse[]> {
//     let url = new URL(`${this.baseApiUrl}/rfps/analysts/work-queue`);
//     return this.unwrapLtlEnvelope(this.http.post<LtlResponseEnvelope<RfpAnalystWorkQueueResponse[]>>(url.toString(), query));
//   }
}