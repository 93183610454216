import { Component, Inject, OnInit } from '@angular/core';
import { XPO_SNACKBAR_DETAILED_MESSAGE_DATA } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Component({
  selector: 'snack-bar-error-list',
  templateUrl: './snack-bar-error-list.component.html',
  styleUrls: ['./snack-bar-error-list.component.scss']
})
export class SnackBarErrorListComponent implements OnInit {

  constructor(@Inject(XPO_SNACKBAR_DETAILED_MESSAGE_DATA) public errors: string[]) {}

  ngOnInit(): void {
  }

}
