<div class="message-container">
    <div class="message" [ngClass]="{'current-user-message': message.isFromCurrentUser}">

        <img *ngIf="!message.isFromCurrentUser" class="profile-picture" [src]="profilePictureUrl">

        <div class="info" [ngClass]="{'info-current-user': message.isFromCurrentUser}">
            <div class="header" [ngClass]="{'header-current-user': message.isFromCurrentUser}" >
                <span [matTooltip]="formatTimestamp(message.createdOn)" class="author-name"> {{ message.authorFullName }}</span>
                <span>-</span>
                <span> {{ utilityService.getLookupValue(lookup.commentTypes$ | async, message.commentTypeCode) }} </span>
                <span>-</span>
                <span> {{ utilityService.getLookupValue(lookup.commentSubTypes$ | async, message.commentSubTypeCode) }} </span>
                <span>-</span>
                <span> {{ formatTimestamp(message.createdOn) }} </span>
                <span *ngIf="message.lastUpdatedOn != message.createdOn" [matTooltip]="formatTimestamp(message.lastUpdatedOn)">- (Edited) </span>
                <!-- <span *ngIf="message.isFromCurrentUser" class="header-edit">
                    <xpo-icon xpoIconSmall iconName="more-vert" [matMenuTriggerFor]="messageEditMenu" matTooltip="More"></xpo-icon>
                    <mat-menu #messageEditMenu="matMenu">
                        <button mat-menu-item (click)="editMessage()"><xpo-icon iconName="edit"></xpo-icon>Edit</button>
                        <button mat-menu-item (click)="deleteMessage()"><xpo-icon iconName="delete"></xpo-icon>Delete</button>
                    </mat-menu>
                </span> -->
            </div>
            <div class="text" [ngClass]="{'text-current-user': message.isFromCurrentUser}">
                <div style="white-space: pre-wrap;">{{ message.content }}</div>
                <div *ngIf="message.fileId" class="document">
                    <button mat-button matTooltip="Go to file" matTooltipPosition="above" (click)="viewDocument()">
                        <xpo-icon iconName="file"></xpo-icon>
                        {{ message.fileName }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>