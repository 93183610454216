<xpo-shell style="height: calc(100vh - 46px)">
	<xpo-header-app-name>Edge</xpo-header-app-name>
	<xpo-header-sub-app-name>Sales</xpo-header-sub-app-name>

	<xpo-header-navigation>
		<button routerLink="dashboard" routerLinkActive="xpo-selected">Dashboard</button>
		<button routerLink="my-rfps" routerLinkActive="xpo-selected">My RFPs</button>
		<button *ngIf="canSeeManagerDashboard() | async" routerLink="manager" routerLinkActive="xpo-selected">Manager Dashboard</button>
		<button *ngIf="canSeeAdminDashboard() | async" routerLink="admin" routerLinkActive="xpo-selected">Admin Dashboard</button>
	</xpo-header-navigation>

	<xpo-header-utilities-actions>
		<xpo-auth-user-profile></xpo-auth-user-profile>
	</xpo-header-utilities-actions>

	<router-outlet></router-outlet>
</xpo-shell>

<xpo-footer xpoFooterSticky [version]="appVersion">
	<!-- <a xpo-regular-link color="accent">Help</a>
	<a xpo-regular-link color="accent">Privacy Policy</a>
	<a xpo-regular-link color="accent">Feedback</a>
	<a xpo-regular-link color="accent">Contact Us</a>
	<a xpo-regular-link color="accent">Release Notes</a> -->
</xpo-footer>