import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

export interface IconButtonCellRendererParams extends ICellRendererParams {
  onClickCallback: Function,
  disabledCallback?: Function;
  iconNameCallback?: Function;
  iconName: string;
  tooltip: string;
}

@Component({
  selector: 'icon-button-cell-renderer',
  templateUrl: './icon-button-cell-renderer.component.html',
  styleUrls: ['./icon-button-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonCellRendererComponent {
  @Input() params!: IconButtonCellRendererParams;
  disabled: boolean = false;
  iconName: string = '';

  agInit(params: IconButtonCellRendererParams): void {
    this.params = params;
    this.updateDisabledState();
    this.updateIconName();
  }

  onClick($event: MouseEvent) {
    if (this.params.onClickCallback instanceof Function) {
      this.params.onClickCallback(this.params.node);
    }
  }

  private updateDisabledState(): void {
    this.disabled = this.params.disabledCallback ? this.params.disabledCallback(this.params.node) : false;
  }

  private updateIconName(): void {
    this.iconName = this.params.iconNameCallback ? this.params.iconNameCallback(this.params.node) : this.params.iconName;
  }
}
