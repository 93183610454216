import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent } from "ag-grid-community";
import { RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { RfpHistoryDetails } from 'src/app/_models/temp/rfpHistory';
import moment from 'moment';
import { GridColumnPartialsHelper } from 'src/app/_reusable/grid-column-partial-helper';

@Component({
  selector: 'rfp-history',
  templateUrl: './rfp-history.component.html',
  styleUrls: ['./rfp-history.component.scss']
})
export class RfpHistoryComponent implements OnInit {
  @Input() rfpDetails: RfpDetails;
  
  columnDefs: (ColDef | ColGroupDef)[] = [
    GridColumnPartialsHelper.RowIndex,
    { field: 'eventLogId',         headerName: "Log Id",    filter: "agTextColumnFilter", hide: true },
    { field: 'eventTimeStamp',     headerName: "Date",      suppressAutoSize: true, flex: 0, sort: "desc", ...GridColumnPartialsHelper.DateCell("MM/DD/YYYY hh:mm:ss A") },
    { field: 'authorFullName',     headerName: "Author",    filter: "agTextColumnFilter", suppressAutoSize: true, flex: 0},
    { field: 'reasonCode',         headerName: "Reason",    filter: "agTextColumnFilter", suppressAutoSize: true, flex: 0},
    { field: 'action',             headerName: "Action",    filter: "agTextColumnFilter"}
  ]

  gridApi: GridApi;
  gridLoadingData: boolean = false;
  gridOptions: GridOptions = {
      defaultColDef: {
          flex: 1,
          resizable: true,
          sortable: true,
          filter: true,
      },
      maintainColumnOrder: true,
      columnDefs: this.columnDefs,
      getRowNodeId: (data) => data.eventLogId,
  } 
  
  constructor(private rfpApi: RfpMgmtApiService) { }

  ngOnInit(): void { }
  
  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange && this.gridApi) {
      this.getGridData()
    }
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.getGridData()
  } 

  getGridData() {
    this.gridLoadingData = true;
    this.gridApi.showLoadingOverlay();

    this.rfpApi.getRfpEvents({salesRfpId: this.rfpDetails.salesRfpId}).subscribe(response => {
      this.gridApi.setRowData(response.items.map((historyDetail: RfpHistoryDetails) => {
        return { 
          ...historyDetail,
          eventTimeStamp: moment.utc(historyDetail.eventTimeStamp).local().valueOf()
        }
      }))
    })
  }
}
