import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerLocation, RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CustomerLocationHierarchy extends CustomerLocation {
  children?: CustomerLocationHierarchy[];
}

export enum AccountHierarchyFilterFields {
  MadCode =         "MadCode",
  Statuses =        "Statuses",
  StatusActive =    "StatusActive",
  StatusInactive =  "StatusInactive",
  StatusExpired =   "StatusExpired",
  Functions =       "Functions",
  FunctionCorp =    "FunctionCorp",
  FunctionPnD =     "FunctionPnD",
  FunctionBillTo =  "FunctionBillTo",

}

@Component({
  selector: 'account-hierarchy',
  templateUrl: './account-hierarchy.component.html',
  styleUrls: ['./account-hierarchy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush 
})
export class AccountHierarchyComponent implements OnInit {
  @Input() madCode: string;
  
  loadingHierarchy: boolean = false;
  hierarchy: CustomerLocationHierarchy[];
  filteredHierarchy$: Observable<CustomerLocationHierarchy[]>;
  expandedStateMap: Map<number, boolean> = new Map();

  accountHierarchyFilterFields = AccountHierarchyFilterFields;
  filterFormGroup: UntypedFormGroup;
  get statusFormGroup(): UntypedFormGroup { return this.filterFormGroup.get(AccountHierarchyFilterFields.Statuses) as UntypedFormGroup }
  get functionFormGroup(): UntypedFormGroup { return this.filterFormGroup.get(AccountHierarchyFilterFields.Functions) as UntypedFormGroup }

  constructor(private rfpApi: RfpMgmtApiService, private snackbar: XpoSnackBar, private formbuilder: UntypedFormBuilder, private changeDetectorRef: ChangeDetectorRef) { 
    this.filterFormGroup = this.formbuilder.group({
      [AccountHierarchyFilterFields.MadCode] : [null],
      [AccountHierarchyFilterFields.Statuses] : this.formbuilder.group({
        [AccountHierarchyFilterFields.StatusActive] :   [true],
        [AccountHierarchyFilterFields.StatusInactive] : [false],
        [AccountHierarchyFilterFields.StatusExpired] :  [false],
      }),
      [AccountHierarchyFilterFields.Functions] : this.formbuilder.group({
        [AccountHierarchyFilterFields.FunctionCorp] :   [true],
        [AccountHierarchyFilterFields.FunctionPnD] :    [true],
        [AccountHierarchyFilterFields.FunctionBillTo] : [true],
      })
    })
  }

  ngOnInit(): void {
    this.filterFormGroup.valueChanges.pipe(
      map(x => {return {...x, [AccountHierarchyFilterFields.MadCode]: x[AccountHierarchyFilterFields.MadCode]?.length > 0 ? x[AccountHierarchyFilterFields.MadCode] : null}})
    ).subscribe(value => {
      this.updateFilteredHierarchy()
    })
  }

  private fetchAndUpdateHierarchy() {
    this.loadingHierarchy = true;
    this.changeDetectorRef.detectChanges()

    this.rfpApi.getLocationsByMadCode({madCode: this.madCode}, null).subscribe(response => {
      this.loadingHierarchy = false;
      this.hierarchy = this.transformToHierarchy(response.items);
      this.updateFilteredHierarchy();
    }, (error) => {
      this.loadingHierarchy = false;
    });
  }

  private updateFilteredHierarchy() {
    this.filteredHierarchy$ = of(this.applyFilter(this.hierarchy, this.getFiltersFromFormGroup()));
    this.changeDetectorRef.detectChanges();
  }

  getHierarchy() {
    this.fetchAndUpdateHierarchy();
  }

  private getFiltersFromFormGroup(): { madCode: string, statuses: string[], functions: string[] } {
    const formValue = this.filterFormGroup.value;
    const statusFilters = [];
    if (formValue[AccountHierarchyFilterFields.Statuses][AccountHierarchyFilterFields.StatusActive]) statusFilters.push('Active');
    if (formValue[AccountHierarchyFilterFields.Statuses][AccountHierarchyFilterFields.StatusInactive]) statusFilters.push('Inactive');
    if (formValue[AccountHierarchyFilterFields.Statuses][AccountHierarchyFilterFields.StatusExpired]) statusFilters.push('Expired');

    const functionFilters = [];
    if (formValue[AccountHierarchyFilterFields.Functions][AccountHierarchyFilterFields.FunctionCorp])   functionFilters.push('CORPORATE');
    if (formValue[AccountHierarchyFilterFields.Functions][AccountHierarchyFilterFields.FunctionPnD])    functionFilters.push('PICKUPORDELIVERY');
    if (formValue[AccountHierarchyFilterFields.Functions][AccountHierarchyFilterFields.FunctionBillTo]) functionFilters.push('BILL-TO');


    return { madCode: formValue.MadCode, statuses: statusFilters, functions: functionFilters };
  }

  private applyFilter(hierarchy: CustomerLocationHierarchy[], filters: { madCode: string, statuses: string[], functions: string[] }): CustomerLocationHierarchy[] {
    const deepCopyHierarchy: CustomerLocationHierarchy[] = Object.clone(hierarchy)

    const filterRecursive = (location: CustomerLocationHierarchy): boolean => {
      const matchesMadCode = !filters.madCode || location.madCode.toUpperCase().includes(filters.madCode.toUpperCase());
      const matchesStatus = filters.statuses.includes(location.statusCode);
      const matchesFunctions = filters.functions.includes(location.functionCode.toUpperCase());
      
      // Check if any children match the filter criteria
      if (location.children && location.children.length > 0) {
        const filteredChildren = location.children.filter(child => filterRecursive(child));
        location.children = filteredChildren;
        return matchesMadCode && matchesStatus && matchesFunctions || filteredChildren.length > 0;
      } 
      else {
        return matchesMadCode && matchesStatus && matchesFunctions;
      }
    };
  
    return deepCopyHierarchy.filter(location => filterRecursive(location));
  }

  getChevron(isExpanded: boolean): string {
    return isExpanded ? "chevron-bottom" : "chevron-right"
  }

  getAccountStatusColor(statusCode: string): string {
    switch(statusCode.toLowerCase()) {
      case "active" : return "green"
      case "inactive" : return "yellow"
      case "expired" : return "red"
      default: return "darkgrey"
    }
  }

  trackByFunction(index: number, item: CustomerLocationHierarchy) {
    return item.accountNumber;
  }

  toggle(location: CustomerLocationHierarchy) {
    this.expandedStateMap.set(location.accountNumber, !this.expandedStateMap.get(location.accountNumber))
  }

  toggleAll(isExpanded: boolean, excludeLevels: number[] = []) {
    const setIsExpandedRecursive = (locations: CustomerLocationHierarchy[], isExpanded: boolean) => {
      locations.forEach(location => {
        if(!excludeLevels.includes(location.levelNumber)) this.expandedStateMap.set(location.accountNumber, isExpanded);
        else this.expandedStateMap.set(location.accountNumber, !isExpanded)

        if (location.children && location.children.length > 0) {
          setIsExpandedRecursive(location.children, isExpanded);
        }
      });
    };
  
    setIsExpandedRecursive(this.hierarchy, isExpanded);
    this.updateFilteredHierarchy();
  }

  transformToHierarchy(locations: CustomerLocation[]): CustomerLocationHierarchy[] {
    this.expandedStateMap = new Map()
    const locationMap = new Map<number, CustomerLocationHierarchy>();
    const hierarchy: CustomerLocationHierarchy[] = [];

    // Initialize ExtendedCustomerLocation objects
    const extendedLocations = locations.map(loc => { 
      this.expandedStateMap.set(loc.accountNumber, loc.levelNumber == 1)
      
      return { ...loc, children: [], isExpanded: false } as CustomerLocationHierarchy
    });

    // Map all locations by accountNumber
    extendedLocations.forEach(location => locationMap.set(location.accountNumber, location));

    // Build hierarchy
    extendedLocations.forEach(location => {
      if (location.parentAccountNumber && locationMap.has(location.parentAccountNumber)) {
        const parent = locationMap.get(location.parentAccountNumber);
        if (parent) {
          parent.children.push(location);
        }
      } else {
        hierarchy.push(location);
      }
    });

    return hierarchy;
  }

  copyAddress(location: any, event: MouseEvent): void {
    event.stopPropagation(); // Stop the click from triggering "toggle()"

    const address = `${location.addressText}, ${location.cityName}, ${location.stateCode}, ${location.zipCode}`;
    navigator.clipboard.writeText(address).then(
      () => {
        this.snackbar.success(`"${address}" successfully copied to the clipboard!`)
      },
      (err) => {
        this.snackbar.success(`There was an issue copying the address to the clipboard.`)
      }
    );
  }
}
