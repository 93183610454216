import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
export interface PremiumServicesStatus {
  hasMustArriveByDate: boolean;
  mustArriveByCodes: string[];
  waivedAccessorials: string[];
  disabledAccessorials: string[]
}

export enum PremiumServiceCodes {
  MABD = "MABD",
  Mexico = "Mexico",
}

@Injectable({ providedIn: 'root' })
export class PremiumServicesService {
  private _premiumServicesStatus: BehaviorSubject<PremiumServicesStatus> = new BehaviorSubject({ hasMustArriveByDate: false, mustArriveByCodes: [], waivedAccessorials: [], disabledAccessorials: [] });

  get premiumServicesStatus$(): Observable<PremiumServicesStatus> {
    return this._premiumServicesStatus.asObservable();
  }

  updatePremiumServicesStatus(status: PremiumServicesStatus): void {
    this._premiumServicesStatus.next(status);
  }
}