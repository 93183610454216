<div class="grid-container">

    <mat-form-field appearance="standard" style="grid-area: min">
        <input [(ngModel)]="customRange.min" type="number" matInput placeholder="Min value" (input)="onCustomRangeChanged()" class="no-arrows" autocomplete="off"/>
    </mat-form-field>

    <mat-form-field  appearance="standard" style="grid-area: max">
        <input [(ngModel)]="customRange.max" type="number" matInput placeholder="Max value" (input)="onCustomRangeChanged()" class="no-arrows" autocomplete="off"/>
    </mat-form-field>

    <div class="container" style="grid-area: range">
        <div class="select-option">
            <input type="checkbox" (change)="onSelectAll(!allOptionsSelected)" [checked]="allOptionsSelected" [indeterminate]="someSelected()"/>
            ( Select All ) 
        </div>
        <div *ngFor="let range of ranges" class="select-option">
            <input type="checkbox" value="range" (change)="onChange(!range.checked, range)" [checked]="range.checked"/>
            <span class="number-container"><ng-container *ngIf="range.min; else noMin">{{ range.min | number: '1.1-3' }}<ng-container *ngIf="!range.max;">+</ng-container></ng-container></span>
            <span class="spacer-container" *ngIf="range.max;"><xpo-icon iconName="arrow-forward"></xpo-icon></span>
            <span class="number-container"><ng-container *ngIf="range.max;">{{ range.max | number: "1.1-3" }} </ng-container> </span>
        </div>
    </div>
</div>

<ng-template #noMin>
    0
</ng-template>