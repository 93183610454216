import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './screens/landing-page/landing-page.component';
import { RfpEditComponent } from './screens/rfp-edit/rfp-edit.component';
import { MyRfpsComponent } from './screens/my-rfps/my-rfps.component';
import { CombinedGuard } from './_guards/combined.guard';
import { RfpManagerGuard } from './screens/manager-dashboard/manager-user.guard';
import { RfpAdminGuard } from './screens/admin-dashboard/admin-user.guard';
import { AdminDashboardComponent } from './screens/admin-dashboard/admin-dashboard.component';
import { ManagerDashboardComponent } from './screens/manager-dashboard/manager-dashboard.component';
import { XpoLtlAuthenticationGuard } from '@xpo-ltl/ngx-ltl-auth/core';
import { RfpRootComponent } from './screens/rfp-root/rfp-root.component';
import { UserNotFoundComponent } from './screens/user-not-found/user-not-found.component';
import { RfpUserGuard } from './_guards/rfp-user.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [XpoLtlAuthenticationGuard],
    children: [
      {
        path: '',
        component: RfpRootComponent,
        canActivate: [RfpUserGuard],
        children: [
          {
            path: "dashboard",
            component: LandingPageComponent,
          },
          {
            path: "my-rfps",
            component: MyRfpsComponent,
          },
          {
            path: "rfp/:salesRfpId",
            component: RfpEditComponent,
            data: {
              unsubscribe: null,
            }
          },
          {
            path: "manager",
            component: ManagerDashboardComponent,
            canActivate: [RfpManagerGuard],
          },
          {
            path: "admin",
            component: AdminDashboardComponent,
            canActivate: [RfpAdminGuard]
          },
          {
            path: '', redirectTo: 'dashboard', pathMatch: 'full'
          },
        ]
      },
      {
        path: 'user-not-found',
        component: UserNotFoundComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
