export{};

declare global {
    interface Array<T> {
        
        /** Returns a deep copy of the array. */
        deepCopy(): T[];
    }
}


Array.prototype.deepCopy = function <T>(): T[] {
    return this.map((item) => {
        if(Array.isArray(item)){
            return item.deepCopy();
        }
        else if (typeof item === 'object' && item !== null) {
            return { ...item };
        }
        else {
            return item;
        }
    });
};