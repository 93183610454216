import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { OnSaveEvent, SaveEvent, TabStatus } from '../../../rfp-edit.component';
import { Observable, Subject, forkJoin } from 'rxjs';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PremiumServiceCodes, PremiumServicesService } from './rfp-premium-services.service';
import { map } from 'rxjs/operators';
import { MexicoFormFields } from './rfp-mexico/rfp-mexico.component';
import { MabdFormFields } from './rfp-mabd/rfp-mabd.component';
import { RfpService } from 'src/app/_services/rfp.service';

interface TabLayout {
  [accessorial: string]: number
}

@Component({
  selector: 'rfp-premium-services',
  templateUrl: './rfp-premium-services.component.html',
  styleUrls: ['./rfp-premium-services.component.scss']
})
export class RfpPremiumServicesComponent implements OnInit, OnDestroy, OnSaveEvent {
  @Input() rfpDetails: RfpDetails;
  @Output() rfpSave = new EventEmitter<SaveEvent>();
  @ViewChildren("savable") saveableChildren!: QueryList<OnSaveEvent>;

  defaultTabIndex: number = 0;

  private destroy$ = new Subject<void>();

  tabLayout: TabLayout = {
    [PremiumServiceCodes.MABD]: 0,
    [PremiumServiceCodes.Mexico]: 1,
  }

  rfpPremiumServiceForm: UntypedFormGroup;
  premiumServiceCodes = PremiumServiceCodes;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private premiumService: PremiumServicesService,
    private rfpService: RfpService,
  ) { }

  ngOnInit(): void {
    this.defaultTabIndex = this.getLowestTabIndex(this.rfpDetails.premiumServiceTypeCodes)
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createForm();
    }
  }

  public getLowestTabIndex(codes: string[]): number {
    let lowestIndex: number | undefined = undefined;

    for (const code of codes) {
      const index = this.tabLayout[code];
      if (lowestIndex === undefined || index < lowestIndex) {
        lowestIndex = index;
      }
    }

    return lowestIndex ?? -1;
  }

  createForm() {
    this.rfpPremiumServiceForm = this.formBuilder.group([]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTabStatus(): TabStatus {
    return {
      complete: this.rfpPremiumServiceForm?.enabled ? this.rfpPremiumServiceForm?.valid : true,
      canSave: this.rfpPremiumServiceForm?.enabled ? this.rfpPremiumServiceForm?.valid : true,
      unsavedChanges: this.rfpPremiumServiceForm?.dirty,
      tabName: "Premium Services"
    };
  }

  hasPremiumService(service: PremiumServiceCodes): boolean {
    return this.rfpDetails?.premiumServiceTypeCodes.includes(service)
  }

  getPremiumServiceTooltip(service: PremiumServiceCodes): string {
    if (!this.hasPremiumService(service)) return "Return to the Opportunity to add this Premium Service"

    return null;
  }

  premiumServiceHasError(service: PremiumServiceCodes): boolean {
    switch(service) {
      case PremiumServiceCodes.MABD:
        return this.rfpPremiumServiceForm.get(MabdFormFields.MustArriveByFormArray)?.invalid ?? false;
      case PremiumServiceCodes.Mexico:
        return this.rfpPremiumServiceForm.get(MexicoFormFields.MexicoForm)?.invalid ?? false;
      default: 
        return false;
    }
  }

  onRfpSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent = {
      changes: []
    }

    const saveObservables = this.saveableChildren.map((child: OnSaveEvent) => child.onRfpSave());

    return forkJoin(saveObservables).pipe(
      map((saveEvents: SaveEvent[]) => {
        saveEvents.forEach((childSaveEvent) => {
          childSaveEvent.changes.forEach((change) => {
            let currentValue = saveEvent.changes.find(x => x.key === change.key)

            if (Array.isArray(change.value)) {
              if (currentValue) {
                currentValue.value = [...currentValue.value, ...change.value]
              }
              else {
                saveEvent.changes.push(change)
              }
            }
            else {
              saveEvent.changes.push(change)
            }
          });
        });

        return saveEvent;
      }),
    );
  }



  onRfpSaveSuccess(): void {
    this.rfpPremiumServiceForm.markAsPristine();
    this.handleEditability();
  }

  handleEditability(): void {
    this.rfpService.isEditable() ? this.rfpPremiumServiceForm.enable() : this.rfpPremiumServiceForm.disable();

    this.saveableChildren.forEach(child => {
      child.handleEditability();
    })
  }
}
