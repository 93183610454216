export{};

declare global {
    interface ObjectConstructor {
        /** Returns a deep clone of the object. */
        clone<T extends object>(original: T): T;
    }
}

/** Deep Copy the Object */
Object.clone = function<T extends object>(original: T): T {
    if (original === null || typeof original !== 'object') {
        return original;
    }

    if (Array.isArray(original)) {
        const arrClone = original as unknown as Array<any>;
        return arrClone.map(item => Object.clone(item)) as unknown as T;
    }

    const objClone: { [key: string]: any } = {};
    for (const key in original) {
        if (Object.prototype.hasOwnProperty.call(original, key)) {
            objClone[key] = Object.clone((original as { [key: string]: any })[key]);
        }
    }

    return objClone as T;
};