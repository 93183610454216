<ng-container *ngIf="dptFormGroup; else loadingRfpData">
    <form [formGroup]="dptFormGroup" class="field-container">

        <!-- DISCOUNT TYPE -->
        <xpo-card *ngIf="!isChangeAccessorialRfp()">
            <xpo-card-header>
            <xpo-card-title>Discount Type</xpo-card-title>
            </xpo-card-header>
        
            <xpo-card-content>

                    <div class="horizontal-container" style="align-items: flex-start;">
                        <mat-radio-group class="radioContainer" [formControlName]="dptFormFields.DiscountTypeCode">
                            <mat-radio-button *ngFor="let type of lookup.dptDiscountTypes$ | async" [value]="type.code" [disabled]="checkDiscountType(type)"> {{ type.display }}</mat-radio-button>
                        </mat-radio-group>
        
                        <div>
                            <mat-form-field floatLabel="always">
                                <mat-label>Discount Amount</mat-label>
                                <input matInput type="number" required name="dptFormFields.DynamicDiscountPercent" class="inputRight" [formControlName]="dptFormFields.DynamicDiscountPercent" [placeholder]="getDefaultValue(getDptOptionFromKey(lookup.dptDiscountTypes$ | async, dptFormGroup.get(dptFormFields.DiscountTypeCode).value), metricFormat.Percent)"/>
                                <span class="inputSuffix">%</span>
                            </mat-form-field>
                            <div *ngVar="getDptOptionFromKey(lookup.dptDiscountTypes$ | async, dptFormGroup.get(dptFormFields.DiscountTypeCode).value) as discountType " class="value-warning-container">
                                <ng-container *ngIf="dptFormGroup.enabled && dptFormGroup.errors?.enteredValueOutOfRange">
                                    <xpo-icon xpoIconSmall iconName="error"></xpo-icon> Outside Acceptable Range: {{ discountType.min | number : '1.2-2' }}% to {{ discountType.max | number : '1.2-2' }}%
                                </ng-container>
                            </div>
                        </div>

                        <mat-form-field floatLabel="always" >
                            <mat-label>Rate Tariff</mat-label>
                            <mat-select [formControlName]="dptFormFields.RateTariffDynamic" required xpoSelect>
                                <mat-option *ngFor="let tariff of lookup.dptRateTariffTypes$ | async" [value]="tariff.code" class=""> 
                                    {{ tariff.display }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
            </xpo-card-content>
        </xpo-card>       


        <!-- FAK -->
        <xpo-card *ngIf="!isChangeAccessorialRfp()">
            <xpo-card-header>
                <xpo-card-title>FAK  Overrides</xpo-card-title>
                <xpo-card-actions>
                    <span class="section-header">
                        <a xpo-regular-link (click)="addFakOverride()" [disabled]="!canAddFakOverride()"><xpo-icon iconName="add-alt"></xpo-icon><span>Add Override</span></a>
                        <mat-checkbox [formControlName]="dptFormFields.IsNoFakOverride"> No Class Overrides </mat-checkbox>
                    </span>
                </xpo-card-actions>
            </xpo-card-header>
        
            <xpo-card-content>
                <table>
                    <tr>
                        <th>FAK Override</th>
                        <th style="text-align: center">Actions</th>
                    </tr>
                    <tr *ngFor="let fakFormGroup of dptFormGroup.get(dptFormFields.FakOverrideFormArray)['controls']; let i = index" [formGroup]="fakFormGroup">
                        <td>
                            <mat-form-field appearance="standard" >
                                <mat-select [formControlName]="dptFormFields.FakOverrideField" required xpoSelect>
                                    <mat-option *ngFor="let fakOverride of lookup.dptFakOverrideTypes$ | async" [value]="fakOverride.overrideClass" [disabled]="!(fakCheckAvailableOption(fakOverride) | async)" class=""> 
                                        <span class="fakOption">
                                            <span>{{fakOverride.overrideClass}}</span>
                                            <span>&emsp;=&emsp;</span>
                                            <span>{{fakOverride.fromClass}}</span>
                                            <xpo-icon xpoIconSmall iconName="arrow-forward"></xpo-icon>
                                            <span style="display:none;">&emsp;→&emsp;</span>
                                            <span>{{fakOverride.throughClass}}</span>
                                        </span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td class="action-button-container">
                            <button xpo-action-button [disabled]="fakFormGroup.disabled" (click)="resetEntry(dptFormFields.FakOverrideFormArray, i)"  matTooltip="Reset Fields"><xpo-icon iconName="loop"></xpo-icon></button>
                            <button xpo-action-button [disabled]="fakFormGroup.disabled || getFormArrayControlByName(dptFormFields.FakOverrideFormArray).length == 1"  (click)="deleteEntry(dptFormFields.FakOverrideFormArray, i)" matTooltip="Delete Override"><xpo-icon iconName="delete"></xpo-icon></button>
                        </td>
                    </tr>
                </table>
            </xpo-card-content>
        </xpo-card>


        <!-- ACCESSORIAL -->
        <xpo-card>
            <xpo-card-header>
                <xpo-card-title>Accessorial Overrides</xpo-card-title>
                <xpo-card-actions>
                    <span class="section-header">
                        <a xpo-regular-link (click)="addAccessorial()" [disabled]="!canAddAccessorialOverride()"><xpo-icon iconName="add-alt"></xpo-icon><span>Add Override</span></a>
                        
                        <mat-checkbox [formControlName]="dptFormFields.IsStandardAccessorials"> Use Standard Accessorials </mat-checkbox>
                    </span>
                </xpo-card-actions>
            </xpo-card-header>
        
            <xpo-card-content>
                <table>
                    <tr>
                        <th>Accessorial</th>
                        <th>Change Amount (per shipment)</th>
                        <th style="text-align: center">Actions</th>
                    </tr>
                    <tr *ngFor="let accessorialFormGroup of dptFormGroup.get(dptFormFields.AccessorialFormArray)['controls']; let i = index" [formGroup]="accessorialFormGroup">
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-select [formControlName]="dptFormFields.AccessorialCode" required xpoSelect>
                                    <mat-option *ngFor="let accessorial of lookup.dptAccessorialTypes$ | async" [value]="accessorial.code"  [disabled]="isDptOptionSelected(accessorial, dptFormFields.AccessorialFormArray, dptFormFields.AccessorialCode)"> {{accessorial.display}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <span class="inputPrefix">$</span>
                                <input matInput type="number" required name="dptFormFields.AccessorialAmount" class="inputRight" [formControlName]="dptFormFields.AccessorialAmount" [placeholder]="getDefaultValue(getDptOptionFromKey(lookup.dptAccessorialTypes$ | async, accessorialFormGroup.get(dptFormFields.AccessorialCode).value), metricFormat.Dollar)"/>
                            </mat-form-field>
                            <div *ngVar="getDptOptionFromKey(lookup.dptAccessorialTypes$ | async, accessorialFormGroup.get(dptFormFields.AccessorialCode).value) as selectedAccessorial" class="value-warning-container">
                                <ng-container *ngIf="accessorialFormGroup.enabled && accessorialFormGroup.errors?.enteredValueOutOfRange">
                                    <xpo-icon xpoIconSmall iconName="error"></xpo-icon> Outside Acceptable Range: ${{ selectedAccessorial.min | number : '1.2-2' }} to ${{ selectedAccessorial.max | number : '1.2-2'}}
                                </ng-container>
                            </div>
                        </td>
                        <td class="action-button-container">
                            <button xpo-action-button [disabled]="accessorialFormGroup.disabled" (click)="resetEntry(dptFormFields.AccessorialFormArray, i)"   matTooltip="Reset Fields"><xpo-icon iconName="loop"></xpo-icon></button>
                            <button xpo-action-button [disabled]="accessorialFormGroup.disabled || getFormArrayControlByName(dptFormFields.AccessorialFormArray).length == 1"  (click)="deleteEntry(dptFormFields.AccessorialFormArray, i)" matTooltip="Delete Override"><xpo-icon iconName="delete"></xpo-icon></button>
                        </td>
                    </tr>
                </table>
            </xpo-card-content>
        </xpo-card>


        <!-- AMC -->
        <xpo-card *ngIf="!isChangeAccessorialRfp()">
            <xpo-card-header>
                <xpo-card-title>AMC  Overrides</xpo-card-title>
                <xpo-card-actions>
                    <span class="section-header">
                        <a xpo-regular-link (click)="addAmc()" [disabled]="!canAddAmcOverride()"><xpo-icon iconName="add-alt"></xpo-icon><span>Add Override</span></a>
                        <mat-checkbox [formControlName]="dptFormFields.IsStandardAmc"> Use Standard AMC </mat-checkbox>
                    </span>
                </xpo-card-actions>
            </xpo-card-header>
        
            <xpo-card-content>

                <table>
                    <tr>
                        <th>AMC</th>
                        <th>Universal Amount</th>
                        <th style="text-align: center">Actions</th>
                    </tr>
                    <tr *ngFor="let amcFormGroup of dptFormGroup.get(dptFormFields.AmcFormArray)['controls']; let i = index" [formGroup]="amcFormGroup" >
                        <td>
                            <mat-form-field appearance="standard">
                                <mat-select [formControlName]="dptFormFields.AmcCode" required xpoSelect>
                                    <mat-option *ngFor="let amc of lookup.dptAmcTypes$ | async" [value]="amc.code" [disabled]="isDptOptionSelected(amc, dptFormFields.AmcFormArray, dptFormFields.AmcCode)"> {{amc.display}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="standard">
                                <span class="inputPrefix">$</span>
                                <input matInput type="number" required class="inputRight" name="dptFormFields.AmcAmount" [formControlName]="dptFormFields.AmcAmount" [placeholder]="getDefaultValue(getDptOptionFromKey(lookup.dptAmcTypes$ | async, amcFormGroup.get(dptFormFields.AmcCode).value), metricFormat.Dollar)"/>
                            </mat-form-field>
                            <div *ngVar="getDptOptionFromKey(lookup.dptAmcTypes$ | async, amcFormGroup.get(dptFormFields.AmcCode).value) as selectedAmc" class="value-warning-container">
                                <ng-container *ngIf="amcFormGroup.enabled && amcFormGroup.errors?.enteredValueOutOfRange">
                                    <xpo-icon xpoIconSmall iconName="error"></xpo-icon> Outside Acceptable Range: ${{ selectedAmc.min | number : '1.2-2'}} to ${{ selectedAmc.max | number : '1.2-2'}}
                                </ng-container>
                            </div>
                        </td>
                        <td class="action-button-container">
                            <button xpo-action-button [disabled]="amcFormGroup.disabled" (click)="resetEntry(dptFormFields.AmcFormArray, i)"   matTooltip="Reset Fields"><xpo-icon iconName="loop"></xpo-icon></button>
                            <button xpo-action-button [disabled]="amcFormGroup.disabled || getFormArrayControlByName(dptFormFields.AmcFormArray).length == 1"  (click)="deleteEntry(dptFormFields.AmcFormArray, i)"  matTooltip="Delete Override"><xpo-icon iconName="delete"></xpo-icon></button>
                        </td>
                    </tr>
                </table>
            </xpo-card-content>
        </xpo-card>


    </form>
</ng-container>

<ng-template #loadingRfpData>
    <div class="center-align-icon">
        <div style="width:100px;">
            <xpo-busy-loader [slow]="true" matSuffix></xpo-busy-loader>
        </div>
        <h1>Loading Dynamic Pricing Data...</h1>
    </div>
</ng-template>
