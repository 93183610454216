import { ColDef, ColGroupDef, GridApi, RowDataTransaction, RowNode } from 'ag-grid-community';

export class GridService {  
  /** Filters and returns the given ColDef array of columns whose key fields are not contained within the `columnFieldsToRemove` input array */
  static removeColumnDefsById(columnDefs: (ColDef | ColGroupDef)[], columnFieldsToRemove: string[]): (ColDef | ColGroupDef)[]{
    return columnDefs.filter(columnGroup => {
      let children: (ColDef | ColGroupDef)[] = columnGroup["children"];
      if(children) {
        columnGroup["children"] = GridService.removeColumnDefsById(columnGroup["children"], columnFieldsToRemove);
      }
      else if(columnGroup["field"] && columnFieldsToRemove.includes(columnGroup["field"])) {
        return null;
      };
      return columnGroup;
    })
  }

  /** Uses transactions to soft-refresh the grid data. */
  static softRefreshGridData<T extends {[key: string]: any}>(gridApi: GridApi, newData: T[], keyField: string) {
    let rowTransaction: RowDataTransaction = {
      add: [],
      update: [],
      remove: [],
    };

    // Create a map for quick lookup
    const newDataMap = new Map(newData.map(item => [item[keyField], item]));

    gridApi.forEachNode((node: RowNode) => {
      if (!node.group) {
        const existingData = newDataMap.get(node.data[keyField]);
        if (existingData) {
          // Update existing row
          rowTransaction.update.push(existingData);
          newDataMap.delete(node.data[keyField]);
        } else {
          // Remove row not in new data
          rowTransaction.remove.push(node.data);
        }
      }
    });

    // Add remaining new rows
    newDataMap.forEach(value => rowTransaction.add.push(value));
    gridApi.applyTransactionAsync(rowTransaction);
  }
}
