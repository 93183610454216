import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AccountOverview } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';

@Component({
  selector: 'customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  @Input() account: AccountOverview;
  @Input() rfpDetails: RfpDetails;

  constructor() { }

  ngOnInit(): void {
  }
}
