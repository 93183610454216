import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgFrameworkComponent, IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'preset-number-range-filter',
  templateUrl: './preset-number-range-filter.component.html',
  styleUrls: ['./preset-number-range-filter.component.scss']
})
export class PresetNumberRangeFilterComponent implements AgFrameworkComponent<IFilterParams> {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => number;
  
  allOptionsSelected: boolean = true;
  ranges: LaneBalanceRange[] = []
  customRange: LaneBalanceRange = { min: null, max: null };
  customRangeApply = new Subject<LaneBalanceRange>();

  agInit(params: IFilterParams): void {
    this.params = params;
    this.ranges = params[NUMBER_RANGE_PARAMS_KEY]
    this.valueGetter = params.valueGetter;

    this.customRangeApply.pipe(debounceTime(500)).subscribe(x => {
      this.params.filterChangedCallback();
    })
  }

  /** Returns true if either custom range is used or at least one range checkbox is unchecked */
  isFilterActive(): boolean {
    return this.noneSelected() || this.someSelected() || this.customRange.min != null || this.customRange.max != null
  }

  /** Check if an individual cell passes our filter. A value passes if the value is within any of the checked ranges or its within the custom range */
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    let curVal = this.valueGetter(params.node);
    let checkFilters = this.ranges.filter(x => x.checked).map(x => this.withinRange(curVal, x.min, x.max))

    return checkFilters.find(x => x) || this.withinRange(curVal, this.customRange.min, this.customRange.max)
  }

  /** Returns the filter model so it can be saved / loaded correctly */
  getModel(): any {
    return { customRange: this.customRange, ranges: this.ranges };
  }

  /** Sets the filter values based on the given model */
  setModel(model: any): void {
    this.customRange.min = model?.customRange?.min
    this.customRange.max = model?.customRange?.max

    if(model?.ranges){
      model.ranges.forEach(x => {
        this.ranges.find(y => y.min === x.min && y.max === x.max).checked = x.checked
      })
    }
    else {
      this.onSelectAll(true)
    }
  }

  /** Event for when either min or max custom range input is changed */
  onCustomRangeChanged(){
    if(this.customRange.min || this.customRange.max){
      this.onSelectAll(false)
    }
    else if(!this.customRange.min && !this.customRange.max){
      this.onSelectAll(true)
    }
    this.customRangeApply.next(this.customRange)
  }

  /** Event for when a single range check box is changed */
  onChange(newValue, range: LaneBalanceRange): void {
    range.checked = newValue;
    this.allOptionsSelected = this.allSelected()
    this.params.filterChangedCallback();
  }

  /** Event for when the "Select All" check box is changed */
  onSelectAll(newValue){
    this.allOptionsSelected = newValue
    this.ranges.forEach(x => x.checked = newValue)
    this.params.filterChangedCallback();
  }

  /** Returns true if no range check boxes are checked */
  noneSelected(): boolean {
    return this.ranges.filter(x => x.checked).length == 0
  }

  /** Returns true if some, but not all, range check boxes are checked */
  someSelected(): boolean {
    return this.ranges.filter(x => x.checked).length > 0 && !this.allOptionsSelected
  }

  /** Returns true if all range checkboxes are checked */
  allSelected(): boolean {
    return this.ranges.filter(x => !x.checked).length == 0
  }

  /** Returns true if the input value is within the given min and max */
  withinRange(check: number, min: number, max: number): boolean {
    if(min && max){
      return check >= min && check <= max 
    }
    else if (min){
      return check >= min
    }
    else if (max){
      return check <= max
    }
    return false
  }
}

/** Interface used to store range checkbox information */
export interface LaneBalanceRange {
  min?: number;
  max?: number;
  checked?: boolean;
}

/** The key used to pass the number range parameters into the filter */
export const NUMBER_RANGE_PARAMS_KEY: string = "numberRanges"

/** The pre-defined ranges for a Lane Balance column */
export const LANE_BALANCE_RANGES: LaneBalanceRange[] = [
  {
    max: 0.749,
    checked: true
  },
  {
    min: 0.75,
    max: 0.899,
    checked: true
  },
  {
    min: 0.9,
    max: 1.099,
    checked: true
  },
  {
    min: 1.1,
    max: 1.249,
    checked: true
  },
  {
    min: 1.25,
    checked: true
  }
]
