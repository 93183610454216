<form [formGroup]="ratingTariffFormGroup" class="formContainer" *ngIf="rfpTariffOptions">
    <div class="tariffContainer">
        <h2>Rating Tariffs</h2>  
        <mat-form-field floatLabel="always">
            <mat-label>Domestic Rating Tariff</mat-label>
            <input
                type="text"
                matInput
                required
                placeholder="Select Domestic Rating Tariff"
                [formControlName]="ratingTariffFields.DomesticRateTariff"
                [matAutocomplete]="domesticTariffRateAutocomplete"
                xpoAutocomplete
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete autoActiveFirstOption #domesticTariffRateAutocomplete [displayWith]="displayWith">
            <mat-option *ngFor="let tariff of filteredDomesticTariffOptions$ | async" [value]="tariff">
                {{ tariff.tariffName }} - {{ tariff.effectiveDate | date: "MM/dd/yyyy" }}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>International Rating Tariff</mat-label>
            <input
                type="text"
                matInput
                placeholder="Select International Rating Tariff"
                [formControlName]="ratingTariffFields.InternationalRateTariff"
                [matAutocomplete]="internationalTariffRateAutocomplete"
                xpoAutocomplete
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete autoActiveFirstOption #internationalTariffRateAutocomplete [displayWith]="displayWith" >
            <mat-option *ngFor="let tariff of filteredInternationalTariffOptions$ | async" [value]="tariff">
                {{ tariff.tariffName }} - {{ tariff.effectiveDate | date: "MM/dd/yyyy" }}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>Intra-Canada Rating Tariff</mat-label>
            <input
                type="text"
                matInput
                placeholder="Select Canada Rating Tariff"
                [formControlName]="ratingTariffFields.CanadaRateTariff"
                [matAutocomplete]="canadaTariffRateAutocomplete"
                xpoAutocomplete
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete autoActiveFirstOption #canadaTariffRateAutocomplete [displayWith]="displayWith">
            <mat-option *ngFor="let tariff of filteredCanadaTariffOptions$ | async" [value]="tariff">
                {{ tariff.tariffName }} - {{ tariff.effectiveDate | date: "MM/dd/yyyy" }}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div>
        <h2>Discount Type</h2>  
        <div class="horizontal-container">
            <mat-radio-group class="radioContainer" [formControlName]="ratingTariffFields.DiscountType">
                <mat-radio-button *ngFor="let type of lookup.discountTypes$ | async" [value]="type.code" > {{ type.name }}</mat-radio-button>
            </mat-radio-group>

            <mat-form-field class="example-full-width" floatLabel="always" *ngIf="isDiscountTypeFlat() || isDiscountTypePercent()">
                <mat-label>Amount</mat-label>
                <span class="inputPrefix" *ngIf="isDiscountTypeFlat()">$</span>
                <input matInput type="number" [name]="ratingTariffFields.DiscountAmount" [formControlName]="ratingTariffFields.DiscountAmount"/>
                <span class="inputSuffix" *ngIf="isDiscountTypePercent()">%</span>
            </mat-form-field>
        </div>
    </div>
</form>