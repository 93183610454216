import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'accountFunctionDisplay' })
export class AccountFunctionDisplayPipe implements PipeTransform {
  transform(functionCode: string): string {
    switch(functionCode.toUpperCase()){
      case "CORPORATE": return "Corp";
      case "PICKUPORDELIVERY": return "PnD";
      case "BILL-TO": return "Bill-To";
      default: return functionCode;
    }
  }
}