import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { RfpMgmtApiService, RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';

export enum AddUserFormFields {
  NewEmployee = "newEmployee",
  ChannelCode = "channelCode"
}

export interface PsaeAddDialogResponse {
  newEmployee: RfpUser;
  channelCode: string;
}

@Component({
  selector: 'psae-assignment-add-dialog',
  templateUrl: './psae-assignment-add-dialog.component.html',
  styleUrls: ['./psae-assignment-add-dialog.component.scss']
})
export class PsaeAssignmentAddDialogComponent {
  addUserForm: UntypedFormGroup;
  addUserFormFields = AddUserFormFields;

  allUsers: RfpUser[] = [];
  filteredUserOptions$: Observable<RfpUser[]>;
  channelCodes: string[] = ["Local", "National"]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    public dialogRef: MatDialogRef<PsaeAssignmentAddDialogComponent, PsaeAddDialogResponse>,
    private fb: UntypedFormBuilder,
    private rfpApi: RfpMgmtApiService) { }

  ngOnInit(): void {
    this.rfpApi.listUsers({} as any).subscribe(x => {
      this.allUsers = x.items;
    })
    this.createForm();
  }

  createForm(){
    this.addUserForm = this.fb.group({
      [AddUserFormFields.NewEmployee]:  [null, Validators.required],
      [AddUserFormFields.ChannelCode]:  [null, Validators.required],
    })

    this.filteredUserOptions$ = this.addUserForm.get(AddUserFormFields.NewEmployee)?.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map(value => {
        if(typeof value === "string" || value instanceof String)
          return this.allUsers.filter(x => x.employeeFullName.toLowerCase().includes(value.toLowerCase()))
        else return [value]
      })
    )
  }

  userFieldDisplay(user: RfpUser) {
    return user ? `${user.employeeId} - ${user.employeeFullName.toTitleCase()}` : ""
  }

  add(){
    this.dialogRef.close(this.addUserForm.value)
  }
}
