import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RfpMessageDetails } from 'src/app/_models/temp/rfpMessageDetails';
import { RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { MessageEditDialogInput } from './message-edit-dialog/message-edit-dialog-input';
import { MessageEditDialogComponent } from './message-edit-dialog/message-edit-dialog.component';
import { MessageEditDialogResponse } from './message-edit-dialog/message-edit-dialog-response';
import { LookupService } from 'src/app/_services/lookup.service';
import { User } from '@xpo-ltl/sdk-common';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { UserService } from 'src/app/_services/user.service';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'rfp-messages',
  templateUrl: './rfp-messages.component.html',
  styleUrls: ['./rfp-messages.component.scss']
})
export class RfpMessagesComponent implements OnInit {
  @Input() containerHeight: string;
  @Input() rfpDetails: RfpDetails;
  @Input() isActiveTab: boolean;
  @Output() onDocumentSelected = new EventEmitter<number>();
  

  computedHeight: string;
  messageRefreshTimer: Subscription;
  refreshTimerInterval: number = 30000; // 10 seconds in milliseconds

  /** NOTE: Messages needs to be sorted newest to oldest */
  rfpMessages: RfpMessageDetails[] = []

  messageIsPrivate: boolean = false;
  messageBoxText: string = "";

  currentUser: User;
  currentUserPicture: string;


  constructor(private dialog: MatDialog, private rfpApi: RfpMgmtApiService, private lookup: LookupService, private snackBar: XpoSnackBar, private userService: UserService) { }

  ngOnInit(): void {
    this.computedHeight = `calc(100vh - ${this.containerHeight})`;
    
    this.userService.authUser$.subscribe(user => {
      this.currentUser = user;
      this.messageRefreshTimer = timer(0, this.refreshTimerInterval).subscribe(x => this.getMessages());
    })
  }

  
  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.getMessages()
    }
  }

  ngOnDestroy(){
    if (this.messageRefreshTimer) {
      this.messageRefreshTimer.unsubscribe();
    }
  }
  
  getMessages() {
    this.rfpApi.getRfpMessages({ salesRfpId: this.rfpDetails.salesRfpId }, { includeInternalSales: true } as any).subscribe(response => {
      this.rfpMessages = response.items.map((x: RfpMessageDetails) => this.enrichMessage(x)).sort((a, b) => Date.parse(b.createdOn)  - Date.parse(a.createdOn))
    })
  }


  addNewMessage() {
    let newMessage: any = {
      salesRfpId: this.rfpDetails.salesRfpId,
      authorId: this.currentUser.employeeId,
      content: this.messageBoxText,
      commentTypeCode: "CommentType",
      commentSubTypeCode: this.messageIsPrivate ? "SalesNotesInternal" : "SalesNotes",
    }

    this.rfpApi.createMessage(newMessage).subscribe((response: RfpMessageDetails) => {
      this.rfpMessages.unshift(this.enrichMessage(response));
    })
    this.messageBoxText = "";
    this.messageIsPrivate = false;
  }

  deleteMessage(message: RfpMessageDetails){
    if(message.authorId == this.currentUser.employeeId){
      this.rfpApi.deleteMessage({messageId: message.messageId}).subscribe(response => {
        let index = this.rfpMessages.findIndex(x => x.messageId == message.messageId)
        this.rfpMessages.splice(index, 1)
        this.snackBar.success("Message Successfully Deleted")
      }),
      (error => {
        this.snackBar.error(error.message)
      })
    }
    else {
      this.snackBar.error("You cannot delete messages from other users")
    }
  }

  editMessage(messageToEdit: RfpMessageDetails){
    const dialogData: MessageEditDialogInput = {
      messageToEdit: messageToEdit
    }

    this.dialog.open(MessageEditDialogComponent, { width: "900px", height: "600px", data: dialogData }).afterClosed().subscribe((dialogResponse: MessageEditDialogResponse)  => {
      if(dialogResponse?.updatedMessage){
        this.rfpApi.updateMessage(dialogResponse.updatedMessage as any, {messageId: dialogResponse.updatedMessage.messageId}).subscribe(response => {
          
        })

        let index = this.rfpMessages.findIndex(x => x.messageId == dialogResponse.updatedMessage.messageId)
        this.rfpMessages[index] = dialogResponse.updatedMessage
      }
    })
  }

  enrichMessage(rfpMessage: RfpMessageDetails){
    return {
      ...rfpMessage, 
      isFromCurrentUser: rfpMessage.authorId == this.currentUser?.employeeId ?? false,
      authorFullName: rfpMessage.authorFullName ? rfpMessage.authorFullName : "Unknown"
    }
  }

  documentSelected(docId: number){
    this.onDocumentSelected.emit(docId)
  }

  toggleIsPrivate() {
    this.messageIsPrivate = !this.messageIsPrivate
  }
  
  get messageIsPrivateIcon(): string {
    return this.messageIsPrivate ? "locked" : "unlocked"
  }
}