import { Component, OnInit } from '@angular/core';
import { RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';


@Component({
  selector: 'admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  constructor(private rfpApi: RfpMgmtApiService) { }

  ngOnInit(): void { }
}
