<div class="gridContainer">
    <div *ngIf="hasActiveAgreementHasTDC">
        <xpo-snack-bar  status="warn" [hideClose]="true" message="This customer's active agreement has TDC. This accessorial is being phased out. If your customer desires a similar product, please use MBD / MBS / G! / G12 instead."></xpo-snack-bar>
    </div>

    <xpo-ag-grid>
        <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </xpo-ag-grid>

    <!-- <xpo-ngx-grid  [gridConfig]="gridConfig" (gridReady)="onGridReady($event)" #grid></xpo-ngx-grid> -->
</div>