import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowClassParams, RowDataTransaction, RowNode, RowSelectedEvent, RowStyle } from 'ag-grid-community';
import { AccountOverview } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { CustomerLocation, RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { TabStatus, OnSaveEvent, SaveEvent } from '../../../rfp-edit.component';
import { Observable, of } from 'rxjs';
import { GridColumnPartialsHelper } from 'src/app/_reusable/grid-column-partial-helper';
import { RfpService } from 'src/app/_services/rfp.service';

@Component({
  selector: 'rfp-locations',
  templateUrl: './rfp-locations.component.html',
  styleUrls: ['./rfp-locations.component.scss']
})
export class RfpLocationsComponent implements OnInit, OnSaveEvent {
  @Input() account: AccountOverview;
  @Input() rfpDetails: RfpDetails;
  @Input() locked: boolean = false;
  @Output() rfpSave = new EventEmitter<SaveEvent>();
  
  parentMadCodeLevelString = "parentMadCodeLevel_";

  RfpLocationsColDefs: (ColDef | ColGroupDef)[] = [
    {
      ...GridColumnPartialsHelper.SelectionWithSelectAll, 
      suppressColumnsToolPanel: true, 
      headerClass: (params) => { return this.rfpService.isEditable() && !this.locked ? "" : "hide-select-all-header" }, 
      checkboxSelection: (params) => { return this.rfpService.isEditable() && !this.locked }
    },
    {
      headerName: "Location Details",
      children: [
        { field: 'madCode',       headerName: "Mad Code",       filter: "agTextColumnFilter",   },
        { field: 'accountNumber', headerName: "Account Number", filter: "agTextColumnFilter"  },
        { field: 'name',          headerName: "Account Name",   filter: "agTextColumnFilter"  },
        { field: 'statusCode',    headerName: "Status",         filter: "agSetColumnFilter"  },
        { field: 'functionCode',  headerName: "Function",       filter: "agSetColumnFilter"  },
        { field: 'parentMadCode',       headerName: "ParentMad Code",       filter: "agTextColumnFilter",   hide: true },
      ]
    },
    {
      headerName: "Location Address",
      children: [
        { field: 'addressText',   headerName: "Address",        filter: "agTextColumnFilter"  },
        { field: 'cityName',      headerName: "City",           filter: "agTextColumnFilter"  },
        { field: 'stateCode',     headerName: "State",          filter: "agSetColumnFilter"  },
        { field: 'zipCode',       headerName: "Zip Code",       filter: "agTextColumnFilter"  },
        { field: 'countryCode',   headerName: "Country",        filter: "agSetColumnFilter"  },
      ]
    },
    { field: 'displayOrderSequence',   headerName: "Row Order",        filter: "agSetColumnFilter",  hide: true, suppressColumnsToolPanel: true, }
  ]

  RfpLocationsParentColDefs: (ColDef) = { field: this.parentMadCodeLevelString,   headerName: "Parent MadCode", filter: "agSetColumnFilter", rowGroup: true, hide: true, suppressColumnsToolPanel: true,  }

  allMadCodes = Object.create(null);
  selectedMadCodes = Object.create(null);
  rfpSavedMadCodes = Object.create(null);
  selectionChanged = false;
  hasPnDLocation: boolean = false;
  hasPnDLocationSelected: boolean = false;

  gridApi: GridApi;
  columnApi: ColumnApi;
  gridLoadingData: boolean = false;
  gridOptions: GridOptions = {
    defaultColDef: {
        resizable: true,
        sortable: true,
        flex: 1,
    },
    autoGroupColumnDef: {
      headerName: 'Parent Mad Code',
      field: `parentMadCode`,
      filter: "agTextColumnFilter"
    },
    groupDisplayType: "groupRows",
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    rowSelection: "multiple",
    columnDefs: this.RfpLocationsColDefs,
    getRowNodeId: (data) => data.madCode,
    getRowStyle: this.getRowStyle.bind(this),
    onRowSelected: this.onRowSelectionChanged.bind(this),
    onSortChanged: (event) => { event.api.redrawRows()},
  };

  constructor(
    private dialog: MatDialog, 
    private rfpApi: RfpMgmtApiService,
    private rfpService: RfpService,
  ) { }

  ngOnInit(): void {
  }

  getTotalLocationCount(): number {
    return Object.keys(this.allMadCodes).length
  }
  getSavedLocationCount(): number {
    return Object.keys(this.rfpSavedMadCodes).length
  }
  getSelectedCount(): number {
    return Object.keys(this.selectedMadCodes).length
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange && this.gridApi) {
      this.getGridData()
    }
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.columnApi = event.columnApi
    this.getGridData()
  }

  getGridData() {
    this.gridLoadingData = true;
    this.gridApi.showLoadingOverlay();

    this.allMadCodes = Object.create(null);
    this.selectedMadCodes = Object.create(null);
    this.rfpSavedMadCodes = Object.create(null);
    
    this.rfpApi.getLocationsByMadCode({madCode: this.account.madCode}, {customerStatusCodes: ["Active"]}).subscribe(response => {
      const locations: CustomerLocation[] = response.items
      let newColDefs = this.createDynamicColumnDefinitions(Math.max(...locations.map(x => x.levelNumber)))
      this.gridApi.setColumnDefs(newColDefs); 
      this.columnApi.resetColumnState()
      
      let rowData = this.processLocationData(locations);
      this.gridApi.setRowData(rowData);
      
      // Create a map of madCodes
      locations.forEach(location => {
        this.allMadCodes[location.madCode] = location.functionCode;
      });
      this.rfpDetails.locationMadCodes.forEach(madCode => {
        this.rfpSavedMadCodes[madCode] = this.allMadCodes[madCode];
      });

      this.rfpSavedMadCodes[this.rfpDetails.madCode] = this.allMadCodes[this.rfpDetails.madCode];
      this.rfpSavedMadCodes[this.rfpDetails.primaryLocationMadCode] = this.allMadCodes[this.rfpDetails.primaryLocationMadCode];
      this.selectedMadCodes = Object.clone(this.rfpSavedMadCodes)

      this.gridApi.forEachNode((rowNode: RowNode, index: number) => {
        if(rowNode.level === 0){
          rowNode.setExpanded(true)
        }
        if(!rowNode.group){
          let isSelected = this.selectedMadCodes[rowNode.id] != null || this.rfpDetails.madCode == rowNode.id || this.rfpDetails.primaryLocationMadCode == rowNode.id
          rowNode.setSelectedInitialValue(isSelected)
        }
      })

      this.gridApi.getRowNode(this.rfpDetails.madCode).selectThisNode()
      this.gridApi.getRowNode(this.rfpDetails.primaryLocationMadCode).selectThisNode()
      this.gridLoadingData = false;
      
      setTimeout(() => {
        this.selectionChanged = false;
        this.handleEditability();
      }, 0);
    })
  }

  processLocationData(locations: CustomerLocation[]){
    const processedLocations = this.buildHierarchy(locations)
    return processedLocations;
  }

  buildHierarchy(locations: CustomerLocation[], parentMadCode = null, parentLevels = []) {
    const result = [];
    locations.forEach(location => {
      if (location.parentMadCode === parentMadCode) {
        const currentLevels = [...parentLevels, location.madCode];
        const currentLocation = {
          ...location,
          ...currentLevels.reduce((acc, val, i) => {
            if (i < parentLevels.length) {
              return { ...acc, [`${this.parentMadCodeLevelString}${i}`]: val };
            }
            return acc;
          }, {})
        };
        result.push(currentLocation, ...this.buildHierarchy(locations, location.madCode, currentLevels));
      }

      if (location.functionCode === "PickupOrDelivery" && location.statusCode === "Active") {
        this.hasPnDLocation = true;
      }
    });
    return result;
  }
  
  createDynamicColumnDefinitions(levels: number): (ColDef | ColGroupDef)[] {
    let dynamicColDefs = []

    for(let i = 0; i < levels; i++){

      let newColDef = {...this.RfpLocationsParentColDefs}
      newColDef.field = `${this.parentMadCodeLevelString}${i}`
      newColDef.headerName = `Parent Mad Code Level ${i}`
      newColDef.rowGroupIndex  = i
      dynamicColDefs.push(newColDef)
    }

    return [...this.RfpLocationsColDefs.deepCopy(), ...dynamicColDefs]
  }

  onRowSelectionChanged(event:  RowSelectedEvent) {
    let nodeData = event.node.data;     
    
    if(!event.node.isSelected()){
      delete this.selectedMadCodes[event.node.id]
    }

    if(!event.node.group){
      if(event.node.isSelected()){
        this.selectedMadCodes[event.node.id] = event.node.data.functionCode
      }
      const isNegotiatingCustomer = nodeData.madCode == this.rfpDetails.madCode
      const isPrimaryLocation = nodeData.madCode == this.rfpDetails.primaryLocationMadCode

      if(isNegotiatingCustomer || isPrimaryLocation) {
        if(!event.node.isSelected()) event.node.selectThisNode(true)
      }
      else {
        this.selectionChanged = true;
      }
      this.gridApi.redrawRows({rowNodes: [event.node]});
    }

    this.checkForPnDSelected();
  }

  checkForPnDSelected(){
    this.hasPnDLocationSelected = Object.values(this.selectedMadCodes).some(x => x === "PickupOrDelivery");
  }

  getRowStyle(params: RowClassParams): RowStyle {
    if(!params.node.group){
      let style: any = {}

      const selected = params.node.isSelected();
      let backgroundColor: string = selected ? "#E4EFFA" : "#FFFFFF"

      const isNegotiatingCustomer = params.node.id == this.rfpDetails.madCode
      if(isNegotiatingCustomer){
        backgroundColor = "#E1FFE1" //Green
      }

      const isPrimaryLocation = params.node.id == this.rfpDetails.primaryLocationMadCode
      if(isPrimaryLocation){
        backgroundColor = "#F1E1FF" //Purple
      }
            
      if (params.node.lastChild && params.node.level == params.columnApi.getRowGroupColumns().length - 1) {
        style.borderBottom = '1px solid gray';
      }
      
      style.background = backgroundColor
      return style;
    }
    else {
      return null
    }
  }

  getTabStatus(): TabStatus {
    return { 
      complete: this.rfpDetails.isDpt ? this.checkDptComplete() : true,
      canSave: true,
      unsavedChanges: !this.gridLoadingData && this.selectionChanged,
      tabName: "Locations",
      matBadge: this.rfpDetails.locationMadCodes.length
    };
  }

  checkDptComplete(): boolean {
    if(!this.locked) {
      return this.hasPnDLocation && this.hasPnDLocationSelected
    }
    else {
      return this.hasPnDLocation;
    }
  }

  onRfpSave(): Observable<SaveEvent> {
    let saveEvent: SaveEvent  = {
      changes: []
    }

    const selectedRowMadCodes = Object.keys(this.selectedMadCodes) 
    saveEvent.changes.push({ key: "locationMadCodes", value: selectedRowMadCodes })

    return of(saveEvent);
  }

  onRfpSaveSuccess(): void {
    
  }

  handleEditability(): void { }
}
