export enum MetricFormat
{
    Dollar = "Dollar",
    Percent = "Percent",
    Fixed0 = "Fixed0",
    Fixed1 = "Fixed1",
    Fixed2 = "Fixed2",
    Fixed3 = "Fixed3",
    Fixed4 = "Fixed4"
}

export enum RegionTypeEnum
{
    /** Zip3 */
    ZIP3 = 1,

    /** Zip5 */
    ZIP5 = 2,

    /** State */
    STATE = 3,

    /** City */
    CITY = 4,
}

export enum TimeBucketTypeEnum
{
    /** Year */
    YEAR = 1,

    /** Quarter */
    QUARTER = 2,

    /** Month */
    MONTH = 3,

    /** Day */
    DAY = 4,
}

export enum ApiErrorType {
    BadRequest = 0,
    ObjectNotFound = 1,
    ObjectExists = 2,
    AccessDenied = 3,
    ValidationSingle = 4,
    ValidationArray = 5,
    Generic = 6,
}

