<div xpoDialog>
    <h1 mat-dialog-title>
      Customer Information
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content>
      <table class="info-table">
        <tr>
          <th>MAD Code</th>
          <td>{{ accountOverview.madCode }}</td>
          
          <th>Account Number</th>
          <td>{{ accountOverview.customerNumber }}</td>

          <th>3PL Type</th>
          <td>{{ rfpDetails.thirdPartyTypeCode }}</td>

        </tr>

        <tr>
          <th>Account Name</th>
          <td>{{ accountOverview.name }}</td>

          <th>Region</th>
          <td>{{ getRegion() }}</td>
          
          <th>3PL MAD Code</th>
          <td>{{ rfpDetails.thirdPartyLogisticsMadCode }}</td>  
        </tr>

        <tr>
          <th>Address</th>
          <td >{{ getCompanyAddress() }}</td>
        </tr>

      </table>
      
      <div class="divider-horizontal"></div>

      <h1>Assigned Employees</h1>
      <table class="info-table">
        <tr>
          <th>Sales Agent <xpo-icon *ngIf="enableSellerChange" xpoIconSmall iconName="reassign" class="reassign-button" matTooltip="Reassign Sales Agent" (click)="openUserSelectDialog('seller')"></xpo-icon></th>
          <td>{{ rfpDetails.salesAgentFullName?.toTitleCase() }}</td>
          
          <th>Pricing Analyst</th>
          <td>{{ rfpDetails.assignedPricingAnalystFullName?.toTitleCase() }}</td>
        </tr>
        <tr>
          <ng-container *ngIf="rdsReview">
            <th>Regional Director of Sales <xpo-icon *ngIf="enableRdsChange" xpoIconSmall iconName="reassign" class="reassign-button" matTooltip="Reassign Regional Director of Sales" (click)="openUserSelectDialog('rds')"></xpo-icon></th>
            <td>{{ rdsReview.reviewerEmployeeFullName?.toTitleCase() }}</td>
          </ng-container>
          <ng-container *ngIf="psaeReview">
            <th>Premium Service Account Executive <xpo-icon *ngIf="enablePsaeChange" xpoIconSmall iconName="reassign" class="reassign-button" matTooltip="Reassign Premium Service Account Executive" (click)="openUserSelectDialog('psae')"></xpo-icon></th>
            <td>{{ psaeReview.reviewerEmployeeFullName?.toTitleCase() }}</td>
          </ng-container>
        </tr>
      </table>

      <div class="divider-horizontal"></div>

      <h1>RFP Primary Contact</h1>
      <table class="info-table">
        <tr>
          <th>Name</th>
          <td>{{ rfpContact?.contactName?.toTitleCase() }}</td>
          
          <th>City</th>
          <td>{{ rfpContact?.cityName }}</td>
        </tr>

        <tr>
          <th>Address Line 1</th>
          <td>{{ rfpContact?.addressLine1?.toTitleCase() }}</td>
          
          <th>State</th>
          <td>{{ rfpContact?.stateCode }}</td>
        </tr>
        
        <tr>
          <th>Address Line 2</th>
          <td>{{ rfpContact?.addressLine2?.toTitleCase() }}</td>
          
          <th>Zip Code</th>
          <td>{{ rfpContact?.zipCode }}</td>
        </tr>

        <tr>
          <th>Email Address</th>
          <td>{{ rfpContact?.contactEmail }}</td>
          
          <th>Fax</th>
          <td>{{ rfpContact?.contactFaxNumber }}</td>
        </tr>
      </table>
    
    </div>
</div>