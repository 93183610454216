import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { XpoColors } from 'src/app/_models/xpo-colors';

export interface StatusIndicatorCellRendererParams extends ICellRendererParams {
  isLabel: boolean;
  statusColor: any; 
  statusText: any; 
  statusTooltipText: string;
}

@Component({
  selector: 'status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss']
})


export class StatusCellRendererComponent implements ICellRendererAngularComp {

  isLabel: boolean = false;                         // default - false 
  statusColor: string = XpoColors.XPO_TRANSPARENT;  // default - transparent 
  tooltipText: string = null;
  statusText: string = null;

  constructor() { }

  agInit(params: StatusIndicatorCellRendererParams): void {
    if(params.data){
      this.isLabel = params?.isLabel ;
  
      this.statusColor = params?.statusColor(params) ;
      this.statusText = params?.statusText(params);
      this.tooltipText = this.statusText;
  
      if(!this.isLabel) 
        this.statusText = null
    }
  }

  refresh(): boolean {
    return false;
  }
}
