import { ColDef, RowNode } from "ag-grid-community"
import { MetricFormat } from "../_models/enums"
import { UtilityService } from "../_services/utility.service"
import { CurrencyCellRendererComponent } from "./cell-renderers/currency-cell-renderer/currency-cell-renderer.component"
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';
import moment from 'moment';
import { BooleanCellRendererComponent } from "./cell-renderers/boolean-cell-renderer/boolean-cell-renderer.component"
import { XpoAgGridColumns } from "@xpo-ltl/ngx-ag-grid"
import { CheckboxCellRendererComponent, CheckboxCellRendererParams } from "./cell-renderers/checkbox-cell-renderer/checkbox-cell-renderer.component";
import { XpoStatusIndicatorCellRendererComponent, XpoStatusIndicatorCellRendererParams } from "./cell-renderers/xpo-status-indicator-cell-renderer/xpo-status-indicator-cell-renderer.component";

export class GridColumnPartialsHelper {

    static readonly ValueFormatterPercent: Partial<ColDef> = {
        valueFormatter: param => UtilityService.formatValue(param.value, MetricFormat.Percent),
        filterValueGetter: param => param.getValue(param.colDef.field) * 100
    }

    static readonly ValueFormatterFixed0: Partial<ColDef> = {
        valueFormatter: param => UtilityService.formatValue(param.value, MetricFormat.Fixed2)
    }

    static readonly ValueFormatterFixed2: Partial<ColDef> = {
        valueFormatter: param => UtilityService.formatValue(param.value, MetricFormat.Fixed2)
    }

    static readonly ValueFormatterValueOrDash: Partial<ColDef> = {
        valueFormatter: param => param.value ?? "-",
    }

    static readonly ValueFormatterLookupCodeNamePair: Partial<ColDef> = {
        valueFormatter: param => (param.value as LookupCodeNamePair)?.name ?? param.value
    }

    static readonly Editable: Partial<ColDef> = {
        editable: true,
        cellClass: (params) => params.colDef.editable ? 'grid-cell-editable' : ''
    };

    static readonly AlignRight: Partial<ColDef> = {
        cellClass: () => 'grid-cell-align-right'
    };

    static ContentAlignCenter: Partial<ColDef> = { 
        cellStyle: { placeItems: "center", placeContent: "end", display: "inline-flex" } 
    }
    
    static DollarFormat: Partial<ColDef>  = { 
        cellRendererFramework: CurrencyCellRendererComponent 
    }
    
    static MetricChange: Partial<ColDef>  = { 
        cellClassRules: {
            'metric-change--error': params => params.value < 0,
            'metric-change--info': params => params.value >= 0
        }
    }  

    static Status: Partial<ColDef> = { cellRenderer: (params) => `<xpo-status-indicator [statusText]="${params.value.statusText}" [statusColor]="${params.value.statusColor}" [isLabel]="${params.value.isLabel}" [matTooltip]="tooltipText" [ngClass]="{'statusIndicatorAlignment': true, 'nonLabelStatusInd' : !isLabel, 'labelStatusInd' : isLabel}"></xpo-status-indicator>`}

    static readonly XpoCustomBase: ColDef = {
        cellStyle: { "padding": 0 },
        pinned: 'left',
        lockPosition: true,
        sortable: false,
        suppressMenu: true,
        filter: false,
        editable: false,
        resizable: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        minWidth: 0,
        flex: 0,
        cellClass: "xpo-AgGrid-rowIndexColumn",
        headerClass: "xpo-AgGrid-rowIndexColumn",
    }

    static readonly XpoStatusColDef: ColDef = {
        ...GridColumnPartialsHelper.XpoCustomBase,
        width: 30,
        minWidth: 30,
        maxWidth: 30,
        cellStyle: { "justify-content": "center"}
    }

    static readonly XpoActionButtonColDef: ColDef = {
        ...GridColumnPartialsHelper.XpoCustomBase,
        width: 38,
        minWidth: 38,
        maxWidth: 38,
    }

    static readonly RowIndex: ColDef = {
        ...XpoAgGridColumns.RowIndex,
        ...GridColumnPartialsHelper.XpoCustomBase,
        cellStyle: { "justify-content": "center", "font-weight": "bold" }
    }

    static readonly Selection: ColDef = {
        ...XpoAgGridColumns.Selection,
        ...GridColumnPartialsHelper.XpoStatusColDef
    }

    static readonly SelectionWithSelectAll: ColDef = {
        ...XpoAgGridColumns.SelectionWithSelectAll,
        ...GridColumnPartialsHelper.XpoStatusColDef,
        headerCheckboxSelectionFilteredOnly: true
    }

    /** Helper function to resolve nested fields */
    private static resolveNestedField(obj: any, field: string): any {
        return field.split('.').reduce((o, key) => (o && o[key] !== 'undefined') ? o[key] : null, obj);
    }

    /**  Handles creation and filtering of a Boolean Cell Renderer
    * @param applyColorTrue - If `true`, color the check icon green. Default `false`.
    * @param applyBackgroundColorTrue - If `true`, color the cell background green. Default `false`.
    * @param applyColorFalse - If `true`, color the check icon red. Default `false`.
    * @param applyBackgroundColorFalse - If `true`, color the cell background red. Default `false`.
    * @returns A `Partial<ColDef>` containing settings to control a Boolean cell value.
    */
    static BooleanCell(
        applyColorTrue: boolean = false,
        applyBackgroundColorTrue: boolean = false,
        applyColorFalse: boolean = false,
        applyBackgroundColorFalse: boolean = false
    ): Partial<ColDef> {
        const getStyle = (applyColor: boolean, color: string, applyBackgroundColor: boolean, backgroundColor: string) => {
            const style: any = {};
            if (applyColor) {
                style.color = color;
            }
            if (applyBackgroundColor) {
                style.backgroundColor = backgroundColor;
            }
            return Object.keys(style).length ? style : null;
        };

    return {
        cellRendererFramework: BooleanCellRendererComponent,
        cellStyle: params => {
            if (params.value === 'True') {
                return getStyle(applyColorTrue, '#4CAF50', applyBackgroundColorTrue, '#F1F9F1');
            }
            if (params.value === 'False') {
                return getStyle(applyColorFalse, '#D32F2F', applyBackgroundColorFalse, '#FCEEEB');
            }
            return null;
        },
        filter: 'agSetColumnFilter',
        filterParams: { values: ["True", "False"] },
        flex: 0,
        valueGetter: params => {
            return GridColumnPartialsHelper.resolveNestedField(params.data, params.colDef.field) ? 'True' : 'False';
        }
    };
}

    /**  Handles display and filtering of a Date Cell Renderer. Pass in format to override default . 
    * 
    * Default Format: `"MM/DD/YY"`
    * 
    * Example DateTime format: `"MM/DD/YYYY hh:mm:ss A"` 
    * 
    * */
    static DateCell(format?: string): Partial<ColDef> {
        return {
            filter: 'agDateColumnFilter',
            valueFormatter: param => {
                if (!param.value) return "-"
                const date = moment(param.value)
                return date.isValid() ? date.format( format ? format : 'MM/DD/YY') : param.value 
            },
            valueGetter: params => {
                return GridColumnPartialsHelper.resolveNestedField(params.data, params.colDef.field) ? new Date(GridColumnPartialsHelper.resolveNestedField(params.data, params.colDef.field)) : null;
            }
        };
    }

    static CheckboxCell(
        onCheckboxChange: (newValue: boolean, node: RowNode) => void,
        enabledCallback?: (node: RowNode) => boolean,
    ): Partial<ColDef> {
        const cellRendererParams: Partial<CheckboxCellRendererParams> = {
            onCheckboxChange: onCheckboxChange,
            enabledCallback: enabledCallback
        }
        
        return {
            cellRendererParams: cellRendererParams,
            cellRendererFramework: CheckboxCellRendererComponent, 
            cellStyle: { "justify-content": "center"}, 
            flex: 0, 
            width: 115, 
            suppressAutoSize: true, 
            suppressSizeToFit: true 
        }
    }

    static CurrencyCell(displayDashIfNull: boolean = false): Partial<ColDef> {
        return { 
            cellRendererFramework: CurrencyCellRendererComponent,
            cellRendererParams: { 
                displayDashIfNull: displayDashIfNull,
            }
        }
    }

    static XpoStatusIndicator(
        statusColorCallback: (node: RowNode) => string,
        statusTextCallback?: (node: RowNode) => string,
        isLabelCallback?: (node: RowNode) => boolean,
        tooltip?: ((node: RowNode) => string) | string
    ): Partial<ColDef> {
        const cellRendererParams: Partial<XpoStatusIndicatorCellRendererParams> = {
            statusColorCallback: statusColorCallback,
            statusTextCallback: statusTextCallback,
            isLabelCallback: isLabelCallback,
            tooltip: tooltip,
        }

        return {
            cellRendererFramework: XpoStatusIndicatorCellRendererComponent,
            cellRendererParams: cellRendererParams,
        }
    }

    /**
    * Combines the cellClass definitions from multiple Partial<ColDef> objects.
    * @param colDefs Array of Partial<ColDef> objects.
    * @returns A single cellClass function that combines all definitions.
    */
    static combineCellClasses(...colDefs: Partial<ColDef>[]): (params: any) => string {
        return (params: any) => {
            let combinedClasses = [];

            for (const colDef of colDefs) {
                const cellClass = colDef.cellClass;
                if (typeof cellClass === 'function') {
                    const result = cellClass(params);
                    combinedClasses.push(...(Array.isArray(result) ? result : [result]));
                } else if (cellClass) {
                    combinedClasses.push(...(Array.isArray(cellClass) ? cellClass : [cellClass]));
                }
            }

            return combinedClasses.filter(Boolean).join(' ');
        };
    }
}