<button 
  *ngIf="showMenu"
  class="more-button"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  (menuClosed)="(true)">More <xpo-icon iconName="arrow-drop-down"></xpo-icon></button>
<mat-menu class="xpo-grid-toolbar-container--button-list-more"
  #menu="matMenu">
  <button *ngFor="let btn of dynButtons"
    (click)="btn.click()"
    [disabled]="btn.disabled">{{btn.name || btn.innerText}}</button>
    <div #projectedWrapper>
      <ng-content></ng-content>
    </div>
  </mat-menu>