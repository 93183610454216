import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export enum NewLookupFormFields {
  LookupName = "lookupName"
}

export interface NewLookupDialogResponse {
  lookupName: string
}

@Component({
  selector: 'new-lookup-dialog',
  templateUrl: './new-lookup-dialog.component.html',
  styleUrls: ['./new-lookup-dialog.component.scss']
})
export class NewLookupDialogComponent implements OnInit {

  newLookupForm: UntypedFormGroup;
  newLookupFormFields = NewLookupFormFields;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    public dialogRef: MatDialogRef<NewLookupDialogComponent, NewLookupDialogResponse>,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.newLookupForm = this.fb.group({
      [NewLookupFormFields.LookupName]:  [null, Validators.required],
    })
  }

  create(){
    this.dialogRef.close(this.newLookupForm.value)
  }
}