<mat-tab-group>
    <mat-tab label="Lookups">
        <admin-lookups></admin-lookups>
    </mat-tab>
    <mat-tab label="Premium Service Review Assignment">
        <admin-psae-assignment></admin-psae-assignment>
    </mat-tab>
    <mat-tab label="RFP Config">
        <admin-rfp-config></admin-rfp-config>
    </mat-tab>
</mat-tab-group>