import { Component, EventEmitter, Input, OnInit, QueryList, ViewChild, ViewChildren, ChangeDetectorRef, AfterContentChecked, Output, SimpleChanges } from '@angular/core';
import { ContextualHeaderActions, ContextualHeaderBackArrow, ContextualHeaderItems } from '@xpo-ltl/ngx-ltl-core/contextual-header';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { AccountOverview, RfpContact } from 'src/app/_models/temp/rfpAccount';
import { RfpDetails, RfpReview } from 'src/app/_models/temp/rfpDetails';
import { RfpMgmtApiService, RfpUser, UpdateRfpsStatusRqst, UpsertReviewSalesRqst } from '@xpo-ltl/sdk-rfpmgmt';
import { LookupService } from 'src/app/_services/lookup.service';
import { Observable, forkJoin, of } from 'rxjs';
import { UtilityService } from 'src/app/_services/utility.service';
import { MetricFormat } from 'src/app/_models/enums';
import { RfpDocumentsComponent } from '../components/forms/rfp-documents/rfp-documents.component';
import { OnSaveEvent, SaveEvent } from '../rfp-edit.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomerInfoDialogComponent } from '../components/dialogs/customer-info-dialog/customer-info-dialog.component';
import { CustomerInfoDialogInput } from '../components/dialogs/customer-info-dialog/customer-info-dialog-input';
import { XpoTileModel } from '@xpo-ltl/ngx-ltl-core/tile-ribbon';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { SubscriptionDialogInput } from '../components/dialogs/subscription-dialog/subscription-dialog-input';
import { SubscriptionDialogComponent } from '../components/dialogs/subscription-dialog/subscription-dialog.component';
import { UserService } from 'src/app/_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigSettings, FeatureFlags } from 'src/app/_models/configSettings';
import { SubmitToPricingDialogComponent, SubmitToPricingDialogResponse } from '../components/dialogs/submit-to-pricing-dialog/submit-to-pricing-dialog.component';
import { PremiumServiceCodes, PremiumServicesService, PremiumServicesStatus } from '../components/forms/rfp-premium-services/rfp-premium-services.service';
import { RfpService } from 'src/app/_services/rfp.service';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';

@Component({
  selector: 'rfp-contracted',
  templateUrl: './rfp-contracted.component.html',
  styleUrls: ['./rfp-contracted.component.scss']
})
export class RfpContractedComponent implements OnInit {
  @Input() rfpDetails: RfpDetails;
  @Input() rfpReviews: RfpReview[];
  @Input() accountOverview: AccountOverview;
  @Input() rfpContact: RfpContact;
  @ViewChild(RfpDocumentsComponent) documentComponent!: RfpDocumentsComponent;
  @ViewChildren("savable") saveableChildren!: QueryList<OnSaveEvent>;
  @Output() refreshRfpDetails = new EventEmitter<number>();

  currentUser: RfpUser;
  salesAgent: RfpUser;
  unsavedRfpDetails: any = {};
  selectedTabIndex: number = 0;

  savingRfpData: boolean = false;
  makingApiCall: boolean = false;
  userIsRds: boolean = false;

  featureFlags: FeatureFlags;
  requireRdsReviewEnabled: boolean = false;
  requireRdsReviewUserEnabled: boolean = false;
  requirePsaeReviewEnabled: boolean = false;
  enableReturnReasons: boolean = false;

  contextualHeaderItems: ContextualHeaderItems[] = [];
  actionsConfig: ContextualHeaderActions = {};
  tiles: XpoTileModel[] = [];
  premiumServicesStatus: PremiumServicesStatus;
  premiumServiceMadCodes: string[] = [];
  premiumServiceDisabled: boolean = true;

  constructor(
    private snackbar: XpoSnackBar,
    private rfpApi: RfpMgmtApiService,
    private lookup: LookupService,
    private configManager: ConfigManagerService,
    private dialog: MatDialog,
    private confirmDialog: XpoConfirmDialog,
    private userService: UserService,
    private route: ActivatedRoute,
    private premServ: PremiumServicesService,
    protected rfpService: RfpService,
  ) { }

  ngOnInit(): void {
    this.featureFlags = this.configManager.getSetting<FeatureFlags>(ConfigSettings.FeatureFlags);
    this.requireRdsReviewEnabled = this.featureFlags?.enableRdsReview ?? false;
    this.requireRdsReviewUserEnabled = this.featureFlags?.enableRdsReviewUser ?? false;
    this.enableReturnReasons = this.featureFlags?.enableReturnReasons ?? false;
    this.requirePsaeReviewEnabled = this.featureFlags?.enablePremiumServicesReview ?? false;
    this.premiumServiceMadCodes = this.configManager.getSetting<string[]>(ConfigSettings.PremiumServicesMadCodes) ?? [];

    this.userService.rfpUser$.subscribe(user => {
      this.currentUser = user;
      this.userIsRds = user.roleOptions.isRds;
      this.premiumServiceDisabled = this.isPremiumServicesDisabled(user)
    })

    this.rfpApi.getUser({employeeId: this.rfpDetails.salesAgentId}).subscribe(user => {
      this.salesAgent = user;
    })

    this.premServ.premiumServicesStatus$.subscribe(x => this.premiumServicesStatus = x);

    if (this.route.snapshot.queryParams.unsubscribe) {
      this.openSubscriptionsDialog(this.route.snapshot.queryParams.unsubscribe);
    }
    this.createContextualHeader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createContextualHeader()
    }
  }

  navigateToDocumentTab(docId: number) {
    this.selectedTabIndex = 6;
    this.documentComponent.filterDocById(docId);
  }

  getPremiumServiceTooltip() {
    if (!this.featureFlags?.enablePremiumServices) return "This feature is not released yet."
    else if (!(this.premiumServiceMadCodes.includes(this.rfpDetails.madCode) || this.currentUser?.applicationOptions?.enableMabd)) return "You do not have access to this feature."
    else if (this.rfpDetails.premiumServiceTypeCodes.length === 0) return "There are no Premium Services on the Opportunity. Return to OneCRM to add one."
    return null;
  }

  isPremiumServicesDisabled(rfpUser: RfpUser): boolean {
    // If enablePremiumServices is false, always return true (feature is disabled) 
    if (!this.featureFlags?.enablePremiumServices) {
      return true;
    }

    // If enablePremiumServices is true,  
    return !(this.premiumServiceMadCodes.includes(this.rfpDetails.madCode) || // Is the madcode in our list of valid madcodes
      rfpUser.applicationOptions.enableMabd) || // Or does the user have permissions to use the premium services
      this.rfpDetails.premiumServiceTypeCodes.length === 0; // Or does this RFP not have any premium services on it
  }

  createContextualHeader() {
    forkJoin([this.lookup.rfpTypes$, this.lookup.rfpStatuses$, this.lookup.threePLTypes$]).subscribe(response => {
      const rfpTypes = response[0];
      const rfpStatuses = response[1];
      const threePLTypes = response[2];

      this.contextualHeaderItems = [
        {
          labelTitle: 'RFP ID',
          title: `${this.rfpDetails.salesRfpId}`,
        },
        {
          labelTitle: 'RFP Type',
          title: rfpTypes.find(x => x.code.toLocaleLowerCase() === this.rfpDetails.rfpTypeCode.toLocaleLowerCase())?.name,
        },
        {
          labelTitle: 'Status',
          title: rfpStatuses.find(x => x.code.toLocaleLowerCase() === this.rfpDetails.rfpStatusCode.toLocaleLowerCase())?.name,
        },
        {
          labelTitle: 'Account',
          title: `${this.rfpDetails.madCode}`,
          action: {
            action: {
              icon: 'visibility',
              itemActionFn: this.openCustomerInfoDialog.bind(this),
            },
          },
        },
        {
          labelTitle: 'Requested Date',
          title: UtilityService.formatDate(this.rfpDetails.requestedDate),
        }
      ]

      if(this.rfpDetails.pricingAgreementId !== "-") {
        this.contextualHeaderItems.push(
          {
            labelTitle: 'Agreement',
            title: `${this.rfpDetails.pricingAgreementId}`,
          }
        )
      }

      if(this.rfpDetails.renegotiationDate) {
        this.contextualHeaderItems.push(
          {
            labelTitle: 'Renegotiation Date',
            title: UtilityService.formatDate(this.rfpDetails.renegotiationDate),
          }
        )
      }

      this.actionsConfig = {
        actionsList: [
          {
            name: 'RFP Notifications',
            icon: 'notifications',
            actionFn: this.openSubscriptionsDialog.bind(this),
          },
          {
            name: 'Refresh Rfp Details',
            icon: 'refresh',
            actionFn: this.callRefreshRfpDetails.bind(this),
          },
        ]
      }

      this.tiles = [
        {
          title: {
            text: this.rfpDetails.pricingType
          },
          label: {
            text: "Pricing Type"
          }
        },
      ]

      if (this.rfpDetails.rfpTypeCode.toLowerCase() !== "newopp") {
        this.tiles.push(
          {
            title: {
              text: this.rfpDetails.inCycleInd ? "In-Cycle" : "Out-of-Cycle"
            },
            label: {
              text: "RFP Cycle"
            }
          }
        )
      }

      this.tiles.push(...[
        {
          title: {
            text: this.accountOverview.creditStatus
          },
          label: {
            text: "Credit Status"
          }
        },
        {
          title: {
            text: threePLTypes.find(x => x.code === this.rfpDetails.thirdPartyTypeCode)?.name ?? "None"
          },
          label: {
            text: "3PL Type"
          }
        },
        {
          title: {
            text: this.rfpDetails.monthlyRevenuePotential ? UtilityService.formatValue(this.rfpDetails.monthlyRevenuePromise, MetricFormat.Dollar) : "-"
          },
          label: {
            text: "Monthly Promise Revenue"
          }
        },
        {
          title: {
            text: this.rfpDetails.monthlyRevenuePromise ? UtilityService.formatValue(this.rfpDetails.monthlyRevenuePotential, MetricFormat.Dollar) : "-"
          },
          label: {
            text: "Monthly Potential Revenue"
          }
        },
      ])
    })
  }

  callRefreshRfpDetails() {
    this.refreshRfpDetails.emit(this.rfpDetails.salesRfpId)
  }

  allFormsComplete() {
    const canSaveResp = this.saveableChildren?.toArray().map(child => child.getTabStatus());
    return canSaveResp?.every(x => x.complete ?? true) ?? false;
  }

  canAllSave() {
    if (!this.rfpService.isEditable()) return false;
    const canSaveResp = this.saveableChildren?.toArray().map(child => child.getTabStatus());
    return canSaveResp?.every(x => x.canSave ?? true) ?? false;
  }

  onChildSave(saveEvent: SaveEvent) {
    saveEvent.changes.forEach(change => {
      this.unsavedRfpDetails[change.key] = change.value;
    });
  }

  callSaveRfpData() {
    this.makingApiCall = true;
    this.saveRfpData().subscribe(saveSuccessful => {
      if (saveSuccessful) {
        this.snackbar.success("Save Successful")
      }
      else {
        this.snackbar.error("Something happened while saving, please try again")
      }
      this.makingApiCall = false;
    })
  }

  saveRfpData(): Observable<boolean> {
    const canSaveResp = this.saveableChildren.toArray().map(child => child.getTabStatus());

    if (this.canAllSave()) {
      const saveObservables = this.saveableChildren.map((child: OnSaveEvent) => child.onRfpSave());

      return forkJoin(saveObservables).pipe(
        tap((saveEvents: SaveEvent[]) => {

          saveEvents.forEach(saveEvent => {
            saveEvent.changes.forEach(change => {
              if (Array.isArray(change.value)) {
                // If the value is an array, concatenate it with the existing array (if it exists)
                this.unsavedRfpDetails[change.key] = this.unsavedRfpDetails.hasOwnProperty(change.key)
                  ? [...this.unsavedRfpDetails[change.key], ...change.value]
                  : change.value;
              } else {
                // If the value is not an array, simply set or override the value
                this.unsavedRfpDetails[change.key] = change.value;
              }
            });
          });
        }),
        tap(() => this.savingRfpData = true),
        switchMap(() => {
          const updatedRfp = new RfpDetails({ ...this.rfpDetails, ...this.unsavedRfpDetails });

          return this.rfpApi.upsertRfp(updatedRfp).pipe(
            map(() => {
              this.rfpDetails = updatedRfp;
              this.unsavedRfpDetails = {}

              this.saveableChildren.forEach((child: OnSaveEvent) => {
                child.onRfpSaveSuccess();
              });

              return true;
            }),
            catchError((error) => {
              return of(false);
            }),
            finalize(() => this.savingRfpData = false)
          )
        }),
      );
    }
    else {
      this.snackbar.open({
        message: 'Cannot Save, Please See Following Tabs:',
        detailedMessage: canSaveResp.filter(x => !(x.canSave ?? true)).map(x => x.tabName).join(", "),
        status: 'error',
        matConfig: {
          duration: 0,
        },
      })
      return of(false);
    }
  }

  validateShipmentFile() {
    switch (this.rfpDetails.rfpTypeCode) {
      // In-Cycle Renos do not require shipment files, if its out of cycle require normal logic
      case "Reneg":
        return this.rfpDetails.inCycleInd ? true : this.documentComponent.hasShipmentFile();
      // All Change Accessorials do not require shipment files
      case "ChangeAc":
        return true;
      default:
        return this.documentComponent.hasShipmentFile();
    }
  }

  canSubmitToPricing(): boolean {
    return this.allFormsComplete() &&
      this.rfpService.isEditable() &&
      this.validateShipmentFile() &&
      !this.makingApiCall &&
      this.accountOverview.creditStatus.toLowerCase() === "credit"
  }

  getSubmitToPricingError(): string {
    if (!this.allFormsComplete()) return "All forms must be complete before submitting"
    if (!this.rfpService.isEditable()) return "You cannot currently edit this RFP"
    if (!this.validateShipmentFile()) return "You must upload a 'Shipment File' type document to submit"
    if (this.makingApiCall) return "Currently submitting this RFP"
    if (this.accountOverview.creditStatus.toLowerCase() !== "credit") return "Customer does not have Credit Status of 'Credit'"
    return null
  }

  callSubmitToPricing(rdsApproved?: boolean) {
    if (this.enableReturnReasons) {
      this.dialog.open(SubmitToPricingDialogComponent, { position: { top: "10vh" }, data: this.rfpDetails }).afterClosed().subscribe((dialogResp: SubmitToPricingDialogResponse) => {
        if (dialogResp) {
          this.submitToPricing(rdsApproved, dialogResp.returnReason);
        }
      })
    }
    else {
      this.submitToPricing(rdsApproved);
    }
  }

  submitToPricing(rdsApproved?: boolean, returnReason: string = undefined) {
    this.makingApiCall = true;
    this.lookup.rfpStatuses$.subscribe((statuses) => {
      const submittedStatus = statuses.find(t => t.code.toLocaleLowerCase() === "submitted")?.code;

      const updateStatus = () => {
        let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
          statusCode: submittedStatus,
          reasonCode: returnReason,
          rfpIds: [this.rfpDetails.salesRfpId],
          markRdsApproved: rdsApproved ?? undefined,
        };

        this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
          () => {
            this.snackbar.success("Successfully submitted RFP to Pricing");
          },
          (error) => {
            this.snackbar.error("Something happened while submitting to pricing, please try again");
          },
          () => {
            this.callRefreshRfpDetails();
          }
        ).add(() => {
          this.makingApiCall = false;
        });
      };

      if (rdsApproved === undefined) {
        this.saveRfpData().subscribe((saveSuccessful: boolean) => {
          if (!saveSuccessful) {
            this.makingApiCall = false;
            return;
          }
          updateStatus();
        });
      } else {
        updateStatus();
      }
    });
  }

  /** Returns true if an RFP should go through the PSAE review process */
  requirePsaeReview(): boolean {
    return this.requirePsaeReviewEnabled &&
      (
        this.rfpDetails.premiumServiceTypeCodes.find(x => (x === PremiumServiceCodes.MABD || x === PremiumServiceCodes.Mexico))
        // || this.rfpDetails.accessorials.some(x => x.accessorialCode === "MBD" || x.accessorialCode === "MBS")  // Don't exactly remember what use case this was covering, but I think as long as the RFP doesn't have it selected it can bypass psae review
      ) &&
      !this.rfpReviews.some(x => x.isApprovedPsaeReview())
  }

  showPsaeActions(): boolean {
    const inPsaeReview = this.rfpDetails.rfpStatusCode == "InPsaeReview"
    const activePsaeReview = this.rfpReviews.find(x => x.isOpenPsaeReview())

    if (inPsaeReview && activePsaeReview) {
      return this.currentUser.roleOptions.premiumServiceAE || this.currentUser.roleOptions.isAdmin;
    }

    return false;
  }

  saveAndSubmitToPsaeReview() {
    this.makingApiCall = true;
    this.saveRfpData().subscribe((saveSuccessful: boolean) => {
      if (!saveSuccessful) {
        this.makingApiCall = false;
        return;
      }
      this.submitToPsaeReview();
    })
  }

  submitToPsaeReview() {
    this.confirmDialog.confirm(
      `This RFP has a Premium Service selected and must be reviewed before it can be sent to pricing.
      </br></br>
      Submit to a Premium Service Account Executive for review?`,
      `Premium Service Review`,
      { showCancelButton: true }
    ).subscribe(confirm => {
      if (confirm) {
        this.makingApiCall = true;

        const request: UpsertReviewSalesRqst = {
          rfpId: this.rfpDetails.salesRfpId,
          reviewType: "PSAEREVIEW",
          reviewStatusCode: "Submitted",
        }

        this.rfpApi.upsertReviewSales(request).subscribe({
          next: (resp) => {
            this.snackbar.success("Successfuly submitted RFP to PSAE Review")
          },
          error: (err) => {
            this.snackbar.error("Something happened while submitting to PSAE Review, please try again")
          }
        }).add(() => {
          this.makingApiCall = false;
          this.callRefreshRfpDetails();
        })
      }
    })
  }

  updateRfpReview(reviewType: string, reviewStatusCode) {
    const request: UpsertReviewSalesRqst = {
      rfpId: this.rfpDetails.salesRfpId,
      reviewType: reviewType,
      reviewStatusCode: reviewStatusCode,
    }

    this.rfpApi.upsertReviewSales(request).subscribe({
      next: (resp) => {
        this.snackbar.success("Successfuly updated review status")
      },
      error: (err) => {
        this.snackbar.error("Something happened while updating the review status, please try again")
      }
    }).add(() => {
      this.makingApiCall = false;
      this.callRefreshRfpDetails();
    })
  }

  /** Returns true if an RFP should go through the RDS review process */
  requireRdsReview(): boolean {
    return this.requireRdsReviewEnabled &&
      !this.rfpReviews.some(x => x.isApprovedRdsReview()) &&
      (this.rfpDetails.pricingType.toLowerCase() === "local") &&
      (this.requireRdsReviewUserEnabled ? this.salesAgent?.applicationOptions.requireRdsReview : true);
  }

  showRdsActions(): boolean {
    const inRdsReview = this.rfpDetails.rfpStatusCode == "InRdsReview"
    const activeRdsReview = this.rfpReviews.find(x => x.isOpenRdsReview())

    if (inRdsReview && activeRdsReview) {
      return this.currentUser.roleOptions.isRds || this.currentUser.roleOptions.isAdmin
    }

    return false;
  }

  returnToSales() {
    this.makingApiCall = true;
    this.lookup.rfpStatuses$.subscribe((response) => {
      var inSalesStatus = response.find(t => t.code.toLocaleLowerCase().localeCompare("InSales".toLocaleLowerCase()) === 0)?.code;

      let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
        statusCode: inSalesStatus,
        rfpIds: [this.rfpDetails.salesRfpId]
      };

      this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
        () => {
          this.snackbar.success("Successfuly returned RFP to Sales Agent")
        },
        (error) => {
          this.snackbar.error("Something happened while returning the RFP, please try again")
        },
        () => {
          this.callRefreshRfpDetails();
        }
      ).add(() => {
        this.makingApiCall = false;
      })

    });
  }

  submitToRdsReview() {
    this.confirmDialog.confirm(
      `This RFP must be reviewed before it can be sent to pricing.
      </br></br>
      Submit to a Regional Director of Sales for review?`,
      `Regional Director of Sales Review`,
      { showCancelButton: true }
    ).subscribe(confirm => {
      if (confirm) {
        this.makingApiCall = true;
        this.saveRfpData().subscribe((saveSuccessful: boolean) => {
          if (!saveSuccessful) {
            this.makingApiCall = false;
            return;
          }

          const request: UpsertReviewSalesRqst = {
            rfpId: this.rfpDetails.salesRfpId,
            reviewType: "RDSREVIEW",
            reviewStatusCode: "Submitted",
          }

          this.rfpApi.upsertReviewSales(request).subscribe({
            next: (resp) => {
              this.snackbar.success("Successfuly submitted RFP to RDS Review")
            },
            error: (err) => {
              this.snackbar.error("Something happened while submitting to RDS Review, please try again")
            }
          }).add(() => {
            this.makingApiCall = false;
            this.callRefreshRfpDetails();
          })
        })
      }
    })


    // this.makingApiCall = true;
    // this.lookup.rfpStatuses$.subscribe((response) => {
    //   var inRdsReviewStatus = response.find(t => t.code.toLocaleLowerCase().localeCompare("InRdsReview".toLocaleLowerCase()) === 0)?.code;

    //   this.saveRfpData().subscribe((saveSuccessful: boolean) => {
    //     if (!saveSuccessful) {
    //       this.makingApiCall = false;
    //       return;
    //     }

    //     let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
    //       statusCode: inRdsReviewStatus,
    //       rfpIds: [this.rfpDetails.salesRfpId]
    //     };

    //     this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
    //       () => {
    //         this.snackbar.success("Successfuly submitted RFP to RDS")
    //       },
    //       (error) => {
    //         this.snackbar.error("Something happened while submitting to RDS, please try again")
    //       },
    //       () => {
    //         this.callRefreshRfpDetails();
    //       }
    //     ).add(() => {
    //       this.makingApiCall = false;
    //     })
    //   })
    // });
  }

  showReOpenButton() {
    return this.rfpDetails.rfpStatusCode.toLowerCase() === "close" || this.rfpDetails.rfpStatusCode.toLowerCase() === "cancel"
  }

  reOpenRfp() {
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Re-Open RFP',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };

    this.confirmDialog.confirm(
      '',
      'Are you sure you want to Re-Open this RFP?',
      confirmConfig
    ).subscribe((result) => {
      if (result) {
        this.makingApiCall = true;
        this.lookup.rfpStatuses$.subscribe((response) => {
          var closeStatusCd = response.find(status => status.code.toLocaleLowerCase() === "InSales".toLocaleLowerCase())?.code;

          let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
            statusCode: closeStatusCd,
            rfpIds: [this.rfpDetails.salesRfpId]
          };

          this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
            () => {
              this.snackbar.success("Successfuly Re-Opened RFP")
            },
            (error) => {
              this.snackbar.error("Something happened while trying to re-open this RFP, please try again")
            },
            () => {
              this.callRefreshRfpDetails();
            }
          ).add(() => {
            this.makingApiCall = false;
          })
        });
      }
    });
  }

  getCloseRfpTooltip(): string | null {
    if(this.rfpDetails.isPricingCreated()) {
      return "You cannot close RFPs created by Pricing"
    }
    else if(this.rfpDetails.isInCycleReno()) {
      return "You cannot close In-Cycle Reno RFPs. Submit a Cancel Pricing RFP if you would like to cancel this customers current pricing."
    }
    else {
      return "Close RFP";
    }
  }

  closeRfp() {
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Close RFP',
      rejectButtonText: 'Cancel',
      icon: 'warning',
    };

    this.confirmDialog.confirm(
      '',
      'Are you sure you want to close this RFP?',
      confirmConfig
    ).subscribe((result) => {
      if (result) {
        this.makingApiCall = true;
        this.lookup.rfpStatuses$.subscribe((response) => {
          var closeStatusCd = response.find(status => status.code.toLocaleLowerCase() === "close".toLocaleLowerCase())?.code;

          let rfpUpdateStatusRequest: Partial<UpdateRfpsStatusRqst> = {
            statusCode: closeStatusCd,
            rfpIds: [this.rfpDetails.salesRfpId]
          };

          this.rfpApi.updateRfpsStatus(rfpUpdateStatusRequest as UpdateRfpsStatusRqst).subscribe(
            () => {
              this.snackbar.success("Successfuly Closed RFP")
            },
            (error) => {
              this.snackbar.error("Something happened while trying to close this RFP, please try again")
            },
            () => {
              this.callRefreshRfpDetails();
            }
          ).add(() => {
            this.makingApiCall = false;
          })
        });

      }
    });
  }


  openCustomerInfoDialog() {
    const dialogData: CustomerInfoDialogInput = {
      accountOverview: this.accountOverview,
      rfpDetails: this.rfpDetails,
      rfpContact: this.rfpContact,
      reviews: this.rfpReviews,
    }

    this.dialog.open(CustomerInfoDialogComponent, { data: dialogData, position: { top: "10vh" } });
  }

  openSubscriptionsDialog(unsubscribeCode?: string) {
    this.userService.authUser$.subscribe(user => {
      const dialogData: SubscriptionDialogInput = {
        salesRfpId: this.rfpDetails.salesRfpId,
        userEmailAddress: user.emailAddress,
        unsubscribeCode: unsubscribeCode ?? undefined
      }

      this.dialog.open(SubscriptionDialogComponent, { data: dialogData, position: { top: "10vh" } });
    })
  }
}