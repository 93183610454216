import { Pipe, PipeTransform } from '@angular/core';

export interface TooltilListPipeData {
    header?: string,
    lines: string[]
}

@Pipe({ name: 'tooltipList' })
export class TooltipListPipe implements PipeTransform {

  transform(input: TooltilListPipeData): string {

    let list: string = input.header ? `${input.header}\n` : '';

    input.lines.forEach(line => {
      list += '• ' + line + '\n'; 
    });

    return list;
  }
}