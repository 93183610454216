<div *ngIf="fakFormArray">
    <div *ngIf="fakFormArray.invalid">
        <mat-error *ngIf="fakFormArray.errors?.multipleClassOverload" style="align-items: center; display: flex;"><xpo-icon iconName="error" style="margin-right:4px"></xpo-icon>Class cannot have multiple overrides</mat-error>
        <mat-error *ngIf="fakFormArray.errors?.rangeIssue" style="align-items: center; display: flex;"><xpo-icon iconName="error" style="margin-right:4px"></xpo-icon>"From Class" must be less than "To Class"</mat-error>
    </div>

    <div class="fakContainer">
        <div>   
            <span class="headerContainer">
                <h2>FAK Overrides</h2>   
                <a xpo-regular-link (click)="addFakOverride()" [disabled]="fakFormArray.disabled"><xpo-icon iconName="add-alt"></xpo-icon><span>Add Override</span></a>
            </span>  


            <table class="input-table">
                <tr>
                    <th>FAK Class Override</th>
                    <th>From Class</th>
                    <th>Through Class</th>
                    <th style="text-align: center">Actions</th>
                </tr>
                <tr *ngFor="let control of getArrayControls(); let i=index" [formGroup]="control">
                    <td>
                        <mat-form-field appearance="standard">
                            <mat-label></mat-label>
                            <mat-select [formControlName]="formControls.FakClass" xpoSelect>
                                <mat-option *ngFor="let classOverride of lookup.fakClasses$ | async" [value]="classOverride"> {{classOverride.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="standard">
                            <mat-label></mat-label>
                            <mat-select [formControlName]="formControls.FromClass" xpoSelect>
                                <mat-option *ngFor="let classFrom of lookup.fakClasses$ | async" [value]="classFrom"> {{classFrom.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field appearance="standard" class="fakField">
                            <mat-select [formControlName]="formControls.ThroughClass" xpoSelect>
                                <mat-option *ngFor="let classThrough of lookup.fakClasses$ | async" [value]="classThrough"> {{classThrough.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="action-button-container">
                        <button xpo-action-button [disabled]="fakFormArray.disabled" (click)="resetFakOverride(i)"  matTooltip="Reset Fields"><xpo-icon iconName="loop"></xpo-icon></button>
                        <button xpo-action-button [disabled]="fakFormArray.disabled || fakFormArray.controls.length == 1"  (click)="removeFakOverride(i)" matTooltip="Delete Fields"><xpo-icon iconName="delete"></xpo-icon></button>
            
                    </td>
                </tr>
            </table>            
        </div>

        <mat-table class="fill-container" [dataSource]="getDataSource(i * 6, (i * 6) + 6)" *ngFor="let l of [].constructor(3); let i=index">
            <!-- Position Column -->
            <ng-container matColumnDef="originalFak">
                <mat-header-cell *matHeaderCellDef> FAK </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.originalFak }} </mat-cell>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="newFak">
                <mat-header-cell *matHeaderCellDef> New Value </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.newFak }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: white;"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>
