import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoErrorPagesType } from '@xpo-ltl/ngx-ltl-core/error-pages';
import { ConfigSettings } from 'src/app/_models/configSettings';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'user-not-found',
  templateUrl: './user-not-found.component.html',
  styleUrls: ['./user-not-found.component.scss']
})
export class UserNotFoundComponent implements OnInit {
  appVersion = "local development"
  errorPagesType = XpoErrorPagesType;
  userMissingValidated = false;

  constructor(
    private userService: UserService, 
    private router: Router,
    private configManagerService: ConfigManagerService,
  ) {
    this.appVersion = this.configManagerService.getSetting<string>(ConfigSettings.BuildVersion)

    this.userService.rfpUser$.subscribe({
      next: (user) => {
        if(user) this.router.navigateByUrl("/dashboard")
        this.userMissingValidated = true;
      },
      error: err => {
        this.userMissingValidated = true;
      }
    })
  }

  ngOnInit(): void { }
}
