<div class="baseContainer">

    <xpo-ag-grid>
        <xpo-grid-toolbar [gridOptions]="gridOptions">    
            <xpo-grid-toolbar-actions-group>
                <button mat-button 
                    matTooltip="Edit selected user"
                    matTooltipPosition="above"
                    (click)="openEditUserDialog()"
                    [disabled]="selectedUsers?.length == 0 || gridLoadingData">
                    <xpo-icon xpoIconSmall iconName="edit"></xpo-icon> Edit User
                </button>
            </xpo-grid-toolbar-actions-group>

            <xpo-grid-toolbar-settings-group>
                <span>
                    <button 
                        mat-icon-button
                        (click)="refreshGridData()"
                        matTooltip="Refresh grid data" 
                        [disabled]="gridLoadingData">
                        <xpo-icon iconName="refresh" *ngIf="!gridLoadingData; else stillDownloading"></xpo-icon> 
                    
                        <ng-template #stillDownloading>
                            <xpo-busy-loader></xpo-busy-loader>

                        </ng-template>
                    </button>
                </span>

                <span>
                    <button 
                        mat-icon-button
                        (click)="clearFilters()"
                        matTooltip="Clear filters" 
                        [disabled]="!filtersExist()">
                        <xpo-icon iconName="filter-toolbar"></xpo-icon> 
                    </button>
                </span>
            </xpo-grid-toolbar-settings-group>
        </xpo-grid-toolbar>
        <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </xpo-ag-grid>
</div>