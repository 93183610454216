<form class="field-container" [formGroup]="rfpPremiumServiceForm">
    <mat-tab-group xpoAlternateTabs [selectedIndex]="defaultTabIndex">
        <mat-tab [disabled]="!hasPremiumService(premiumServiceCodes.MABD)">
            <ng-template mat-tab-label>
                <span [matTooltip]="getPremiumServiceTooltip(premiumServiceCodes.MABD)">Must Arrive By Date</span>
                <ng-template *ngIf="premiumServiceHasError(premiumServiceCodes.MABD)" [ngTemplateOutlet]="tabInvalid"></ng-template>
            </ng-template>

            <div class="tab-container">
                <rfp-mabd #savable [rfpDetails]="rfpDetails" [rfpPremiumServiceForm]="rfpPremiumServiceForm"></rfp-mabd>
            </div>
        </mat-tab>
        <mat-tab [disabled]="!hasPremiumService(premiumServiceCodes.Mexico)">
            <ng-template mat-tab-label>
                <span [matTooltip]="getPremiumServiceTooltip(premiumServiceCodes.Mexico)">Mexico</span>
                <ng-template *ngIf="premiumServiceHasError(premiumServiceCodes.Mexico)" [ngTemplateOutlet]="tabInvalid"></ng-template>
            </ng-template>

            <div class="tab-container">
                <rfp-mexico #savable [rfpDetails]="rfpDetails" [rfpPremiumServiceForm]="rfpPremiumServiceForm"></rfp-mexico>
            </div>
        </mat-tab>
    </mat-tab-group>
</form>

<ng-template #tabInvalid>
    <xpo-icon iconName="priority-high" class="tab-error"></xpo-icon>
</ng-template>