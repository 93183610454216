import { RfpMgmtRfpAmcOverride } from "@xpo-ltl/sdk-rfpmgmt";

export class RfpAmcOverride extends RfpMgmtRfpAmcOverride {

    constructor(response: RfpMgmtRfpAmcOverride){
        super();
        Object.assign(this, response);
    }

     /**
     * The ID generated by concatenating code and display.
     */
     get id(): string {
        if(this.typeCode === "MILES") return `${this.typeCode}-${this.minimumMiles}-${this.maximumMiles}`;
        else return `${this.typeCode}-${this.typeCode}`
    }
}