import { Injectable } from '@angular/core';
import { RfpFieldData, RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RfpConfigService {
  readonly configFields$: Observable<RfpFieldData[]>;

  constructor(private rfpApi: RfpMgmtApiService) {
    
    this.configFields$ = this.rfpApi.getRfpFieldData().pipe(map(response => response.items), shareReplay())
  }
}