<div xpoDialog>
    <h1 mat-dialog-title>
      Add New Lookup
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content>
        <form [formGroup]="newLookupForm">
            <mat-form-field floatLabel="always">
              <mat-label>Lookup Code</mat-label>
              <input matInput [formControlName]="newLookupFormFields.LookupName" required />
            </mat-form-field>
        </form>
    </div>
  
    <div mat-dialog-actions>
        <xpo-button-group></xpo-button-group>
        
        <xpo-button-group>    
            <button mat-flat-button xpoButton [disabled]="newLookupForm.invalid" (click)="create()">Create</button>
        </xpo-button-group>
    </div>
  </div>