import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { forkJoin, throwError } from 'rxjs';
import { catchError, finalize, last} from 'rxjs/operators';
import { UploadDocumentsDialogInput } from './upload-documents-dialog-input';
import { UploadDocumentsDialogResponse } from './upload-documents-dialog-response';
import { LookupService } from 'src/app/_services/lookup.service';
import { RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigSettings } from 'src/app/_models/configSettings';
import { LookupCodeNamePair } from 'src/app/_models/temp/lookupCodeValuePair';

export enum DocumentUploadFormFields {
  Document = "document",
  DocumentType = "documentType",
  CommentArea = "commentArea",
}

export class RfpFile extends File {
  fileComment: string;
  fileTypeCode: string;
  fileUploadSuccess: boolean = false;
  fileUploadFailure: boolean = false;
  fileUploadError: string;

  constructor(file: File, fileComment: string = null, fileTypeCode: string = null) {
    super([file], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    });
    this.fileComment = fileComment;
    this.fileTypeCode = fileTypeCode;
  }
}

@Component({
  selector: 'upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  styleUrls: ['./upload-documents-dialog.component.scss']
})
export class UploadDocumentsDialogComponent implements OnInit {

  selectedFiles: RfpFile[] = [];

  uploading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: UploadDocumentsDialogInput, public dialogRef: MatDialogRef<UploadDocumentsDialogComponent, UploadDocumentsDialogResponse>, private snackBar: XpoSnackBar, public lookup: LookupService, private rfpApi: RfpMgmtApiService, private config: ConfigManagerService) {
  }

  ngOnInit(): void { }

  canUpload(): boolean {
    return this.selectedFiles.length > 0 && 
      this.selectedFiles.every((rfpFile: RfpFile) => 
        rfpFile.fileTypeCode !== null && // Has a file type
        !rfpFile.fileUploadSuccess // Hasn't already been uploaded
      )
  }

  close(): void {
    this.dialogRef.close();
  }

  deleteFile(index: number){
    this.selectedFiles.splice(index, 1)
  }

  getOptionTooltip(docType: LookupCodeNamePair){
    if(this.disableDocType(docType)){
      return "This is a Pricing-only Document Type"
    }
    return null;
  }

  disableDocType(docType: LookupCodeNamePair){
    switch(docType.code){
      case "Doc for Signature":
      case "Rulesets":
        return true
      default: 
        return false;
    }
  }

  getUploadDisabledTooltip(){
    if(this.selectedFiles.length === 0) return "Please select files to upload"
    if(this.uploading) return "Please wait for upload to finish"
    if(this.selectedFiles.every((rfpFile: RfpFile) => rfpFile.fileTypeCode === null)) return "Please assign a File Type to every file."
    if(this.selectedFiles.every((rfpFile: RfpFile) => rfpFile.fileUploadSuccess)) return "Please remove successfully uploaded files from file list before uploadng more."
    return null
  }

  canEditFile(rfpFile: RfpFile){
    return !rfpFile.fileUploadSuccess && !this.uploading
  }

  uploadSelectedFiles(){
    this.uploading = true;

    const fileUploads = this.selectedFiles.map(file => {
      return this.uploadDocument(file).pipe(
        catchError(error => {
          file.fileUploadFailure = true;
          if(error){
            var jsonErrorMessage = error?.error?.message;
            if(jsonErrorMessage){
              file.fileUploadError = jsonErrorMessage.replace(/-/g, '');
            }
          }
          return throwError(error);
        }),
        finalize(() => {
          file.fileUploadSuccess = !file.fileUploadFailure;
        })
      );
    })

    forkJoin(fileUploads).pipe(last()).subscribe(
      () => {
        this.uploading = false;
        this.snackBar.success("Files Successfully Uploaded")
      },
      (error) => {
        this.uploading = false;
        this.snackBar.error("At least one file encountered an upload error, please try again.")
      }
    );
  }

  uploadDocument(file: RfpFile){
    const request = {
      file: file,
      rfpId: this.data.rfpDetails.salesRfpId,
      description: file.fileComment ?? undefined,
      documentTypeCode: file.fileTypeCode ?? undefined,
    }

    return this.rfpApi.uploadDocument(request as any)
  }

  onFileSelected(event) {
    const filesSelected: FileList = event.target.files;

    for(let i = 0; i < filesSelected.length; i++){      
      this.selectedFiles.push(new RfpFile(filesSelected.item(i)))
    };
  }
}