import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'boolean-cell-renderer',
  templateUrl: './boolean-cell-renderer.component.html',
  styleUrls: ['./boolean-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanCellRendererComponent implements ICellRendererAngularComp {
  value: boolean;
  iconName: string = "";

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams): void {
    this.updateValue(params.value);
  }

  refresh(params: ICellRendererParams): boolean {
    let newValue = this.checkForTrue(params.value);
    if (newValue !== this.value) {
      this.updateValue(params.value);
      this.changeDetectorRef.markForCheck();
      return true;
    }
    return false;
  }

  updateValue(value: any): void {
    this.value = this.checkForTrue(value);
    this.iconName = this.value ? "checkmark" : "close";
  }

  checkForTrue(value: any): boolean {
    if (typeof value === 'boolean') {
      return value;
    }
    let stringValue = value.toString().toLowerCase();
    return stringValue === "true" || 
      stringValue === "1" || 
      stringValue === "on" || 
      stringValue === "yes" ||
      stringValue === "y" ||
      stringValue === "enabled" ||
      stringValue === "active";
  }
}