<div class="field-container">

    <xpo-ag-grid>
        <xpo-grid-toolbar [gridOptions]="gridOptions">    
            <xpo-grid-toolbar-actions-group>
                <button mat-button 
                    matTooltip="Upload a new document"
                    matTooltipPosition="above"
                    (click)="openFileUploadDialog()">
                    <xpo-icon xpoIconSmall svgIconName="add-alt"></xpo-icon> New Document
                </button>
            </xpo-grid-toolbar-actions-group>

            <xpo-grid-toolbar-settings-group>
                <button mat-icon-button
                    matTooltip="Clear All Filters" 
                    matTooltipPosition="above"
                    (click)="clearAllGridFilters()"
                    [disabled]="!anyFiltersActive()">
                    <xpo-icon xpoIconSmall svgIconName="filter-toolbar-outline"></xpo-icon>
                </button>

                <button mat-button
                    matTooltip="Open PL Docs"
                    matTooltipPosition="above"
                    (click)="openPlDocs()">
                    <xpo-icon xpoIconSmall svgIconName="open-in-new"></xpo-icon> PL Docs
                </button>

            </xpo-grid-toolbar-settings-group>
        </xpo-grid-toolbar>

        <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </xpo-ag-grid>
</div>