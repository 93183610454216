import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RfpMgmtApiService } from '@xpo-ltl/sdk-rfpmgmt';
import moment from 'moment';
import { RfpMessageDetails } from 'src/app/_models/temp/rfpMessageDetails';
import { LookupService } from 'src/app/_services/lookup.service';
import { UserService } from 'src/app/_services/user.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'rfp-message',
  templateUrl: './rfp-message.component.html',
  styleUrls: ['./rfp-message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() message: RfpMessageDetails;
  @Output() onDocumentSelected = new EventEmitter<number>();
  @Output() onDeleteMessage = new EventEmitter<RfpMessageDetails>();
  @Output() onEditMessage = new EventEmitter<RfpMessageDetails>();

  profilePictureUrl: SafeUrl;
  utilityService = UtilityService;

  constructor(public lookup: LookupService, public userService: UserService, public utility: UtilityService) { }

  ngOnInit(): void {
    this.userService.getProfilePicture(this.message.authorEmail).subscribe((safeUrl: SafeUrl) => {
      this.profilePictureUrl = safeUrl;
    });
  }

  editMessage(){
    this.onEditMessage.emit(this.message)
  }

  deleteMessage(){
    this.onDeleteMessage.emit(this.message)
  }

  viewDocument(){
    this.onDocumentSelected.emit(this.message.fileId)
  } 

  formatTimestamp(timestamp: string): string {
    return moment.utc(timestamp).local().format('MMM D, YYYY, h:mm A')
    return moment.utc(timestamp).local().format('MM/DD/YYYY hh:mm:ss A')
  }
}
