<div xpoDialog>
  <h1 mat-dialog-title>
    {{data.header}}
    <xpo-dialog-close-button *ngIf="!uploading"></xpo-dialog-close-button>
  </h1>
  
  <div mat-dialog-content >
  
    <div *ngIf="selectedFiles.length > 0; else noFilesSelected">

      <div *ngFor="let file of selectedFiles; let i = index" class="rfp-file">
        <div class="file-actions">
          <button xpo-action-button (click)="deleteFile(i)" [disabled]="uploading"><xpo-icon iconName="delete"></xpo-icon></button>
        </div>
        <div class="file-info">
          <div class="file-name">
            <xpo-icon iconName="checkmark-alt" *ngIf="file.fileUploadSuccess" class="success"></xpo-icon>
            <xpo-icon iconName="error" *ngIf="file.fileUploadFailure" class="error" [matTooltip]="file.fileUploadError"></xpo-icon>
            {{ file.name }}
          </div>
          <mat-select placeholder="Select document type" xpoSelect required class="file-type" [(ngModel)]="file.fileTypeCode" [disabled]="!canEditFile(file)">
            <mat-option *ngFor="let doc of lookup.documentTypes$ | async" [value]="doc.code" [disabled]="disableDocType(doc)" [matTooltip]="getOptionTooltip(doc)" matTooltipPosition="before"> {{ doc.name }} </mat-option>
          </mat-select>
        </div>

        <div class="comment-entry-container">
          <textarea matInput placeholder="File Comments" [(ngModel)]="file.fileComment" [disabled]="!canEditFile(file)"></textarea>
        </div>

      </div>

    </div>

  </div>

  <div mat-dialog-actions>
    <xpo-button-group>
      <button mat-stroked-button xpoButton (click)="close()" [disabled]="uploading">Close</button>
    </xpo-button-group>
    <xpo-button-group class="button-container">
      <input type="file" multiple style="display: none;" (change)="onFileSelected($event)" #fileUpload>
      <button mat-flat-button xpoButton (click)="fileUpload.click()" [disabled]="uploading">Browse For Files</button>
      <span [matTooltip]="getUploadDisabledTooltip()">
        <button mat-flat-button xpoButton class="upload-button" (click)="uploadSelectedFiles()" [disabled]="!canUpload() || uploading"><xpo-busy-loader *ngIf="uploading" style="height: 20px; width: 20px"></xpo-busy-loader>Upload Files</button>
      </span>
    </xpo-button-group>
  </div>

</div>

<ng-template #noFilesSelected>
  <xpo-empty-state>
    <xpo-empty-state-icon>
      <mat-icon>folder_open</mat-icon>
    </xpo-empty-state-icon>
    <xpo-empty-state-header>No Files Selected</xpo-empty-state-header>
    <xpo-empty-state-description>Select at least one file using the "Browse for Files" button below</xpo-empty-state-description>
  </xpo-empty-state>
</ng-template>