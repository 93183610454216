import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { RfpUser } from '@xpo-ltl/sdk-rfpmgmt';
import { RfpDetails } from '../_models/temp/rfpDetails';

@Injectable({
  providedIn: 'root'
})
export class RfpService {
  private rfpUserSubject: BehaviorSubject<RfpUser> = new BehaviorSubject<RfpUser>(null);
  private rfpDetailsSubject: BehaviorSubject<RfpDetails> = new BehaviorSubject<RfpDetails>(null);

  constructor(
    private userService: UserService,
  ) {
    this.userService.rfpUser$.subscribe(user => {
      this.rfpUserSubject.next(user);
    });
  }

  /** Observable for rfpDetails */
  get rfpDetails$(): Observable<RfpDetails> {
    return this.rfpDetailsSubject.asObservable();
  }

  /** Set the current rfpDetails */
  setRfpDetails(rfpDetails: RfpDetails): void {
    this.rfpDetailsSubject.next(rfpDetails);
  }

  /** Get the current RfpDetails object */
  getRfpDetails(): RfpDetails {
    return this.rfpDetailsSubject.value;
  }

  /** Check if the current rfpDetails is editable */
  isEditable(): boolean {
    const rfpUser = this.rfpUserSubject.value;
    const rfpDetails = this.getRfpDetails();
    if (!rfpUser || !rfpDetails) {
      return false;
    }
    const cannotModifyRfp = rfpUser.applicationOptions.cannotModifyRfp;
    const isThreePlNationalRfp = rfpDetails.isThirdPartyLogistics && rfpDetails.pricingType.toLowerCase() === 'national';
    // RFP-177 if this is a 3PL National RFP allow all the users (NAE/EAE/3PL NAEs) to modify the RFP
    return this.isRfpStatusEditable(rfpDetails.rfpStatusCode) && (!cannotModifyRfp || isThreePlNationalRfp);
  }

  /** Utility method to check if an RFP status is editable */
  private isRfpStatusEditable(rfpStatusCode: string): boolean {
    const editableStatusCodes = ["New", "InSales"];
    return editableStatusCodes.includes(rfpStatusCode);
  }
}
