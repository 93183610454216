<div xpoDialog>
    <h1 mat-dialog-title>
      Edit Message
      <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
  
    <div mat-dialog-content class="message-entry-container">

      <textarea matInput placeholder="Aa" [(ngModel)]="messageBoxText"></textarea>

    </div>
  
    <div mat-dialog-actions>
      <xpo-button-group></xpo-button-group>
      <xpo-button-group>
        <button mat-stroked-button xpoLargeButton xpoButton (click)="cancel()">Cancel</button>
        <button mat-flat-button xpoLargeButton xpoButton (click)="saveMessage()" [disabled]="messageBoxText.length == 0">Save</button>
      </xpo-button-group>
    </div>
  </div>