<xpo-contextual-header>
    <xpo-contextual-header-items [itemList]="contextualHeaderItems"></xpo-contextual-header-items>

    <xpo-contextual-header-actions [config]="actionsConfig"></xpo-contextual-header-actions>

    <div class="header-actions" *ngIf="rfpService.isEditable() || showReOpenButton() || showRdsActions() || showPsaeActions()">
        <div class="action-button-container">
            <ng-container *ngIf="rfpService.isEditable()">
                <span [matTooltip]="getSubmitToPricingError()" >
                    <button mat-flat-button xpoButton xpoSmallButton class="submit-button" *ngIf="!requireRdsReview() && !requirePsaeReview()" [disabled]="!canSubmitToPricing() || makingApiCall || savingRfpData" (click)="callSubmitToPricing()"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Submit to Pricing</button>
                    
                    <ng-container *ngIf="requireRdsReview()">
                        <button mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="!canSubmitToPricing() || makingApiCall" (click)="submitToRdsReview()"><xpo-busy-loader *ngIf="makingApiCall || savingRfpData" style="height: 20px; width: 20px"></xpo-busy-loader>Submit To RDS</button>
                    </ng-container>
    
                    <ng-container *ngIf="!requireRdsReview() && requirePsaeReview();">
                        <button mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="!canSubmitToPricing() || makingApiCall" (click)="saveAndSubmitToPsaeReview()"><xpo-busy-loader *ngIf="makingApiCall || savingRfpData" style="height: 20px; width: 20px"></xpo-busy-loader>Submit To PSAE</button>
                    </ng-container>
                </span>

                <button xpo-action-button [disabled]="!canAllSave() || makingApiCall || savingRfpData" [matTooltip]="'Save RFP'" (click)="callSaveRfpData()"><xpo-icon iconName="save"></xpo-icon></button>
                <button xpo-action-button [disabled]="!rfpDetails.canSalesClose() || makingApiCall || savingRfpData" [matTooltip]="getCloseRfpTooltip()" (click)="closeRfp()"><xpo-icon iconName="cancel-outline"></xpo-icon></button>
            </ng-container>

            <ng-container *ngIf="showReOpenButton()">
                <button mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall" (click)="reOpenRfp()"><xpo-busy-loader *ngIf="makingApiCall || savingRfpData" style="height: 20px; width: 20px"></xpo-busy-loader>Re-Open RFP</button>
            </ng-container>

            <ng-container *ngIf="showRdsActions()">
                <button mat-stroked-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall || savingRfpData" (click)="updateRfpReview('RDSREVIEW', 'Rejected')"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Return To Sales</button>
                <button *ngIf="!requirePsaeReview()" mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall || savingRfpData" (click)="updateRfpReview('RDSREVIEW', 'Approved')"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Submit to Pricing</button>
                <button *ngIf="requirePsaeReview()" mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall || savingRfpData" (click)="submitToPsaeReview()"><xpo-busy-loader *ngIf="makingApiCall || savingRfpData" style="height: 20px; width: 20px"></xpo-busy-loader>Submit To PSAE</button>
            </ng-container>

            <ng-container *ngIf="showPsaeActions()">
                <button mat-stroked-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall || savingRfpData" (click)="updateRfpReview('PSAEREVIEW', 'Rejected')"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Return To Sales</button>
                <button mat-flat-button xpoButton xpoSmallButton class="submit-button" [disabled]="makingApiCall || savingRfpData" (click)="updateRfpReview('PSAEREVIEW', 'Approved')"><xpo-busy-loader *ngIf="makingApiCall" style="height: 20px; width: 20px"></xpo-busy-loader>Submit to Pricing</button>
            </ng-container>


            <ng-template #submitPsaeButton>
            </ng-template>
        </div>
    </div>

</xpo-contextual-header>

<div class="content-container">
    <xpo-tile-ribbon xpoTileRibbonCompact>
        <xpo-tile *ngFor="let tile of tiles" [tile]="tile"></xpo-tile>
    </xpo-tile-ribbon>

    <div class="field-container">
        <mat-tab-group #matTabGroup xpoAlternateTabs [(selectedIndex)]="selectedTabIndex">

                <mat-tab *ngVar="saveableChildren?.get(0)?.getTabStatus() as tabStatus">
                    <ng-template mat-tab-label>
                        General
                        <ng-container *ngIf="tabStatus?.complete; else tabInvalid">
                            <xpo-icon iconName="checkmark-alt" class="tab-success"></xpo-icon>
                        </ng-container>

                        <ng-container *ngIf="tabStatus?.unsavedChanges">
                            <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                        </ng-container>
                    </ng-template>

                    <div class="tab-container">
                        <rfp-general #savable (rfpSave)="onChildSave($event)" [account]="accountOverview" [rfpDetails]="rfpDetails"></rfp-general>
                    </div>
                </mat-tab>


                <mat-tab *ngVar="saveableChildren?.get(1)?.getTabStatus() as tabStatus">
                    <ng-template mat-tab-label>
                        <span [matBadge]="tabStatus?.matBadge">Locations</span>

                        <ng-container *ngIf="tabStatus?.unsavedChanges">
                            <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                        </ng-container>
                    </ng-template>

                    <div class="tab-container">
                        <rfp-locations #savable (rfpSave)="onChildSave($event)" [account]="accountOverview" [rfpDetails]="rfpDetails"></rfp-locations>
                    </div>
                </mat-tab>


                <mat-tab *ngVar="saveableChildren?.get(2)?.getTabStatus() as tabStatus">
                    <ng-template mat-tab-label>
                        Tariffs & Discount
                        <ng-container *ngIf="tabStatus?.complete; else tabInvalid">
                            <xpo-icon iconName="checkmark-alt" class="tab-success"></xpo-icon>
                        </ng-container>

                        <ng-container *ngIf="tabStatus?.unsavedChanges">
                            <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                        </ng-container>
                    </ng-template>

                    <div class="tab-container">
                        <rfp-tariff-discount #savable (rfpSave)="onChildSave($event)" [rfpDetails]="rfpDetails"></rfp-tariff-discount>
                    </div>
                </mat-tab>

                <mat-tab *ngVar="saveableChildren?.get(3)?.getTabStatus() as tabStatus" [disabled]="premiumServiceDisabled">
                    <ng-template mat-tab-label>
                        <span [matTooltip]="getPremiumServiceTooltip()">Premium Services</span>

                        <ng-container *ngIf="tabStatus?.complete; else tabInvalid">
                            <xpo-icon iconName="checkmark-alt" class="tab-success"></xpo-icon>
                        </ng-container>

                        <ng-container *ngIf="tabStatus?.unsavedChanges">
                            <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                        </ng-container>
                    </ng-template>

                    <div class="tab-container">
                        <rfp-premium-services #savable (rfpSave)="onChildSave($event)" [rfpDetails]="rfpDetails"></rfp-premium-services>
                    </div>
                </mat-tab>

                <mat-tab *ngVar="saveableChildren?.get(4)?.getTabStatus() as tabStatus">
                    <ng-template mat-tab-label>
                        <span [matBadge]="tabStatus?.matBadge">Accessorials</span>

                        <ng-container *ngIf="tabStatus?.unsavedChanges">
                            <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                        </ng-container>
                    </ng-template>

                    <div class="tab-container">
                        <rfp-accessorials #savable (rfpSave)="onChildSave($event)" [account]="accountOverview"  [rfpDetails]="rfpDetails"></rfp-accessorials>
                    </div>
                </mat-tab>


                <mat-tab *ngVar="saveableChildren?.get(5)?.getTabStatus() as tabStatus">
                    <ng-template mat-tab-label>
                        <span [matBadge]="tabStatus?.matBadge">FAK Overrides</span>

                        <xpo-icon *ngIf="!tabStatus?.canSave" iconName="error" class="tab-error"></xpo-icon>
                        
                        <ng-container *ngIf="tabStatus?.unsavedChanges">
                            <ng-container *ngTemplateOutlet="tabUnsavedChanges"></ng-container>
                        </ng-container>
                    </ng-template>
                    
                    <div class="tab-container">
                        <rfp-fak-overrides #savable (rfpSave)="onChildSave($event)" [rfpDetails]="rfpDetails"></rfp-fak-overrides>
                    </div>
                </mat-tab>

            <mat-tab label="Documents">
                <div class="tab-container">
                    <rfp-documents [rfpDetails]="rfpDetails"></rfp-documents>
                </div>
            </mat-tab>


            <mat-tab label="Messages">
                <div class="tab-container no-side-padding">
                    <rfp-messages [containerHeight]="'403px'" [rfpDetails]="rfpDetails" (onDocumentSelected)="navigateToDocumentTab($event)"></rfp-messages>
                </div>
            </mat-tab>


            <mat-tab label="History">
                <div class="tab-container">
                    <rfp-history [rfpDetails]="rfpDetails"></rfp-history>
                </div>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>

<ng-template #tabInvalid>
    <xpo-icon iconName="priority-high" class="tab-error"></xpo-icon>
</ng-template>

<ng-template #tabUnsavedChanges>
    <xpo-icon iconName="save" class="tab-normal"></xpo-icon>
</ng-template>